/* eslint-disable quotes */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import EpicPassportHeader from './epicPassportHeader';
import Avatar from 'react-avatar';
import NotesSection from '../Note';
import Play from 'static/images/play.svg';
import Pause from 'static/images/pause.svg';
import HiringProfileModal from '../HiringProfileModal';
import { cityCountryFormatter, ddmmyyFormat, formatPhoneNumber, fullNameFormatter } from 'shared/Constant';
import {
    getClientHiringDetails,
    updateNotesDetails,
} from 'actions/dashboardActions';
import { handleStatus , handleProjectStatus } from 'utils/Helper';
import Loading from 'shared/Loader';
import Edit from '../Edit';
import { useHistory } from 'react-router-dom';
import { deleteClient, deleteProject, getClientProfile, getProjects, updateClientCampaign, updateClientNotes, uploadImage } from 'actions/clientActions';
import { deleteClientCampaign } from 'actions/candidateActions';
import uab from 'unique-array-objects';
import { triggerNotifier } from 'shared/Notifier';
import InterviewModal from '../InterviewModal';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import ProjectModal from '../ProjectModal/Index';
function EpicPassport(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ isOpenNote, setIsOpenNote ] = useState(false);
    const [ rowUpdated, setRowUpdated ] = useState(false);

    const [ clientHiringId, setClientHiringId ] = useState('');
    const [ isEdit, setIsEdit ] = useState(false);
    const [ isEditDesc, setIsEditDesc ] = useState(false);
    const [ isOpenHiringModal, setIsOpenHiringModal ] = useState(false);
    const [ isOpenInterviewModal, setIsOpenInterviewModal ] = useState(false)
    const [ isOpenProjectModal, setIsOpenProjectModal ] = useState(false)

    const [ notesValue, setNotesValues ] = useState('');
    const [ rowData, setRowData ] = useState([]);
    const [ projects, setProjects ] = useState([]);
    const client_id = localStorage.getItem('clientId');
    const clientProfileId = (props.history.location.state && props.history.location.state.id) !== undefined ? props.history.location.state.id : (props.history.location.state && props.history.location.state.clientId) !== undefined ? props.history.location.state.clientId : client_id;
    const clientProfileDetails = useSelector(
        (state) => state.clientProfile.client_profile
    );
    const clientCampDetails = useSelector(
        (state) => state.clientProfile.campaigns
    );
    const clientHiringDetails = useSelector((state) => state.clientHiringDetails);
    const isLoading = useSelector((state) => state.applicationIsLoading);
    const profileImage = useSelector((state) => state.profileImage);
    const [ projectUpdate, setProjectUpdate ] = useState(null);
    const [ inputField, setInputField ] = useState({});
    const [ inputFieldCampaign, setInputFieldCampaign ] = useState({});
    const active_tab = localStorage.getItem('activeTab');

    const [ activeTab, setActiveTab ] = useState(active_tab === null ? "1" : active_tab);

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
        if (tab === "1") {
            // dispatch(getCampaignCandidates(campId, 1));
            localStorage.setItem('activeTab', "1");
        } else {
            // dispatch(getCampaignCandidates(campId, 0));
            localStorage.setItem('activeTab', "2");
        }
    };
    const getProjectList = () =>{
        dispatch(getProjects(clientProfileId)).then((res) => {
            if (res.statusCode === 200) {
                setProjects(res.data.project_list);
            }
        });
    }
    useEffect(() => {
        dispatch(getClientProfile(clientProfileId));
        getProjectList()
    }, [ dispatch, clientProfileId ]);
    const handleOpenNotes = (data) => {
        setInputField(data)
        setIsOpenNote(true);
        setIsEditDesc(!isEditDesc)
        if (!isOpenNote) {
            setTimeout(function () {
                window.scroll({
                    top: document.body.offsetHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 300);
        }
    };
    const localStorageHandler = (data) => {
        localStorage.setItem('campaignId', data.id);
        localStorage.setItem('campaignName', data.campaign_name);
    }
    const updateCampaignDetails = (event, deatils, campNotes) => {
        event.preventDefault();
        const { campaign_description } = inputFieldCampaign
        const id = deatils.id ? deatils.id : 0
        const notesData = {
            notes: campNotes ? campNotes : campaign_description ? campaign_description : deatils.campaign_description,
        }
        const campaignData = {
            // campaign_name: inputFieldCampaign.campaign_name === undefined ? deatils.campaign_name : inputFieldCampaign.campaign_name,
            campaign_name: deatils.campaign_name,
            is_create: deatils.id ? 0 : 1,
            client_id: deatils.client_id ? deatils.client_id : clientProfileId,
            campaign_description: campNotes ? campNotes : campaign_description ? campaign_description : deatils.campaign_description
        }
        if ((deatils.campaign_name !== undefined) && (deatils.campaign_name !== null) && (deatils.campaign_name !== '')) {
            dispatch(updateClientCampaign(id, campaignData)).then((res) => {
                if (res.statusCode === 200) {
                    setRowUpdated(true)
                }
            })
        } else {
            dispatch(updateClientNotes(id, notesData)).then((res) => {
                if (res.statusCode === 200) {
                    setRowUpdated(true)
                }
            })
        }
    }
    const handleUpdateNotes = (data, event) => {
        const message = 'The notes entered exceeds the maximum limit and its should be 500 words'
        let numWords = 0;
        for (var i = 0; i < data.length; i++) {
            var currentCharacter = data[ i ];
            if (currentCharacter === " ") {
                numWords += 1;
            }
        }
        numWords += 1;
        if (numWords <= 500) {
            const date = new Date();
            const notesDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
            if (typeof data === 'object') {
                if (inputField.notes !== null || inputField.notes !== undefined) {
                    updateCampaignDetails(event, inputField, inputField.notes);
                    setInputField(data.notes)
                    setIsOpenNote(!isOpenNote);
                    setNotesValues('')
                } else {
                    updateCampaignDetails(event, inputField, notesDate.concat(" : " + data.notes));
                    setInputField(data.notes)
                    setIsOpenNote(!isOpenNote);
                    setNotesValues('')
                }
            } else {
                if (inputField.notes === '' || inputField.notes === null) {
                    const finalNotes = notesDate.concat(" : " + data)
                    updateCampaignDetails(event, inputField, finalNotes);
                    setInputField(data)
                    setIsOpenNote(!isOpenNote);
                    setNotesValues('')
                } else {
                    updateCampaignDetails(event, inputField, data);
                    setInputField(data)
                    setIsOpenNote(!isOpenNote);
                    setNotesValues('')
                }
            }
        } else {
            triggerNotifier({ type: 'error', message })
        }
    }
    const handleCloseNotes = () => {
        setIsOpenNote(false);
    }
    const handleEditNotes = (event) => {
        setNotesValues(event.target.value);
    };
    const handleHiringDetails = (event, client) => {
        event.preventDefault();
        setIsOpenHiringModal(!isOpenHiringModal);
        setClientHiringId(client.id)
        dispatch(getClientHiringDetails(client.id));
    };

    const handleAddClientInterview = (event, client) => {
        event.preventDefault();
        setIsOpenInterviewModal(!isOpenInterviewModal);
        setClientHiringId(client.id)
    }

    const handleAddClientProject = (event, client) => {
        event.preventDefault();
        setIsOpenProjectModal(!isOpenProjectModal);
    }
    const closeHiringDetails = () => {
        setIsOpenHiringModal(!isOpenHiringModal);
    };
    const closeProjectModal = () => {
        setIsOpenProjectModal(false);
        getProjectList()
    }
    const campArray = [];
    clientProfileDetails &&
        clientProfileDetails.map((data, idx) => {
            clientCampDetails &&
                clientCampDetails.map((camp, idm) => {
                    if (data.id === camp.client_id) {
                        campArray.push(camp);
                    }
                });
        });
    const handleClientEdit = (event, data) => {
        event.preventDefault();
        setIsEdit(!isEdit);
        setInputField(data);
        setInputFieldCampaign({})
    };
    const handleChange = (e) => {
        if (e.target.type.includes('file')) {
            setInputField({ ...inputField, [ e.target.name ]: e.target.files[ 0 ] });
            const param = clientProfileDetails[ 0 ].email_id
            const input = {}
            input[ e.target.name ] = e.target.files[ 0 ];
            var data = new FormData();
            data.append('file', input.avatar);
            data.append('email', param);
            const allData = clientProfileDetails
            allData[ 0 ].avatar = e.target.files[ 0 ].name;
            if (e.target.files[ 0 ]) {
                dispatch(uploadImage('Clients', data, allData)).then((res) => {
                    if (res.statusCode === 200) {
                        setInputField({ ...inputField, avatar: res.data.file_url });
                    }
                });
            }
        }
        else {
            setInputField({ ...inputField, [ e.target.name ]: e.target.value });
        }
    };
    const handleCampaignChange = (e, idx) => {
        setInputFieldCampaign({ ...inputFieldCampaign, [ e.target.name ]: e.target.value });
        if (rowData.length === 0) {
            campArray[ idx ].campaign_name = e.target.value;
        }
    }
    const newRow = {
        campaign_description: 'Description',
        campaign_name: 'New Campaign',
        resource: '',
        client_id: clientProfileDetails && clientProfileDetails[ 0 ] && clientProfileDetails[ 0 ].id,
        created_datetime: new Date(),
    };
    const addNewCampaign = () => {
        setRowUpdated(false)
        var temp = campArray;
        temp.push(newRow);
        setRowData([ ...temp ]);
    }

    const finalArray = uab([ ...campArray, ...rowData ])
    const idToRemove = 'New Campaign';
    const filteredFinalArray = finalArray.filter((item) => item.campaign_name !== idToRemove);
    const resultArray = rowUpdated ? filteredFinalArray : finalArray;
    const finalCampaignData = [];
    for (let i = 0; i < filteredFinalArray.length; i++) {
        finalCampaignData.push({
            campaign_id: filteredFinalArray[ i ].id,
            campaign_name: filteredFinalArray[ i ].campaign_name,
            campaign_description: filteredFinalArray[ i ].campaign_description
        });
    }
    const updateNotes = (event) => {
        event.preventDefault();
        if (finalArray[ finalArray.length - 1 ].resource === '') {
            finalCampaignData.push({
                campaign_id: 0,
                campaign_name: inputFieldCampaign.campaign_name,
                campaign_description: finalArray[ finalArray.length - 1 ].campaign_description
            });
        }
        const {
            id,
            first_name,
            last_name,
            email_id,
            phone_number,
            company_name,
            company_url,
            job_title,
            city,
            state,
            country,
            zipcode,
            notes,
        } = inputField;
        const data = {
            client_id: id,
            avatar: profileImage,
            first_name: first_name,
            last_name: last_name,
            email_id: email_id,
            phone_number: phone_number,
            company_name: company_name,
            company_url: company_url,
            job_title: job_title,
            city: city || '',
            state: state || '',
            country: country || '',
            zipcode: zipcode || '',
            notes: notesValue ? notesValue : notes || '',
            campaigns_data: finalCampaignData
        };
        dispatch(updateNotesDetails(id, data)).then((res) => {
            if (res.statusCode === 200) {
                dispatch(getClientProfile(id))
                setIsEdit(false);
                setIsOpenNote(false);
                setRowData([])
            }
        });
    };
    const removeClientFunc = (clientId) => {
        dispatch(deleteClient(clientId));
        history.push('/clients');
    };
    const removeClientCampaign = (campId) => {
        dispatch(deleteClientCampaign(campId));
    };
    const handleNoteData = (data) => {
    }
    const updateProject = (project) =>{
        setProjectUpdate(project);
        setIsOpenProjectModal(!isOpenProjectModal);
    }
    const removeProject = async (project_id) => {
        const result = window.confirm('Are you sure you want to delete?');
        if(result){
            dispatch(deleteProject(project_id)).then((res) => {
                getProjectList();
            });
        }

    }
    return (
        <>
            <EpicPassportHeader
                handleClientEdit={ handleClientEdit }
                clientProfileDetails={ clientProfileDetails }
                isEdit={ isEdit }
                updateNotes={ updateNotes }
                rowUpdated={ rowUpdated }
            />
            <h2>{ isEdit}</h2>
            {!isEdit && (
                <div className="main-div">
                    <table className="table table-condensed github table-borderless" style={ { marginBottom : "0px !important" } }>
                        <thead className="contentdata1">

                            {clientProfileDetails &&
                                            clientProfileDetails.map((data, idx) => {
                                                return (
                                                    <tr key={ idx }>
                                                        <td colSpan="12">
                                                            <div className="emergency">
                                                                <div className="epic-extendedrev row m-3">
                                                                    <div className="clicked3 text-center col-lg-1">
                                                                        <Avatar
                                                                            size="100"
                                                                            square={ true }
                                                                            name={ fullNameFormatter(data) }
                                                                            src={ data.avatar }
                                                                        />
                                                                        <br />
                                                                        {data.is_active ? (
                                                                            <img
                                                                                src={ Play }
                                                                                alt="play"
                                                                                className="play-icon"
                                                                                onClick={ () =>
                                                                                    handleStatus(dispatch, data, 0, 0)
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={ Pause }
                                                                                alt="pause"
                                                                                className="pause-icon pause-img"
                                                                                onClick={ () =>
                                                                                    handleStatus(dispatch, data, 0, 1)
                                                                                }
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <div className="clicked col-lg-2 overflow-auto">
                                                                        <table>
                                                                            <tr>
                                                                                <td className="title">Company</td>
                                                                                <td className="sub-title">
                                                                                    {data.company_name}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="title">Location</td>
                                                                                <td className="sub-title">{cityCountryFormatter(data)}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="title">Email</td>
                                                                                <td className="sub-title">{data.email_id}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="title">Phone</td>
                                                                                <td className="sub-title">
                                                                                    {!formatPhoneNumber(data.phone_number) ? (<span>-</span>) : (formatPhoneNumber(data.phone_number))}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="title">URL</td>
                                                                                <td className="sub-title">
                                                                                    {data.company_url}
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                    <div className="clicks1 col-lg-2">
                                                                        <tr>
                                                                            <td className="title">First Name</td>
                                                                            <td className="sub-title">{data.first_name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="title">Last Name</td>
                                                                            <td className="sub-title">{data.last_name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="title">Job Title</td>
                                                                            <td className="sub-title">{data.job_title}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="title">Registered</td>
                                                                            <td className="sub-title">
                                                                                {moment(data.joined_date).format(
                                                                                    ddmmyyFormat
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    </div>
                                                                    <div className="dataclick col-lg-2">
                                                                        <p className=" mb-0">
                                                                            <b>Notes</b>
                                                                        </p>
                                                                        <textarea
                                                                            id="w3review"
                                                                            onClick={ () => { handleOpenNotes(data); handleNoteData(data) } }
                                                                            name="w3review"
                                                                            rows="4"
                                                                            cols="0"
                                                                            value={ data.notes }
                                                                            readOnly
                                                                            placeholder='Make your note'
                                                                        ></textarea>
                                                                    </div>
                                                                    <div className="clickeb d-grid col-lg-4 text-end mt-4" style = { { height : "154px" } }>
                                                                        <button
                                                                            type="button"
                                                                            className="epic-btn text-center mb-0 pb-0"
                                                                            data-toggle="modal"
                                                                            data-target="#exampleModal"
                                                                            onClick={ (event) =>
                                                                                handleHiringDetails(event, data)
                                                                            }
                                                                        >
                                                                            Hiring Profile
                                                                        </button>
                                                                        <button
                                                                            className="epic-btn text-center mb-0 pb-0"
                                                                            type="button"
                                                                            name="button"
                                                                            onClick={ addNewCampaign }
                                                                        >
                                                                            New Campaign
                                                                        </button>
                                                                        <button
                                                                            className="epic-btn text-center mb-0 pb-0"
                                                                            type="button"
                                                                            name="button"
                                                                            onClick={ (event) =>
                                                                                handleAddClientProject(event, data)
                                                                            }
                                                                        >
                                                                            New Project
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="epic-btn text-center mb-0 pb-0"
                                                                            data-toggle="modal"
                                                                            data-target="#interviewModal"
                                                                            onClick={ (event) =>
                                                                                handleAddClientInterview(event, data)
                                                                            }
                                                                        >
                                                                            List Interview
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                        </thead>
                    </table>
                    <Nav tabs className="storage-tabs justify-content-left">
                        <NavItem>
                            <NavLink
                                className={ classnames({ active: activeTab === "1" }) }
                                onClick={ () => {
                                    toggle("1");
                                } }
                            >
                                Campaigns
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={ classnames({ active: activeTab === "2" }) }
                                storage-vote
                                onClick={ () => {
                                    toggle("2");
                                } }
                            >
                                Projects
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={ activeTab }>
                        <TabPane tabId="1">
                            <table  className="main-table table table-condensed github table-borderless">
                                <thead style = { { width : "100%" } }>
                                    <tr className="table-head">
                                        <th scope="col" className="s_no">#</th>
                                        <th scope="col" style={ { width: '5%' } }></th>
                                        <th scope="col" style={ { width: '20%' } }>
                                            Campaign Name <img src="Images/hiphen.svg" alt="" />
                                        </th>
                                        <th scope="col" style={ { width: '50%' } }>Description </th>
                                        <th scope="col" style={ { width: '12%' } }>
                                            Resources <img src="Images/hiphen.svg" alt="" />
                                        </th>
                                        <th scope="col" style={ { width: '10%' } }>
                                            Started <img src="Images/hiphen.svg" alt="" />
                                        </th>
                                    </tr>
                                </thead>
                                {isLoading && (
                                    <div className="loader">
                                        <Loading />
                                    </div>
                                )}
                                <tbody>
                                    {!isLoading &&
                                            resultArray &&
                                            resultArray.map((camp, idx) => {
                                                return (
                                                    <tr key={ idx } className="table-row">
                                                        <th scope="row" className="s_no">{idx + 1}</th>
                                                        <td>
                                                            {camp.is_active ? (
                                                                <img
                                                                    src={ Play }
                                                                    alt="play"
                                                                    className="play-icon"
                                                                    onClick={ () =>
                                                                        handleStatus(dispatch, camp, 2, 0)
                                                                    }
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={ Pause }
                                                                    alt="pause"
                                                                    className="pause-icon pause-img"
                                                                    onClick={ () =>
                                                                        handleStatus(dispatch, camp, 2, 1)
                                                                    }
                                                                />
                                                            )}
                                                        </td>
                                                        <td>{camp.campaign_name}</td>
                                                        <td onClick={ () => handleOpenNotes(camp) }>{camp.campaign_description}</td>
                                                        <td>
                                                            <Link
                                                                className="tabss"
                                                                to={ { pathname: '/storage', query: camp.id, campaign_name: camp.campaign_name } }
                                                                onClick={ () => localStorageHandler(camp) }
                                                            >
                                                                {camp.total_hired_candidate} Hire
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            {moment(camp.created_datetime).format(ddmmyyFormat)}{' '}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                </tbody>
                            </table>
                        </TabPane>
                        <TabPane tabId="2">
                            <table  className="main-table table table-condensed github table-borderless">
                                <thead style = { { width : "100%" } }>
                                    <tr className="table-head">
                                        <th scope="col" className="s_no">#</th>
                                        <th scope="col" style={ { width: '5%' } }></th>
                                        <th scope="col" style={ { width: '20%' } }>
                                            Project Name <img src="Images/hiphen.svg" alt="" />
                                        </th>
                                        <th scope="col" style={ { width: '20%' } }>Project Description </th>
                                        <th scope="col" style={ { width: '10%' } }>
                                            Resources <img src="Images/hiphen.svg" alt="" />
                                        </th>
                                        <th scope="col" style={ { width: '10%' } }>
                                            Started <img src="Images/hiphen.svg" alt="" />
                                        </th>
                                        <th scope="col" style={ { width: '10%' } }>
                                            End <img src="Images/hiphen.svg" alt="" />
                                        </th>
                                        <th scope="col" style={ { width: '30%' } }>
                                            Action <img src="Images/hiphen.svg" alt="" />
                                        </th>
                                    </tr>
                                </thead>
                                {isLoading && (
                                    <div className="loader">
                                        <Loading />
                                    </div>
                                )}
                                <tbody>
                                    {!isLoading &&
                                            projects &&
                                            projects.map((project, idx) => {
                                                return (
                                                    <tr key={ idx } className="table-row">
                                                        <th scope="row" className="s_no">{idx + 1}</th>
                                                        <td>
                                                            {project.is_active ? (
                                                                <img
                                                                    src={ Play }
                                                                    alt="play"
                                                                    className="play-icon"
                                                                    onClick={ () =>
                                                                        handleProjectStatus(dispatch, project.id,0)
                                                                    }
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={ Pause }
                                                                    alt="pause"
                                                                    className="pause-icon pause-img"
                                                                    onClick={ () =>
                                                                        handleProjectStatus(dispatch, project.id,  1)
                                                                    }
                                                                />
                                                            )}
                                                        </td>
                                                        <td>{project.project_name}</td>
                                                        <td onClick={ () => handleOpenNotes(project) }>{project.project_description}</td>
                                                        <td>

                                                            {project.resource_count} Hire
                                                        </td>
                                                        <td>
                                                            {moment(project.project_start_date).format(ddmmyyFormat)}{' '}
                                                        </td>
                                                        <td>
                                                            {moment(project.project_end_date).format(ddmmyyFormat)}{' '}
                                                        </td>
                                                        <td >
                                                            <button type="submit" className="btn btn-secondary telenory closer" onClick = { () =>{
                                                                history.push({
                                                                    pathname: '/milestone',
                                                                    state: {
                                                                        projectId: project.id,
                                                                        notes : project.project_notes
                                                                    },
                                                                })
                                                            } } >
                                                                View
                                                            </button>
                                                            <button type="submit" className="btn btn-secondary telenory closer" onClick = { () =>{

                                                                updateProject(project)
                                                            } } >
                                                                Edit
                                                            </button>
                                                            <button type="submit" className="btn btn-secondary telenory closer" data-dismiss="modal"
                                                                onClick = { () =>{
                                                                    removeProject(project.id)
                                                                } }  >
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                </tbody>
                            </table>
                        </TabPane>
                    </TabContent>

                    {isOpenNote && (
                        <NotesSection
                            handleOpenNotes={ handleOpenNotes }
                            handleEditNotes={ handleEditNotes }
                            clientList={ clientProfileDetails }
                            updateNotes={ updateNotes }
                            handleCloseNotes={ handleCloseNotes }
                            handleCandidatesNotes={ handleUpdateNotes }
                            notesValue={ notesValue ? notesValue : inputField }
                        />
                    )}
                </div>
            )}

            {isEdit && (
                <Edit
                    clientProfileDetails={ clientProfileDetails }
                    campArray={ resultArray }
                    projects = { projects }
                    handleChange={ handleChange }
                    removeClientFunc={ removeClientFunc }
                    removeClientCampaign={ removeClientCampaign }
                    updateCampaignDetails={ updateCampaignDetails }
                    handleCampaignChange={ handleCampaignChange }
                    handleOpenNotes={ handleOpenNotes }
                    addNewCampaign={ addNewCampaign }
                    inputField={ inputField }
                    activeTab = { activeTab }
                />
            )}
            {isOpenHiringModal && (
                <HiringProfileModal
                    isOpenHiringModal={ isOpenHiringModal }
                    clientHiringDetails={ clientHiringDetails }
                    closeHiringDetails={ closeHiringDetails }
                    clientId={ clientHiringId }
                />
            )}
            {isOpenInterviewModal && (
                <InterviewModal
                    isOpenInterviewModal={ isOpenInterviewModal }
                    closeInterviewDetail={ () => { setIsOpenInterviewModal(!isOpenInterviewModal) } }
                    clientId={ client_id }
                />
            )}
            {isOpenProjectModal && (
                <ProjectModal
                    isOpenProjectModal={ isOpenProjectModal }
                    closeProjectDetail={ closeProjectModal }
                    projectUpdate = { projectUpdate }
                    clientId={ client_id }
                />
            )}

        </>
    );
}
EpicPassport.propTypes = {
    history: PropTypes.object,
};

export default EpicPassport;
