import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AuthHeaderSection from '../AuthHeaderSection';
import sideArrow from 'static/images/left.svg';
import Play from 'static/images/play.svg';
import Pause from 'static/images/pause.svg';
import { getClientsListing, getClientProfile } from 'actions/clientActions';
import Loading from 'shared/Loader';
import moment from 'moment';
import {
    cityCountryFormatter,
    dmmyyFormat,
    formatPhoneNumber,
    SearchTableData,
} from 'shared/Constant';
import { handleStatus } from 'utils/Helper';
import Sort from 'shared/Sorting';

function ClientSection(props) {
    const dispatch = useDispatch();
    const [ searchValue, setSearchValue ] = useState('');
    const [ filterOptions, setFilterOptions ] = useState({
        city: '',
        state: '',
    });
    const [ sortType, setSortType ] = useState('');
    const [ isHover, setIsHover ] = useState('');
    const [ columnName, setColumnName ] = useState('');
    const [ companyArrowUp, setCompanyArrowUp ] = useState(false);
    const [ companyArrowDown, setCompanyArrowDown ] = useState(false);
    const [ locationArrowUp, setLocationArrowUp ] = useState(false);
    const [ locationArrowDown, setLocationArrowDown ] = useState(false);
    const [ registeredArrowUp, setRegisteredArrowUp ] = useState(false);
    const [ registeredArrowDown, setRegisteredArrowDown ] = useState(false);
    const [ resourcesArrowUp, setResourcesArrowUp ] = useState(false);
    const [ resourcesArrowDown, setResourcesArrowDown ] = useState(false);
    const [ campaignsArrowUp, setCampaignsArrowUp ] = useState(false);
    const [ campaignsArrowDown, setCampaignsArrowDown ] = useState(false);

    const clientList = useSelector((state) => state.clientsListing);
    const isLoading = useSelector((state) => state.applicationIsLoading);
    const tableData = SearchTableData(clientList, searchValue);
    useEffect(() => {
        dispatch(getClientsListing(filterOptions));
    }, [ dispatch, filterOptions ]);
    const openClientProfile = (data) => {
        const clientId = data.id;
        localStorage.setItem('clientId', clientId);
        dispatch(getClientProfile(data.id));
        props.history.push({
            pathname: '/epic-passport',
            state: {
                clientId,
            },
        });
    };
    const updateSearch = (event) => {
        setSearchValue(event.target.value.substr(0, 20));
    };
    const handleOptions = (event) => {
        setFilterOptions({
            ...filterOptions,
            [ event.target.name ]: event.target.value,
        });
    };
    const sorted = tableData && tableData.sort((a, b) => {
        const isReversed = !sortType ? '' : (sortType === 'asc') ? 1 : -1
        if(columnName === 'company'){
            return isReversed*a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
        }
        else if(columnName === 'location'){
            return (isReversed*a.city.toLowerCase().localeCompare(b.city.toLowerCase()))
        }
        else if(columnName === 'registered'){
            const date1 = new Date(a.joined_date);
            const date2 = new Date(b.joined_date);
            return (isReversed*date1.getTime() - isReversed*date2.getTime())
        }
        else if(columnName === 'resources'){
            if(isReversed === 1){
                return isReversed*a.total_hired_candidate - b.total_hired_candidate
            } else if(isReversed === -1){
                return b.total_hired_candidate - a.total_hired_candidate
            } else {
                return a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
            }
        }
        else if(columnName === 'campaigns'){
            if(isReversed === 1){
                return isReversed*a.total_active_campaigns - b.total_active_campaigns
            } else if(isReversed === -1){
                return b.total_active_campaigns - a.total_active_campaigns
            } else {
                return a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
            }
        }
        else {
            return a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
        }
    })
    const handleColumnName = (data) => {
        setColumnName(data)
    }
    const onSort = (order, data) => {
        setSortType(order);
        setColumnName(data)
        if(data === 'company') {
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setResourcesArrowUp(false)
            setResourcesArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setCompanyArrowUp(!companyArrowUp)
        }
        if(data === 'location') {
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setResourcesArrowUp(false)
            setResourcesArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(!locationArrowUp)
        }
        if(data === 'registered') {
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setResourcesArrowUp(false)
            setResourcesArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowUp(!registeredArrowUp)
        }
        if(data === 'resources') {
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setResourcesArrowUp(!resourcesArrowUp)
        }
        if(data === 'campaigns') {
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setResourcesArrowUp(false)
            setResourcesArrowDown(false)
            setCampaignsArrowUp(!campaignsArrowUp)
        }
    };
    const onSortUp = (order, data) => {
        setSortType(order);
        if(columnName === 'company' || data === 'company') {
            setCompanyArrowUp(!companyArrowUp)
            setCompanyArrowDown(!companyArrowDown)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setResourcesArrowUp(false)
            setResourcesArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        } else if(columnName === 'location' || data === 'location') {
            setLocationArrowUp(!locationArrowUp)
            setLocationArrowDown(!locationArrowDown)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setResourcesArrowUp(false)
            setResourcesArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        } else if(columnName === 'registered' || data === 'registered') {
            setRegisteredArrowDown(!registeredArrowDown)
            setRegisteredArrowUp(!registeredArrowUp)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setResourcesArrowUp(false)
            setResourcesArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        } else if(columnName === 'resources' || data === 'resources') {
            setResourcesArrowDown(!resourcesArrowDown)
            setResourcesArrowUp(!resourcesArrowUp)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        } else if(columnName === 'campaigns' || data === 'campaigns') {
            setCampaignsArrowDown(!campaignsArrowDown)
            setCampaignsArrowUp(!campaignsArrowUp)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setResourcesArrowUp(false)
            setResourcesArrowDown(false)
        }
    };
    const onSortDown = (order) => {
        setSortType(order);
        if(columnName === 'company') {
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
        } else if(columnName === 'location') {
            setLocationArrowUp(false)
            setLocationArrowDown(false)
        } else if(columnName === 'registered'){
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'resources'){
            setResourcesArrowUp(false)
            setResourcesArrowDown(false)
        } else if(columnName === 'campaigns'){
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        }
    };
    const toggleHover = (idx) => {
        if (isHover === idx){
            setIsHover('')
        }
        else{
            setIsHover(idx)
        }
    }
    return (
        <>
            <AuthHeaderSection
                props={ props }
                updateSearch={ updateSearch }
                searchValue={ searchValue }
                handleOptions={ handleOptions }
            />
            <div className="table-responsive main-div">
                <table
                    className="main-table table table-condensed github table-borderless"
                    id="myTable"
                >
                    <thead>
                        <tr className="table-head">
                            <th scope="col" className="s_no" style={ { width: '4%' } }>
                                #
                            </th>
                            <th scope="col" style={ { width: '4%' } }></th>
                            <th scope="col" style={ { width: '15%' } }>
                                Company Name{' '}
                                <Sort
                                    data= { 'company' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp = { companyArrowUp }
                                    sortArrowDown ={ companyArrowDown }
                                    onSort={ onSort }
                                    onSortUp={ onSortUp }
                                    onSortDown={ onSortDown }
                                />
                            </th>
                            <th scope="col" style={ { width: '16%' } }>
                                Location{' '}
                                <Sort
                                    data= { 'location' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp = { locationArrowUp }
                                    sortArrowDown ={ locationArrowDown }
                                    onSort={ onSort }
                                    onSortUp={ onSortUp }
                                    onSortDown={ onSortDown }
                                />
                            </th>
                            <th scope="col" style={ { width: '20%' } }>Email</th>
                            <th scope="col" style={ { width: '10%' } }>Phone</th>
                            <th scope="col" className="act-center" style={ { width: '10%' } }>
                                Campaigns{' '}
                                <Sort
                                    data= { 'campaigns' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp = { campaignsArrowUp }
                                    sortArrowDown ={ campaignsArrowDown }
                                    onSort={ onSort }
                                    onSortUp={ onSortUp }
                                    onSortDown={ onSortDown }
                                />
                            </th>
                            <th scope="col" className="act-center" style={ { width: '10%' } }>
                                Resources{' '}
                                <Sort
                                    data= { 'resources' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp = { resourcesArrowUp }
                                    sortArrowDown ={ resourcesArrowDown }
                                    onSort={ onSort }
                                    onSortUp={ onSortUp }
                                    onSortDown={ onSortDown }
                                />
                            </th>
                            <th scope="col" style={ { width: '7%' } }>
                                Joined{' '}
                                <Sort
                                    data= { 'registered' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp = { registeredArrowUp }
                                    sortArrowDown ={ registeredArrowDown }
                                    onSort={ onSort }
                                    onSortUp={ onSortUp }
                                    onSortDown={ onSortDown }
                                />
                            </th>
                            <th scope="col" style={ { width: '4%' } }></th>
                        </tr>
                    </thead>
                    {isLoading && (
                        <div className="loader">
                            <Loading />
                        </div>
                    )}
                    {!isLoading &&
            sorted &&
            sorted.map((client, idx) => {
                return (
                    <tbody key={ idx }>
                        <>
                            <tr
                                data-toggle="collapse"
                                className="accordion-toggle table-row"
                                data-target={ `#toggle${ idx }` }
                            >
                                <th scope="row" className="s_no">
                                    {idx + 1}
                                </th>
                                <td>
                                    {client.is_active ? (
                                        <img
                                            src={ Play }
                                            alt="play"
                                            className="play-icon"
                                            onClick={ () => handleStatus(dispatch, client, 0, 0) }
                                            onMouseEnter={ () => toggleHover(client.id) }
                                        />
                                    ) : (
                                        <img
                                            src={ Pause }
                                            alt="pause"
                                            className="pause-icon pause-img"
                                            onClick={ () => handleStatus(dispatch, client, 0, 1) }
                                            onMouseEnter={ () => toggleHover(client.id) }
                                        />
                                    )}
                                </td>
                                <td>{client.company_name}</td>
                                <td>{cityCountryFormatter(client)}</td>
                                <td className="email-text">{client.email_id}</td>
                                <td>
                                    {!formatPhoneNumber(client.phone_number) ? (
                                        <span>-</span>
                                    ) : (
                                        formatPhoneNumber(client.phone_number)
                                    )}
                                </td>
                                <td >
                                    {client.total_active_campaigns}/
                                    <b>{client.total_campaigns}</b>
                                </td>
                                <td >
                                    <span
                                        className="tabss"
                                        onClick={ () =>
                                            props.history.push({
                                                pathname: '/hiring',
                                                state: {
                                                    clientId: client.id,
                                                    companyName: client.company_name,
                                                },
                                            })
                                        }
                                    >
                                        <b> {`${ client.total_hired_candidate } `} </b>
                                        {client.total_hired_candidate < 1 ? (
                                            <span>Hire</span>
                                        ) : (
                                            <span>Hires</span>
                                        )}
                                    </span>
                                </td>
                                <td>
                                    {moment(client.joined_date).format(dmmyyFormat)}{' '}
                                </td>
                                <td
                                >
                                    <img
                                        src={ sideArrow }
                                        alt="side-arrow"
                                        className="down"
                                        onClick={ () => openClientProfile(client) }
                                    />
                                </td>
                            </tr>
                        </>
                    </tbody>
                );
            })}
                </table>
            </div>
        </>
    );
}
ClientSection.propTypes = {
    history: PropTypes.object,
};
export default ClientSection;
