import axios from 'axios';
import { triggerNotifier } from 'shared/Notifier';
import { getAccessToken, removeAccessToken, setAccessToken } from 'utils/Helper';
import  { REACT_APP_BASE_URL }  from '../config';
import { applicationIsLoading } from './commonActions';
import { encryptFunc, decryptFunc } from '../utils/Helper'

export function isAuthenticated(bool) {
    return {
        type: 'IS_AUTHENTICATED',
        isAuthenticated: bool,
    };
}
export function fetchPhoneNumber(phoneNumber) {
    return {
        type: 'FETCH_PHONE_NUMBER',
        phoneNumber,
    };
}

export function loginUser(data, history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'user/api/userLogin',
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                console.log(res.data.verification_otp)
                dispatch(applicationIsLoading(false));
                dispatch(fetchPhoneNumber(res.data.phone_number));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}

export function logoutUser(history) {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'user/api/userLogout',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-Type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                removeAccessToken()
                history ? history.push('/') : window.location.href = '/';
                dispatch(isAuthenticated(false));
                return res.data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    };
}
export function sendForgotPassword(data) {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'user/api/sendResetPasswordToken',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                console.log('res', res.data.verification_otp )
                return res.data;
            })
            .catch((error) => {
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}

export function sendVerifyToken(data) {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'user/api/verifyResetPasswordToken',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                console.log('code', res)
                return res.data;
            })
            .catch((error) => {
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}

export function sendResetUserPassword(data) {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'user/api/resetUserPassword',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                const message = res && (res.message || 'Password Updated ')
                triggerNotifier({ type: 'success', message })
                return res.data;
            })
            .catch((error) => {
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}

export function verifyOtp(data, history) {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'user/api/verifyOTP',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-Type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                setAccessToken(res.data.access_token)
                dispatch(applicationIsLoading(false));
                dispatch(isAuthenticated(true));
                history.push('/clients')
                return res.data;
            })
            .catch((error) => {
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}