import React from 'react'
import Button from 'shared/Button'
import LogoSection from './LogoSection'
import PropTypes from 'prop-types';
function ResetPassword(props) {
    const { handleChange, inputField, inputFieldError, handleSubmit } = props
    return (
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="row no-gutters d-flex flex-column card">
                <div className="card-body">
                    <LogoSection isLogin = { false } title = 'Please enter your Password.' />
                    <form className="login-form">
                        <div className="form-group field_cont">
                            <label htmlFor="email" className="form-label">
                                Password
                            </label>
                            <input
                                type="text"
                                name="confirm_password"
                                id="confirm_password"
                                autoComplete="off"
                                placeholder="Enter your password"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.confirm_password || '' }
                            />
                            {inputFieldError.confirm_password && (
                                <span className="error_msg">{inputFieldError.confirm_password}</span>
                            )}
                        </div>
                        <Button
                            handleSubmit={ handleSubmit }
                            buttonName="Submit"
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}
ResetPassword.propTypes = {
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    inputField: PropTypes.object,
    inputFieldError: PropTypes.object,
};
export default ResetPassword
