import React, { Component } from 'react'
import PropTypes from 'prop-types';

class HiringPdfContent extends Component {
    render() {
        return (
            <div className="modal-body">
                {this.props.clientHiringDetails &&
             this.props.clientHiringDetails.map((data, idx) => {
                 return (
                     <div className="key" key={ idx }>
                         <p className="questions">
                             {' '}
                             {idx + 1}. {data.que_text}
                         </p>
                         <p className="answers">{data.answer}</p>
                     </div>
                 );
             })}
            </div>
        )
    }
}
HiringPdfContent.propTypes = {
    clientHiringDetails: PropTypes.array,
};
export default HiringPdfContent