import React, { Component } from 'react'
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Export from 'static/images/export.svg';
import HiringPdfContent from './HiringPdfContent';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

class HiringProfileModal extends Component {
    render() {
        const { isOpenHiringModal, clientHiringDetails, closeHiringDetails  } = this.props
        const PdfConvertor = () => {
            const doc = new jsPDF();
            var col = [ 'Question', 'Answer' ];
            var rows = [];
            clientHiringDetails.forEach(element => {
                var temp = [ element.que_text, element.answer ];
                rows.push(temp);
            });
            doc.autoTable(col, rows, {
                styles: {
                    overflow: 'linebreak',
                    fontSize: 10,
                },
                columnStyles: {
                    0: {
                        cellWidth: 120,
                        halign: 'left',
                        fontStyle: 'bold',
                    },
                    1: {
                        cellWidth: 70,
                        halign: 'left',
                    }
                } } )
            doc.save('Hiring-profile.pdf');
        }
        return (
            <div id="exampleModal">
                <Modal isOpen={ isOpenHiringModal }>
                    <div className="modal-header">
                        <div>
                            <h5 className="modal-title" id="exampleModalLabel">
                                HIRING PROFILE
                            </h5>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn telenory closer d-inline-flex justify-content-center align-items-center"
                                onClick={ () => PdfConvertor() }
                            >
                                <img src={ Export } alt="up-arrow" className="export-up-arrow"  />
                                Export
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary telenory closer"
                                data-dismiss="modal"
                                onClick={ closeHiringDetails }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                    <ModalBody>
                        <HiringPdfContent clientHiringDetails={ clientHiringDetails } ref={ (response) => (this.componentRef = response) } />
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}
HiringProfileModal.propTypes = {
    clientHiringDetails: PropTypes.array,
    isOpenHiringModal: PropTypes.bool,
    closeHiringDetails: PropTypes.func,
    clientId: PropTypes.number
};
export default HiringProfileModal