import React from 'react';
import PropTypes from 'prop-types';
import BackArrow from 'static/images/back.svg';
import PenImage from 'static/images/pen.svg';
import { Link } from 'react-router-dom';
import Filter from '../FilterHeader';
import { ReactSVG } from 'react-svg';
import ApplyImage from 'static/images/apply.svg';

function StorageHeader(props) {
    const { searchValue , updateSearch, campName, handleCandidateOptions, editHandletFunc, isEdit, updateCandidateFunc } = props
    return(
        <>
            <nav className="navbar navbar-expand-lg navbar-dashboard">
                <div className="container-fluid">
                    <Link className="navbar-brand" to=""></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 richard">
                            <li className="nav-item amand back-arrow-icon"><Link to="/epic-passport"><ReactSVG src={ BackArrow } className="back-arrow" /></Link></li>
                            <li className="nav-item">
                                <Link to="/epic-passport" className="nav-link back-heading">CLIENTS&nbsp;&nbsp;PROFILE</Link>
                            </li>
                        </ul>
                        <div className="port3 center">{campName}
                        </div>
                        <form className="d-flex flex-wrap align-items-center">
                            <Filter updateSearch={ updateSearch } searchValue={ searchValue } handleCandidateOptions={ handleCandidateOptions }/>
                            <div className="store-edit-btn-section pb-1 mb-1">
                                {
                                    !isEdit &&
                                        <button
                                            className="fill mt-1"
                                            type="submit"
                                            onClick={ editHandletFunc }
                                        >
                                            <img src={ PenImage } alt="pen" />
                                            <span className="modify">Edit</span>
                                        </button>
                                }
                                {
                                    isEdit &&
                                        <button
                                            className="filtr mt-1 ms-0"
                                            type="submit"
                                            onClick={ editHandletFunc }
                                        >
                                            Cancel
                                        </button>
                                }
                                {
                                    isEdit &&
                                        <button
                                            className="apply mt-1"
                                            type="submit"
                                            onClick={ updateCandidateFunc }
                                        >
                                            <img src={ ApplyImage } alt="" />
                                            Apply
                                        </button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </nav>
        </>
    )
}
StorageHeader.propTypes = {
    updateSearch: PropTypes.func,
    searchValue: PropTypes.string,
    campName: PropTypes.string,
    history: PropTypes.object,
    handleCandidateOptions: PropTypes.func,
    editHandletFunc: PropTypes.func,
    isEdit: PropTypes.bool,
    updateCandidateFunc: PropTypes.func
};
export default StorageHeader