export function isAuthenticated(state = sessionStorage.accessToken ? true : false, action) {
    switch(action.type) {
    case 'IS_AUTHENTICATED':
        return action.isAuthenticated
    default:
        return state;
    }
}
export function phoneNumber(state = '', action) {
    switch (action.type) {
    case 'FETCH_PHONE_NUMBER':
        return action.phoneNumber;
    default:
        return state;
    }
}