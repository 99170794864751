import React, { memo } from 'react'
import site_logo_dark from 'static/images/site_logo_dark.png';
import PropTypes from 'prop-types';

function LogoSection(props) {
    const { isLogin, title } = props
    return (
        <>
            <img
                src={ site_logo_dark }
                alt="site logo"
                className="login-page-logo"
            />
            {isLogin && <h5 className="welcome-back-label">Welcome Back!</h5>}
            <p className="please-login-line">{ title }</p>
        </>
    )
}
LogoSection.propTypes = {
    isLogin: PropTypes.bool,
    title: PropTypes.string,
};
export default memo(LogoSection)
