import CryptoJS from 'crypto-js'
import { ENCRYPT_KEY } from '../config';
import { updateStatus } from 'actions/dashboardActions';
import { updateProject } from '../actions/clientActions';

export const setAccessToken = (token) => {
    return sessionStorage.setItem('accessToken', token)
}
export const getAccessToken = () => {
    return sessionStorage.getItem('accessToken')
}
export const removeAccessToken = () => {
    return sessionStorage.removeItem('accessToken');
}

export const encryptFunc = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPT_KEY).toString()
}

export const decryptFunc = (data) => {
    var bytes = CryptoJS.AES.decrypt(data, ENCRYPT_KEY);
    // console.log(bytes.toString(CryptoJS.enc.Utf8));
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return  decryptedData
}

export const handleStatus = (dispatch, details, type, role) => {
    const data = {
        status_type : type,
        id : details.id ? details.id : details.candidate_id ? details.candidate_id : details.campaign_id ,
        status : role
    }
    dispatch(updateStatus(data, details))
}
export const handleProjectStatus = (dispatch, project_id, status) => {
    console.log(project_id)
    const data = {
        is_active : status
    }
    dispatch(updateProject(project_id, data))
}
export const handleMilestoneStatus = (dispatch, project_id, status) => {
    console.log(project_id)
    const data = {
        is_active : status
    }
    dispatch(updateProject(project_id, data))
}
export const handleTaskStatus = (dispatch, project_id, status) => {
    console.log(project_id)
    const data = {
        is_active : status
    }
    dispatch(updateProject(project_id, data))
}
