import { combineReducers } from 'redux';
import { isAuthenticated, phoneNumber } from './authReducer'
import { clientListing, clientHiringDetails, filterDetails, profileStatus, clientsData } from './dashboardReducer'
import { clientsListing, clientProfile, hireCandidates, hireActiveCandidates, campaignCandidates, candidateActiveCampaigns, profileImage, isDecline } from './clientsReducer'
import { applicationIsLoading, spinnerIsLoading } from './commonReducer';
import { assoCandidatesListing, sendVotedDetails, resumeFileName, sharedProfileStatus  } from './candidateReducer';

export default combineReducers({
    applicationIsLoading,
    spinnerIsLoading,
    assoCandidatesListing,
    campaignCandidates,
    candidateActiveCampaigns,
    clientListing,
    clientsListing,
    clientHiringDetails,
    isDecline,
    clientProfile,
    hireCandidates,
    hireActiveCandidates,
    profileImage,
    phoneNumber,
    filterDetails,
    isAuthenticated,
    profileStatus,
    resumeFileName,
    sendVotedDetails,
    sharedProfileStatus,
    clientsData
});