export const dateFormat = 'MM/DD/YYYY';
export const ddmmyyFormat = 'M/DD/YY';
export const dmmyyFormat = 'D/MM/YY';
export const yyyymmddFormat = 'YYYY-MM-DD';
export const dayMonthDateYearFormat = 'dddd, MMMM Do, YYYY';
export const mmdyyyFormat = 'MMM Do YYYY';
export const mmdyyyyFormat = 'MMM Do, YYYY';

export const stateName = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
];
export const fullNameFormatter = (data) => {
    return data.first_name + ' ' + data.last_name;
};
export const cityCountryFormatter = (data) => {
    if (data.city && data.state){
        return data.city + ' , ' + data.state;
    }
    else if (data.city) {
        return data.city;
    }
    else {
        return data.state ? data.state : '-'
    }
};
export const getUniqueArray = (data) => {
    return [ ...new Set(data) ]
};

export const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    const cleaned = ('' + str).replace(/\D/g, '');
    //Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[ 1 ] + ') ' + match[ 2 ] + '-' + match[ 3 ];
    }

    return null;
};

export const SearchTableData = (data, searchValue) => {
    return (
        data &&
    data.filter((d) => {
        return (
            (d.company_name &&
          d.company_name.toLowerCase().indexOf(searchValue.toLowerCase()) !==
            -1) ||
        (d.email_id &&
          d.email_id.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) ||
        (d.phone_number &&
          d.phone_number.toLowerCase().indexOf(searchValue.toLowerCase()) !==
            -1) ||
        (d.city &&
          d.city.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) ||
        (d.country &&
          d.country.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) ||
        (d.full_name &&
          d.full_name.toLowerCase().indexOf(searchValue.toLowerCase()) !==
            -1) ||
        (d.tech_stack &&
          d.tech_stack.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
        ) ||  (d.campaign_name &&
          d.campaign_name.toLowerCase().indexOf(searchValue.toLowerCase()) !==
            -1) ||  (d.job_title &&
              d.job_title.toLowerCase().indexOf(searchValue.toLowerCase()) !==
                -1);
    })
    );
};
