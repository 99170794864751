import React from 'react';
import PropTypes from 'prop-types';

const EditHobbieBody = (props) => {
    const { handleHobbiesChange, hobby, index, rowIndex } = props
    return(
        <div>
            <input
                type="text"
                className="form-control skill-field"
                id="hobbies"
                autoComplete='off'
                name="hobbies"
                defaultValue= { hobby || '' }
                onChange ={ (e) => handleHobbiesChange(e, index, hobby, rowIndex) }
            />
        </div>
    )
}
EditHobbieBody.propTypes = {
    handleHobbiesChange: PropTypes.func,
    hobby: PropTypes.string,
    index: PropTypes.number,
    rowIndex: PropTypes.number,
};
export default EditHobbieBody