/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import sideArrow from 'static/images/left.svg';
import NotesSection from '../Note';
import Play from 'static/images/play.svg';
import Pause from 'static/images/pause.svg';
import moment from 'moment';
import Loading from 'shared/Loader';
import Sort from 'shared/Sorting';
import { AiOutlineFileSearch } from 'react-icons/ai';
import {
    addCandidateAction,
    deleteCandidate,
    getCandidateListing,
    updateCandidates,
    uploadResume,
} from 'actions/candidateActions';
import {
    cityCountryFormatter,
    ddmmyyFormat,
    SearchTableData,
} from 'shared/Constant';
import AuthHeaderSection from '../AuthHeaderSection';
import Trash from 'static/images/trash.svg';
import EditCandidateFormBody from '../EditCandidateForm/EditCandidateFormBody';
import AvatarComp from 'shared/AvatarComp';
import { uploadImage } from 'actions/clientActions';
import { updateStatus } from 'actions/dashboardActions';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import DeleteModal from '../Edit/DeleteModal';
import { triggerNotifier } from 'shared/Notifier';
import Github from 'static/images/github-img.png';
import Portfolio from 'static/images/portfolio-img.png';
import Linkedin from 'static/images/linkedin-img.png';

const CandidatesSection = (props) => {
    const dispatch = useDispatch();
    const [ isOpenNote, setIsOpenNote ] = useState(false);
    const [ rowData, setRowData ] = useState([]);
    const [ inputField, setInputField ] = useState([]);
    const [ skillArray, setSkillArray ] = useState([]);
    const [ educationArray, setEducationArray ] = useState([
        {
            id: 0,
            candidate_id: 0,
            course: '',
            specialization: '',
            university: '',
        },
    ]);
    const [ sortType, setSortType ] = useState('asc');
    const [ sortArrowUp, setSortArrowUp ] = useState(false);
    const [ sortArrowDown, setSortArrowDown ] = useState(true);
    const [ hobbyArray, setHobbyArray ] = useState([]);
    const [ toggleArrowIndex, setToggleArrowIndex ] = useState('');
    const [ toggleArrowEdit, setToggleArrowEdit ] = useState(false);
    const [ isEdit, setIsEdit ] = useState(false);
    const [ searchValue, setSearchValue ] = useState('');
    const [ filterOptions, setFilterOptions ] = useState({
        city: '',
        state: '',
        tech_stack: '',
    });

    const [ notesValue, setNotesValues ] = useState('');
    const [ isNotesData, setIsNotesData ] = useState(false);
    const [ isAddNewCandidate, setIsAddNewCandidate ] = useState(false);
    const [ columnName, setColumnName ] = useState('');
    const [ fullNameArrowUp, setFullNameArrowUp ] = useState(false);
    const [ fullNameArrowDown, setFullNameArrowDown ] = useState(false);
    const [ locationArrowUp, setLocationArrowUp ] = useState(false);
    const [ locationArrowDown, setLocationArrowDown ] = useState(false);
    const [ techArrowUp, setTechArrowUp ] = useState(false);
    const [ techArrowDown, setTechArrowDown ] = useState(false);
    const [ campaignsArrowUp, setCampaignsArrowUp ] = useState(false);
    const [ campaignsArrowDown, setCampaignsArrowDown ] = useState(false);
    const [ associatedArrowUp, setAssociatedArrowUp ] = useState(false);
    const [ associatedArrowDown, setAssociatedArrowDown ] = useState(false);
    const [ registeredArrowUp, setRegisteredArrowUp ] = useState(false);
    const [ registeredArrowDown, setRegisteredArrowDown ] = useState(false);
    const [ isOpenDeleteModal, setIsOpenDeleteModal ] = useState(false);
    const [ candidateInfo, setCandidateInfo ] = useState({});
    const [ userType, setuserType ] = useState('');
    const [ btnIsDisabled, setBtnIsDisabled ] = useState(true)

    const isLoading = useSelector((state) => state.applicationIsLoading);
    const resumeName = useSelector((state) => state.resumeFileName);
    const profileImage = useSelector((state) => state.profileImage);
    const [ profileFileName, setProfileFileName ] = useState();
    const tableData = SearchTableData(rowData, searchValue);
    const handleOpenNotes = (data) => {
        setIsOpenNote(!isOpenNote);
        setInputField(data);
        if(!isOpenNote) {
            setTimeout(function() {
                window.scroll({
                    top: document.body.offsetHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 300);
        }
    };
    const handleCloseNotes = () => {
        setIsOpenNote(!isOpenNote);
    };
    useEffect(() => {
        dispatch(getCandidateListing(filterOptions)).then((res) => {
            if (res.statusCode === 200) {
                setRowData(res.data.candidates_list);
            }
        });
        if (props.history.location.state === 'storage'){
            setIsEdit(true)
        }
    }, [ dispatch, filterOptions, props ]);
    const handleCandidateStatus = (details, type, role) => {
        const data = {
            status_type : type,
            id : details.id ? details.id : details.candidate_id ? details.candidate_id : details.campaign_id ,
            status : role
        }
        dispatch(updateStatus(data)).then((result)=> {
            if (result && result.data && result.data.status_type === 1){
                dispatch(getCandidateListing(filterOptions)).then((res) => {
                    if (res.statusCode === 200) {
                        setRowData(res.data.candidates_list);
                    }
                });
            }
        });
    };
    const date = new Date();
    const notesDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
    const newRow = {
        full_name: 'New Candidate',
        location: 'Location',
        tech_stack: '',
        act_campaigns: '0',
        clients_associated: '0 Client',
        joined: ' ',
        educations: [],
        skills: [],
        hobbies: [],
        first_name: '',
        last_name: '',
        experience: '',
        assessment_score: '',
    };
    const addNew = () => {
        var temp = rowData;
        temp.push(newRow);
        setRowData([ ...temp ]);
        setInputField({})
        setIsAddNewCandidate(true);
    };
    const handleCloseCandidate = () => {
        setIsAddNewCandidate(!isAddNewCandidate);
    };
    const handleEditFunc = () => {
        setIsEdit(!isEdit);
        setBtnIsDisabled(true)
    };
    const addNewField = () => {
        setInputField([ ...inputField, { university: '', education: '' } ]);
    };
    const removeCandidateFunc = (candidate, role) => {
        if(role === 'education'){
            const data = {
                candidate_id : candidate.candidate_id,
                is_profile_delete: role === 'profile' ? 1 : 0,
                is_education_delete: role === 'education' ? 1 : 0,
                candidate_education_id: role === 'education' ? candidate.id : 0,
                is_resume_delete: role === 'resume' ? 1 : 0,
            };
            dispatch(
                deleteCandidate(data, data.candidate_id)
            ).then((res) => {
                if (res.statusCode === 200) {
                    setRowData(res.data.candidates_list);
                    window.location.reload();
                }
            });
        } else if(role === 'resume') {
            const data = {
                is_profile_delete: role === 'profile' ? 1 : 0,
                is_education_delete: role === 'education' ? 1 : 0,
                candidate_education_id: role === 'education' ? candidate.id : 0,
                is_resume_delete: role === 'resume' ? 1 : 0,
            };
            dispatch(deleteCandidate(data, candidate.id ? candidate.id : candidate.candidate_id))
        } else {
            const data = {
                is_profile_delete: role === 'profile' ? 1 : 0,
                is_education_delete: role === 'education' ? 1 : 0,
                candidate_education_id: role === 'education' ? candidate.id : 0,
                is_resume_delete: role === 'resume' ? 1 : 0,
            };
            dispatch(
                deleteCandidate(data, candidate.id ? candidate.id : candidate.candidate_id)
            ).then((res) => {
                if (res.statusCode === 200) {
                    setRowData(res.data.candidates_list);
                    window.location.reload();
                }
            });
        }
    };
    const updateSearch = (event) => {
        setSearchValue(event.target.value.substr(0, 20));
    };
    const addNewCandidateFunc = () => {
        const {
            first_name,
            last_name,
            email_id,
            phone_number,
            city,
            state,
            resume,
            tech_stack,
            experience,
            assessment_score,
            notes,
            educations,
        } = inputField;
        const data = {
            first_name: first_name,
            avatar: profileImage,
            last_name: last_name,
            email_id: email_id || '',
            phone_number: phone_number,
            city: city,
            state: state,
            resume: resumeName ? resumeName : resume || '',
            tech_stack: tech_stack,
            experience: experience,
            assessment_score: assessment_score,
            notes: notesValue ? notesValue : notes ? notes : '',
            educations: educationArray,
            skills: skillArray,
            hobbies: hobbyArray,
        };
        dispatch(addCandidateAction(data)).then((result) => {
            if (result.statusCode === 200) {
                dispatch(getCandidateListing(filterOptions)).then((res) => {
                    if (res.statusCode === 200) {
                        setRowData(res.data.candidates_list);
                        setIsAddNewCandidate(!isAddNewCandidate);
                        setEducationArray([
                            {
                                id: 0,
                                candidate_id: 0,
                                course: '',
                                specialization: '',
                                university: '',
                            },
                        ])
                    }
                });
            }
        });
    };
    const updateCandidateFunc = () => {
        const {
            first_name,
            last_name,
            email_id,
            phone_number,
            city,
            state,
            resume,
            tech_stack,
            experience,
            assessment_score,
            notes,
            educations,
            skills,
            hobbies,
            id,
            linkedin_url,
            github_url,
            portfolio_url
        } = inputField;
        const data = {
            first_name: first_name,
            avatar: profileFileName,
            last_name: last_name,
            email_id: email_id,
            phone_number: phone_number,
            city: city,
            state: state,
            resume: resumeName ? resumeName.split('Candidates/')[ 1 ] : resume || 'notes',
            tech_stack: tech_stack,
            experience: experience,
            assessment_score: assessment_score,
            notes: !notesValue
                ? (notes && notes.toString()) || ''
                : isNotesData ? notesDate.concat(" : "+ notesValue.toString()) : notesValue.toString(),
            educations: educationArray[ 0 ].course === "" ? educations : educationArray,
            skills: skills,
            hobbies: hobbies,
            id: id,
            linkedin_url: linkedin_url ? linkedin_url : '',
            github_url: github_url ? github_url : '',
            portfolio_url: portfolio_url ? portfolio_url : ''
        };
        const message = 'The notes entered exceeds the maximum limit and its should be 500 words'
        let numWords = 0;
        for (var i = 0; i < data.notes.length; i++) {
            var currentCharacter = data.notes[ i ];
            if (currentCharacter === " ") {
                numWords += 1;
            }
        }
        numWords += 1;
        if(numWords <= 500) {
            dispatch(updateCandidates(id, data)).then((res) => {
                if (res.statusCode === 200) {
                    dispatch(getCandidateListing()).then((result) => {
                        setRowData(result.data.candidates_list);
                    })
                }
            });
            setIsOpenNote(false);
        } else {
            triggerNotifier({ type: 'error', message })
        }
        setBtnIsDisabled(true)
    };
    const handleChange = (e) => {
        if (e.target.type.includes('file')) {
            inputField[ e.target.name ] = e.target.files[ 0 ];
            var data = new FormData();
            data.append('file', inputField.resume);
            data.append('email', inputField.email_id);
            data.append('is_resume', 1);
            setInputField({ ...inputField });
            dispatch(uploadResume('Candidates', data)).then((res) => {
                if(res.statusCode === 200) {
                    setIsOpenDeleteModal(false)
                }
            });
        } else {
            setInputField({ ...inputField, [ e.target.name ]: e.target.value });
        }
    };
    const handleSkillsChange = (e, idm, skill , rowIndex) => {
        if (isEdit) {
            const oldArray = rowData[ rowIndex ].skills;
            const obj = {
                id: 0,
                candidate_id: inputField.id,
                skill_name: e.target.value
            }
            oldArray[ idm ] = obj;
            setSkillArray(oldArray);
            setInputField({ ...inputField, [ e.target.name ]: oldArray });
        } else {
            const newArray = [ ...skillArray ];
            newArray[ idm ] = e.target.value;
            setSkillArray(newArray);
            setInputField({ ...inputField, [ e.target.name ]: newArray });
        }
    };
    const handleHobbiesChange = (e, idm, hobby, rowIndex) => {
        if (isEdit) {
            const oldArray = rowData[ rowIndex ].hobbies;
            const obj = {
                id: 0,
                candidate_id: inputField.id,
                hobby_name: e.target.value
            }
            oldArray[ idm ] = obj;
            setHobbyArray(oldArray);
            setInputField({ ...inputField, [ e.target.name ]: oldArray });
        } else {
            const newArray = [ ...hobbyArray ];
            newArray[ idm ] = e.target.value;
            setHobbyArray(newArray);
            setInputField({ ...inputField, [ e.target.name ]: newArray });
        }
    };
    const handleInputChange = (e , index) => {
        const { name, value } = e.target;
        const list = [ ...educationArray ];
        list[ index ][ name ] = value;
        list[ index ][ 'candidate_id' ] = inputField.id;
        setEducationArray(list);
    }

    const handleAddInput = () => {
        setEducationArray([ ...educationArray, {  id: 0 , candidate_id: 0 , university: '', specialization: '', course: '' } ])
    }

    const handleRemoveInput = index => {
        const list = [ ...educationArray ];
        list.splice(index, 1);
        setEducationArray(list);
    }

    const handleCandidateOptions = (event) => {
        setFilterOptions({
            ...filterOptions,
            [ event.target.name ]: event.target.value,
        });
    };
    const handleEditNotes = (event) => {
        setNotesValues(event.target.value);
    };
    const handleCandidateProfile = (details, id, idx) => {
        setBtnIsDisabled(false)
        setInputField(details);
        if (toggleArrowIndex === id) {
            setToggleArrowIndex('');
            setToggleArrowEdit(!toggleArrowEdit);
        } else {
            setToggleArrowIndex(id);
            setToggleArrowEdit(!toggleArrowEdit);
        }
    };
    const uploadProfilePicture = (e) => {
        inputField[ e.target.name ] = e.target.files[ 0 ];
        setProfileFileName(e.target.files[ 0 ].name)
        var data = new FormData();
        data.append('file', inputField.avatar);
        data.append('email', inputField.email_id);
        setInputField({ ...inputField });
        dispatch(uploadImage('Candidates', data)).then((res) => {
            if (res.statusCode === 200) {
                setInputField({ ...inputField, avatar: res.data.file_url });
            }
        });
    };
    const handleDeleteModal = (id, type) => {
        setIsOpenDeleteModal(!isOpenDeleteModal)
        setCandidateInfo(id)
        setuserType(type)
    }
    const uploadResumeHandler = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal)
        setuserType('uploadResume')
    }
    const handleNoteData = (data) => {
        if((data && data.notes === '') || (data && data.notes && data.notes.length <= 0)){
            setIsNotesData(true)
        }
    }
    const sorted = tableData && tableData.sort((a, b) => {
        const isReversed = !sortType ? '' :  (sortType === 'asc') ? 1 : -1
        if(columnName === 'full-name'){
            return isReversed*a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
        }
        else if(columnName === 'location'){
            return (isReversed*a.city.toLowerCase().localeCompare(b.city.toLowerCase()))
        }
        else if(columnName === 'tech'){
            return (isReversed*a.tech_stack.toLowerCase().localeCompare(b.tech_stack.toLowerCase()))
        }
        else if(columnName === 'campaigns'){
            if(isReversed === 1){
                return isReversed*a.act_campaigns - b.act_campaigns
            } else if(isReversed === -1){
                return b.act_campaigns - a.act_campaigns
            } else {
                return a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
            }
        }
        else if(columnName === 'associated'){
            if(isReversed === 1){
                return isReversed*a.associated_clients - b.associated_clients
            } else if(isReversed === -1){
                return b.associated_clients - a.associated_clients
            } else {
                return a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
            }
        }
        else if(columnName === 'registered'){
            const date1 = new Date(a.registration_datetime);
            const date2 = new Date(b.registration_datetime);
            return (isReversed*date1.getTime() - isReversed*date2.getTime())
        }
        else {
            return a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
        }
    })
    const handleColumnName = (data) => {
        setColumnName(data)
    }
    const onSort = (order, data) => {
        setSortType(order);
        setColumnName(data)
        if(data === 'full-name') {
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setAssociatedArrowUp(false)
            setAssociatedArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setFullNameArrowUp(!fullNameArrowUp)
        }
        if(data === 'location') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setAssociatedArrowUp(false)
            setAssociatedArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setLocationArrowUp(!locationArrowUp)
        }
        if(data === 'tech') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setAssociatedArrowUp(false)
            setAssociatedArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setTechArrowUp(!techArrowUp)
        }
        if(data === 'campaigns') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setAssociatedArrowUp(false)
            setAssociatedArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setCampaignsArrowUp(!campaignsArrowUp)
        }
        if(data === 'associated') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setAssociatedArrowUp(!associatedArrowUp)
        }
        if(data === 'registered') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setAssociatedArrowUp(false)
            setAssociatedArrowDown(false)
            setRegisteredArrowUp(!registeredArrowUp)
        }
    };
    const onSortUp = (order, data) => {
        setSortType(order);
        if(columnName === 'full-name' || data === 'full-name') {
            setFullNameArrowUp(!fullNameArrowUp)
            setFullNameArrowDown(!fullNameArrowDown)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setAssociatedArrowUp(false)
            setAssociatedArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'location' || data === 'location') {
            setLocationArrowUp(!locationArrowUp)
            setLocationArrowDown(!locationArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setAssociatedArrowUp(false)
            setAssociatedArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'tech' || data === 'tech') {
            setTechArrowUp(!techArrowUp)
            setTechArrowDown(!techArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setAssociatedArrowUp(false)
            setAssociatedArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'campaigns' || data === 'campaigns') {
            setCampaignsArrowUp(!campaignsArrowUp)
            setCampaignsArrowDown(!campaignsArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setAssociatedArrowUp(false)
            setAssociatedArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'associated' || data === 'associated') {
            setAssociatedArrowUp(!associatedArrowUp)
            setAssociatedArrowDown(!associatedArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'registered' || data === 'registered') {
            setRegisteredArrowDown(!registeredArrowDown)
            setRegisteredArrowUp(!registeredArrowUp)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setAssociatedArrowUp(false)
            setAssociatedArrowDown(false)
        }
    };
    const onSortDown = (order) => {
        setSortType(order);
        if(columnName === 'full-name') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
        } else if(columnName === 'location') {
            setLocationArrowUp(false)
            setLocationArrowDown(false)
        } else if(columnName === 'tech') {
            setTechArrowUp(false)
            setTechArrowDown(false)
        } else if(columnName === 'campaigns') {
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        } else if(columnName === 'associated') {
            setAssociatedArrowUp(false)
            setAssociatedArrowDown(false)
        } else if(columnName === 'registered'){
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        }
    };
    return (
        <>
            <AuthHeaderSection
                addNewRow={ addNew }
                handleEditFunc={ handleEditFunc }
                history={ props.history }
                isEdit={ isEdit }
                updateSearch={ updateSearch }
                searchValue={ searchValue }
                updateCandidateFunc={ updateCandidateFunc }
                handleCandidateOptions={ handleCandidateOptions }
                btnIsDisabled={ btnIsDisabled }
            />
            <div className="table-responsive main-div">
                <table
                    className="main-table table table-condensed github table-borderless"
                    id="accordion"
                >
                    <thead>
                        <tr className="table-head">
                            <th scope="col" className="s_no" style={ { width: '4%' } }>#</th>
                            <th scope="col" style={ { width: '4%' } }></th>
                            <th scope="col" style={ { width: '15%' } }>
                                Full Name{' '}
                                <Sort
                                    data= { 'full-name' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp = { fullNameArrowUp }
                                    sortArrowDown ={ fullNameArrowDown }
                                    onSort={ onSort }
                                    onSortUp={ onSortUp }
                                    onSortDown={ onSortDown }
                                />
                            </th>
                            <th scope="col" style={ { width: '15%' } }>
                                Location{' '}
                                <Sort
                                    data= { 'location' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp = { locationArrowUp }
                                    sortArrowDown ={ locationArrowDown }
                                    onSort={ onSort }
                                    onSortUp={ onSortUp }
                                    onSortDown={ onSortDown }
                                />
                            </th>
                            <th scope="col" style={ { width: '10%' } }>
                                Tech Stack{' '}
                                <Sort
                                    data= { 'tech' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp = { techArrowUp }
                                    sortArrowDown ={ techArrowDown }
                                    onSort={ onSort }
                                    onSortUp={ onSortUp }
                                    onSortDown={ onSortDown }
                                />
                            </th>
                            <th scope="col" className="act-center" style={ { width: '10%' } }>
                                Act. Campaigns{' '}
                                <Sort
                                    data= { 'campaigns' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp = { campaignsArrowUp }
                                    sortArrowDown ={ campaignsArrowDown }
                                    onSort={ onSort }
                                    onSortUp={ onSortUp }
                                    onSortDown={ onSortDown }
                                />
                            </th>
                            <th scope="col" className="act-center" style={ { width: '15%' } }>
                                Clients Associated{' '}
                                <Sort
                                    data= { 'associated' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp={ associatedArrowUp }
                                    sortArrowDown={ associatedArrowDown }
                                    onSort={ onSort }
                                    onSortUp={ onSortUp }
                                    onSortDown={ onSortDown }
                                />
                            </th>
                            <th scope="col" style={ { width: '10%' } }>
                                Joined{' '}
                                <Sort
                                    data= { 'registered' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp={ registeredArrowUp }
                                    sortArrowDown={ registeredArrowDown }
                                    onSort={ onSort }
                                    onSortUp={ onSortUp }
                                    onSortDown={ onSortDown }
                                />
                            </th>
                            <th scope="col" style={ { width: '4%' } }></th>
                            {isEdit && <th scope="col" style={ { width: '4%' } }></th>}
                        </tr>
                    </thead>
                    {isLoading && (
                        <div className="loader">
                            <Loading />
                        </div>
                    )}
                    {!isLoading &&
            sorted &&
            sorted.map((candidate, idx) => {
                return (
                    <tbody key={ idx }>
                        <>
                            <tr
                                className="accordion-toggle table-row"
                                data-bs-toggle="collapse"
                                href={ `#toggle${ idx }` }
                                onClick={ () =>
                                    handleCandidateProfile(candidate, candidate.id, idx)
                                }
                            >
                                <th scope="row" className="s_no">
                                    {idx + 1}
                                </th>
                                <td>
                                    {candidate.is_active ? (
                                        <img
                                            src={ Play }
                                            alt="play"
                                            className="play-icon"
                                            onClick={ () =>
                                                handleCandidateStatus(candidate, 1, 0)
                                            }
                                        />
                                    ) : (
                                        <img
                                            src={ Pause }
                                            alt="pause"
                                            className="pause-icon pause-img"
                                            onClick={ () =>
                                                handleCandidateStatus(candidate, 1, 1)
                                            }
                                        />
                                    )}
                                </td>
                                <td>{candidate.full_name}</td>
                                <td>{cityCountryFormatter(candidate)}</td>
                                <td>{candidate.tech_stack}</td>
                                <td >
                                    <span
                                        className="tabss"
                                        onClick={ () =>
                                            props.history.push({
                                                pathname: '/associate-candidates',
                                                state: {
                                                    campId: candidate.id,
                                                    candName: candidate.full_name,
                                                },
                                            })
                                        }
                                    >
                                        <b>{candidate.act_campaigns}</b>
                                    </span>
                                </td>
                                <td >
                                    <span
                                        className="tabss"
                                        onClick={ () =>
                                            props.history.push({
                                                pathname: '/ward-clients',
                                                state: {
                                                    campId: candidate.id,
                                                    candName: candidate.full_name,
                                                },
                                            })
                                        }
                                    >
                                        <b>{candidate.associated_clients} </b>{' '}
                                        {candidate.associated_clients <= 1 ? (
                                            <span>Client</span>
                                        ) : (
                                            <span>Clients</span>
                                        )}
                                    </span>
                                </td>
                                <td>
                                    {moment(candidate.registration_datetime).format(ddmmyyFormat)}{' '}
                                </td>
                                {isEdit && (
                                    <td>
                                        <img
                                            onClick={ () => handleDeleteModal(candidate, 'candidate') }
                                            src={ Trash }
                                            alt="trash"
                                            className='cursor'
                                        />
                                    </td>
                                )}
                                <td>
                                    <img
                                        src={ sideArrow }
                                        alt="side-arrow"
                                        className="down"
                                    />
                                </td>
                            </tr>
                            {isAddNewCandidate ? (
                                <tr className="collapse-row dynamicrow" data-bs-parent="#myTable1">
                                    <td colSpan="12" className="hiddenRow">
                                        <div
                                            className="accordian-body collapse 33"
                                            id={ `toggle${ idx }` }
                                            data-bs-parent="#accordion"
                                        >
                                            <EditCandidateFormBody
                                                candidate={ candidate }
                                                removeCandidateFunc={ removeCandidateFunc }
                                                handleChange={ handleChange }
                                                addNewField={ addNewField }
                                                inputField={ inputField }
                                                uploadProfilePicture={ uploadProfilePicture }
                                                handleCloseCandidate={ handleCloseCandidate }
                                                addNewCandidateFunc={ addNewCandidateFunc }
                                                handleSkillsChange={ handleSkillsChange }
                                                handleHobbiesChange={ handleHobbiesChange }
                                                educationArray={ educationArray }
                                                setEducationArray={ setEducationArray }
                                                handleInputChange= { handleInputChange }
                                                handleAddInput= { handleAddInput }
                                                handleRemoveInput = { handleRemoveInput }
                                                isAddNewCandidate={ isAddNewCandidate }
                                                rowIndex={ idx }
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ) : !isEdit ? (
                                <tr className="collapse-row dynamicrow" data-bs-parent="#myTable1">
                                    <td colSpan="12" className="hiddenRow">
                                        <div
                                            className="accordian-body collapse main-candidate  extendedrev"
                                            id={ `toggle${ idx }` }
                                            data-bs-parent="#accordion"
                                        >
                                            <div className="epic-extendedrev row g-0">
                                                <div className="clicked3 rectangle col-lg-1 col-md-4 text-center">
                                                    <AvatarComp
                                                        image={ candidate.avatar }
                                                        name={ candidate.full_name }
                                                    />
                                                    <br />
                                                    {candidate.is_active ? (
                                                        <img
                                                            src={ Play }
                                                            alt="play"
                                                            className="play-icon"
                                                            onClick={ () =>
                                                                handleCandidateStatus(candidate, 1, 0)
                                                            }
                                                        />
                                                    ) : (
                                                        <img
                                                            src={ Pause }
                                                            alt="pause"
                                                            className="pause-icon pause-img"
                                                            onClick={ () =>
                                                                handleCandidateStatus(candidate, 1, 1)
                                                            }
                                                        />
                                                    )}
                                                </div>
                                                <div className="clicked col-lg-2 col-md-4 mt-2">
                                                    <table>
                                                        <tr>
                                                            <td className="title">Name</td>
                                                            <td className="sub-title">
                                                                {candidate.first_name}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="title">Surname</td>
                                                            <td className="sub-title">
                                                                {candidate.last_name}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="title">Location</td>
                                                            <td className="sub-title">
                                                                {cityCountryFormatter(candidate)}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div className="clicks1 col-lg-2 col-md-4">
                                                    <table>
                                                        <tr>
                                                            <td className="title">Tech Stack</td>
                                                            <td className="sub-title">
                                                                {candidate.tech_stack}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="title">Experience</td>
                                                            <td className="sub-title">
                                                                {candidate.experience} years
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="title">Assesment</td>
                                                            <td className="sub-title">
                                                                {candidate.assessment_score}%
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div className="datanotes col-lg-3 col-md-5">
                                                    <p className="mb-0">
                                                        <b>Notes</b>
                                                    </p>
                                                    <p onClick={ () => handleOpenNotes(candidate) }>
                                                        <form>
                                                            <textarea
                                                                id="w3review"
                                                                placeholder="Make your note"
                                                                name="w3review"
                                                                onClick={ () => { handleOpenNotes(candidate); handleNoteData(candidate) } }
                                                                defaultValue={
                                                                    candidate.notes || inputField.notes
                                                                }
                                                                readOnly
                                                            ></textarea>
                                                        </form>
                                                    </p>
                                                    <form />
                                                </div>
                                                <div className="clickeb col-lg-3 col-md-4">
                                                    <div className="educatab">
                                                        <h4>Education</h4>
                                                        <ul className="stanford">
                                                            {candidate.educations.map(
                                                                (education, idm) => {
                                                                    return (
                                                                        <>
                                                                            <li key={ idm } className="universe">
                                                                                {education.university}
                                                                            </li>
                                                                            <li className="universal">
                                                                                {`${ education.specialization }, ${ education.course }`}
                                                                            </li>
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="skillbuilding row mt-5 mb-3 g-0">
                                                <div className="skillearn col-lg-3 col-md-6 col-sm-12">
                                                    <h4>
                                                        <b>Skills</b>
                                                    </h4>
                                                    <ul className="jbond2 mb-0">
                                                        {candidate.skills.map((skill, idm) => {
                                                            return (
                                                                <li className="skiled2" key={ idm }>
                                                                    {skill.id === 0
                                                                        ? ''
                                                                        : `- ${ skill.skill_name }`}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                                <div className="hobbie col-lg-2 col-md-4 col-sm-12">
                                                    <h4>Hobbies</h4>
                                                    <ul className="jbond1 mb-0">
                                                        {candidate.hobbies.map((hobby, idm) => {
                                                            return (
                                                                <li className="hobie2" key={ idm }>
                                                                    {hobby.id === 0
                                                                        ? ''
                                                                        : `- ${ hobby.hobby_name }`}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                                <div className="datanotes col-lg-1 col-md-2 col-sm-12">
                                                    <div className='d-flex flex-column social-links'>
                                                        {
                                                            (candidate.github_url !== null && candidate.github_url !== "") ?
                                                                (
                                                                    <Link to={ { pathname: candidate.github_url } }  target="_blank" >
                                                                        <img src={ Github } alt='git-img' width='20' />
                                                                    </Link>
                                                                )
                                                                :
                                                                (
                                                                    <img src={ Github } alt='git-img' className='fade-link' width='20' />
                                                                )
                                                        }
                                                        {
                                                            (candidate.linkedin_url !== null && candidate.linkedin_url !== "") ?
                                                                (
                                                                    <Link to={ { pathname: candidate.linkedin_url } }  target="_blank" >
                                                                        <img src={ Linkedin } alt='linkedin-img' width='20' />
                                                                    </Link>
                                                                )
                                                                :
                                                                (
                                                                    <img src={ Linkedin } alt='linkedin-img' className='fade-link' width='20' />
                                                                )
                                                        }
                                                        {
                                                            (candidate.portfolio_url !== null && candidate.portfolio_url !== "") ?
                                                                (
                                                                    <Link to={ { pathname: candidate.portfolio_url } }  target="_blank" >
                                                                        <img src={ Portfolio } alt='portfolio-img' width='20' />
                                                                    </Link>
                                                                )
                                                                :
                                                                (
                                                                    <img src={ Portfolio } alt='linkedin-img' className='fade-link' width='20' />
                                                                )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="profiledisk col-lg-3 col-md-12 col-sm-12 d-flex justify-content-end align-items-end">
                                                    <button className="vote">
                                                        <AiOutlineFileSearch className="resume-icon" />
                                                        <a
                                                            href={ candidate.resume }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            View Resume
                                                        </a>
                                                    </button>
                                                    <button
                                                        className="vote"
                                                        type="button"
                                                        name="button"
                                                        disabled={ !candidate.is_active }
                                                        onClick={ () =>
                                                            props.history.push({
                                                                pathname: '/send-to-vote',
                                                                state: {
                                                                    candName: candidate.full_name,
                                                                    candId: candidate.id,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        Send to Vote
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                <tr className="collapse-row dynamicrow" key={ idx } data-bs-parent="#myTable1">
                                    <td colSpan="12" className="hiddenRow">
                                        <div
                                            className="accordian-body collapse"
                                            id={ `toggle${ idx }` }
                                            data-bs-parent="#accordion"
                                        >
                                            <EditCandidateFormBody
                                                candidate={ candidate }
                                                removeCandidateFunc={ removeCandidateFunc }
                                                handleChange={ handleChange }
                                                addNewField={ addNewField }
                                                inputField={ inputField }
                                                uploadProfilePicture={ uploadProfilePicture }
                                                handleCloseCandidate={ handleCloseCandidate }
                                                addNewCandidateFunc={ addNewCandidateFunc }
                                                handleSkillsChange={ handleSkillsChange }
                                                handleHobbiesChange={ handleHobbiesChange }
                                                educationArray={ educationArray }
                                                setEducationArray={ setEducationArray }
                                                handleInputChange= { handleInputChange }
                                                handleAddInput= { handleAddInput }
                                                handleRemoveInput = { handleRemoveInput }
                                                isAddNewCandidate={ isAddNewCandidate }
                                                rowIndex={ idx }
                                                handleCandidateStatus = { handleCandidateStatus }
                                                uploadResumeHandler={ uploadResumeHandler }
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </>
                    </tbody>
                );
            })}
                </table>
            </div>
            {isOpenNote && (
                <NotesSection
                    handleOpenNotes={ handleOpenNotes }
                    handleEditNotes={ handleEditNotes }
                    clientList={ rowData }
                    handleCandidatesNotes={ updateCandidateFunc }
                    handleCloseNotes={ handleCloseNotes }
                    notesValue={ inputField }
                />
            )}
            {isOpenDeleteModal && (
                <DeleteModal
                    isOpenDeleteModal={ isOpenDeleteModal }
                    removeCandidateFunc={ removeCandidateFunc }
                    candidateInfo={ candidateInfo }
                    handleDeleteModal={ handleDeleteModal }
                    userType={ userType }
                    handleChange={ handleChange }
                />
            )}
        </>
    );
};

CandidatesSection.propTypes = {
    history: PropTypes.object,
};

export default CandidatesSection;
