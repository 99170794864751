import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import HiringHeader from './hiringHeader';
import { getHiresCandidates } from 'actions/clientActions';
import moment from 'moment';
import { cityCountryFormatter, ddmmyyFormat } from 'shared/Constant';
import Play from 'static/images/play.svg';
import Pause from 'static/images/pause.svg';
import { handleStatus } from 'utils/Helper';
import Loading from 'shared/Loader';
import Sort from 'shared/Sorting';

function HiringSection(props) {
    const dispatch = useDispatch()
    const [ searchValue, setSearchValue ] = useState('');
    const hireCandidates = useSelector(state => state.hireCandidates)
    const isLoading = useSelector(state => state.applicationIsLoading)
    const clientId = props.history.location.state.clientId
    const companyName = props.history.location.state.companyName
    const [ filterOptions, setFilterOptions ] = useState({
        city: '', state: '', tech_stack:''
    });

    const [ sortType, setSortType ] = useState('asc');
    const [ columnName, setColumnName ] = useState('');
    const [ fullNameArrowUp, setFullNameArrowUp ] = useState(false);
    const [ fullNameArrowDown, setFullNameArrowDown ] = useState(false);
    const [ locationArrowUp, setLocationArrowUp ] = useState(false);
    const [ locationArrowDown, setLocationArrowDown ] = useState(false);
    const [ techArrowUp, setTechArrowUp ] = useState(false);
    const [ techArrowDown, setTechArrowDown ] = useState(false);
    const [ campaignsArrowUp, setCampaignsArrowUp ] = useState(false);
    const [ campaignsArrowDown, setCampaignsArrowDown ] = useState(false);
    const [ registeredArrowUp, setRegisteredArrowUp ] = useState(false);
    const [ registeredArrowDown, setRegisteredArrowDown ] = useState(false);
    const [ mlArrowUp, setMlArrowUp ] = useState(false);
    const [ mlArrowDown, setMlArrowDown ] = useState(false);

    useEffect(() => {
        dispatch(getHiresCandidates(clientId, filterOptions))
    }, [ dispatch, clientId, filterOptions ])
    const updateSearch = (event) => {
        setSearchValue(event.target.value.substr(0, 20));
    };
    const handleCandidateOptions = (event) => {
        setFilterOptions({ ...filterOptions , [ event.target.name ]: event.target.value })
    }
    const localStorageHandler = (data) => {
        localStorage.setItem('campaignId', data.campaign_id);
        localStorage.setItem('campaignName', data.campaign_name);
    }
    const sorted = hireCandidates && hireCandidates.sort((a, b) => {
        const isReversed = !sortType ? '' :  (sortType === 'asc') ? 1 : -1
        if(columnName === 'full-name'){
            return isReversed*a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
        }
        else if(columnName === 'location'){
            return (isReversed*a.city.toLowerCase().localeCompare(b.city.toLowerCase()))
        }
        else if(columnName === 'tech'){
            return (isReversed*a.tech_stack.toLowerCase().localeCompare(b.tech_stack.toLowerCase()))
        }
        else if(columnName === 'campaigns'){
            return (isReversed*a.campaign_name.toLowerCase().localeCompare(b.campaign_name.toLowerCase()))
        }
        else if(columnName === 'registered'){
            const date1 = new Date(a.joined_date);
            const date2 = new Date(b.joined_date);
            return (isReversed*date1.getTime() - isReversed*date2.getTime())
        }
        else {
            return a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
        }
    })
    const handleColumnName = (data) => {
        setColumnName(data)
    }
    const onSort = (order, data) => {
        setSortType(order);
        setColumnName(data)
        if(data === 'full-name') {
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setFullNameArrowUp(!fullNameArrowUp)
        }
        if(data === 'location') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setLocationArrowUp(!locationArrowUp)
        }
        if(data === 'tech') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setTechArrowUp(!techArrowUp)
        }
        if(data === 'campaigns') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(!campaignsArrowUp)
        }
        if(data === 'ml') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setMlArrowUp(!mlArrowUp)
        }
        if(data === 'registered') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setRegisteredArrowUp(!registeredArrowUp)
        }
    };
    const onSortUp = (order, data) => {
        setSortType(order);
        if(columnName === 'full-name' || data === 'full-name') {
            setFullNameArrowUp(!fullNameArrowUp)
            setFullNameArrowDown(!fullNameArrowDown)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'location' || data === 'location') {
            setLocationArrowUp(!locationArrowUp)
            setLocationArrowDown(!locationArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'tech' || data === 'tech') {
            setTechArrowUp(!techArrowUp)
            setTechArrowDown(!techArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'campaigns' || data === 'campaigns') {
            setCampaignsArrowUp(!campaignsArrowUp)
            setCampaignsArrowDown(!campaignsArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'ml' || data === 'ml') {
            setMlArrowUp(!mlArrowUp)
            setMlArrowDown(!mlArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
        } else if(columnName === 'registered' || data === 'registered') {
            setRegisteredArrowDown(!registeredArrowDown)
            setRegisteredArrowUp(!registeredArrowUp)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        }
    };
    const onSortDown = (order) => {
        setSortType(order);
        if(columnName === 'full-name') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
        } else if(columnName === 'location') {
            setLocationArrowUp(false)
            setLocationArrowDown(false)
        } else if(columnName === 'tech') {
            setTechArrowUp(false)
            setTechArrowDown(false)
        } else if(columnName === 'ml') {
            setMlArrowUp(false)
            setMlArrowDown(false)
        } else if(columnName === 'campaigns') {
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        } else if(columnName === 'registered'){
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        }
    };
    return (
        <>
            <HiringHeader companyName={ companyName } updateSearch={ updateSearch }
                searchValue={ searchValue } handleCandidateOptions={ handleCandidateOptions } />
            <table className="main-table table table-condensed github table-borderless">
                <thead className="Campaignname">
                    <tr className="table-head">
                        <th scope="col" className="s_no" style={ { width: '2%' } }>&nbsp;&nbsp;#</th>
                        <th scope="col" style={ { width: '3%' } }></th>
                        <th scope="col" style={ { width: '15%' } }>
                            Full Name {' '}
                            <Sort
                                data= { 'full-name' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { fullNameArrowUp }
                                sortArrowDown ={ fullNameArrowDown }
                                onSort={ onSort }
                                onSortUp={ onSortUp }
                                onSortDown={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '12%' } }>
                            Location{' '}
                            <Sort
                                data= { 'location' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { locationArrowUp }
                                sortArrowDown ={ locationArrowDown }
                                onSort={ onSort }
                                onSortUp={ onSortUp }
                                onSortDown={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '15%' } }>
                            Tech Stack{' '}
                            <Sort
                                data= { 'tech' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { techArrowUp }
                                sortArrowDown ={ techArrowDown }
                                onSort={ onSort }
                                onSortUp={ onSortUp }
                                onSortDown={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '8%' } }>
                            ML%{' '}
                            <Sort
                                data= { 'ml' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { mlArrowUp }
                                sortArrowDown ={ mlArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '20%' } }>
                            Campaign name(s){' '}
                            <Sort
                                data= { 'campaigns' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { campaignsArrowUp }
                                sortArrowDown ={ campaignsArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '10%' } }>
                            Started{' '}
                            <Sort
                                data= { 'registered' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp={ registeredArrowUp }
                                sortArrowDown={ registeredArrowDown }
                                onSort={ onSort }
                                onSortUp={ onSortUp }
                                onSortDown={ onSortDown }
                            />
                        </th>
                    </tr>
                </thead>
                {isLoading && (
                    <div className="loader">
                        <Loading />
                    </div>
                )}
                <tbody>
                    {!isLoading && sorted && sorted.map((data, idx)=>{
                        return <tr key={ idx } className="table-row">
                            <th scope="row" className="s_no">&nbsp;&nbsp;{ idx + 1 }</th>
                            <td>
                                {data.is_active ? (
                                    <img src={ Play } alt="play" className="play-icon" onClick={ () => handleStatus(dispatch, data, 1, 0) } />
                                ) : (
                                    <img src={ Pause } alt="pause" className="pause-icon pause-img" onClick={ () => handleStatus(dispatch, data, 1, 1) }/>
                                )}
                            </td>
                            <td>{data.full_name}</td>
                            <td>{cityCountryFormatter(data)}</td>
                            <td>{data.tech_stack}</td>
                            <td>92%</td>
                            <td>
                                <Link className="epicdetail"
                                    to={ {
                                        pathname: '/storage',
                                        state: {
                                            campId: data.campaign_id,
                                            campName: data.campaign_name
                                        },
                                        query: data.campaign_id,
                                        campaign_name: data.campaign_name
                                    } }
                                    onClick={ () => localStorageHandler(data) }
                                >
                                    {data.campaign_name}
                                </Link>
                            </td>
                            <td>{moment(data.joined_date).format(ddmmyyFormat) } </td>
                        </tr>
                    }) }
                </tbody>
            </table>
        </>
    );
}
HiringSection.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default HiringSection;
