import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Trash from 'static/images/trash.svg';
import sideArrow from 'static/images/left.svg';
import downArrow from 'static/images/downArrow.svg';
import Play from 'static/images/play.svg';
import { handleStatus } from 'utils/Helper';
import Pause from 'static/images/pause.svg';
import { cityCountryFormatter, ddmmyyFormat } from 'shared/Constant';
import EditCandidateFormBody from './EditCandidateFormBody';
import Sort from 'shared/Sorting';
const EditCandidateForm = (props) => {
    const {
        candidateList,
        dispatch,
        history,
        removeCandidateFunc,
        handleChange,
        handleCandidateData,
        inputField,
    } = props;
    const [ toggleArrow, setToggleArrow ] = useState(false);
    return (
        <>
            <table className="table table-condensed github table-borderless">
                <thead>
                    <tr className="table-head">
                        <th scope="col" className="s_no">#</th>
                        <th></th>
                        <th scope="col">
                            Full Name <Sort />
                        </th>
                        <th scope="col">
                            Location <Sort />
                        </th>
                        <th scope="col">
                            Tech Stack <Sort />
                        </th>
                        <th scope="col" className="act-center">
                            Act. Campaigns <Sort />
                        </th>
                        <th scope="col" className="act-center">
                            Clients Associated <Sort />
                        </th>
                        <th scope="col">
                            Joined <Sort />
                        </th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                {candidateList &&
          candidateList.map((candidate, idx) => {
              return (
                  <tbody key={ idx }>
                      <tr className="resourcedata">
                          <th scope="row" className="s_no">&nbsp;&nbsp;&nbsp;{idx + 1}</th>
                          <th>
                              {candidate.is_active ? (
                                  <img src={ Play } alt="play" className="play-icon" onClick={ () => handleStatus(dispatch, candidate, 1, 0) }/>
                              ) : (
                                  <img src={ Pause } alt="pause" className="pause-icon pause-img" onClick={ () => handleStatus(dispatch, candidate, 1, 1) }/>
                              )}
                          </th>
                          <td>{candidate.full_name}</td>
                          <td>{cityCountryFormatter(candidate)}</td>
                          <td>{candidate.tech_stack}</td>
                          <td >
                              <span
                                  className="tabss"
                                  onClick={ () =>
                                      history.push({
                                          pathname: '/associate-candidates',
                                          state: {
                                              campId: candidate.id,
                                          },
                                      })
                                  }
                              >
                                  {candidate.act_campaigns}
                              </span>
                          </td>
                          <td >
                              <span
                                  className="tabss"
                                  onClick={ () =>
                                      history.push({
                                          pathname: '/ward-clients',
                                          state: {
                                              campId: candidate.id,
                                          },
                                      })
                                  }
                              >
                                  {candidate.associated_clients}
                              </span>
                          </td>
                          <td>{moment(candidate.joined_date).format(ddmmyyFormat)} </td>
                          <td>
                              <img
                                  onClick={ () => removeCandidateFunc(candidate) }
                                  src={ Trash }
                                  alt="trash"
                              />
                          </td>
                          <td
                              data-toggle="collapse"
                              data-target={ `#toggle${ idx }` }
                              onClick={ () => handleCandidateData(candidate) }
                              className="accordion-toggle"
                          >
                              {!toggleArrow ? (
                                  <img
                                      src={ sideArrow }
                                      onClick={ () => setToggleArrow(!toggleArrow) }
                                      alt="side-arrow"
                                      className="down"
                                  />
                              ) : (
                                  <img
                                      src={ downArrow }
                                      onClick={ () => setToggleArrow(!toggleArrow) }
                                      className="down-arrow"
                                      alt="side-arrow"
                                  />
                              )}
                          </td>
                      </tr>
                      <tr className="datacabl">
                          <td colSpan="12" className="hiddenRow">
                              <div
                                  className="accordian-body collapse"
                                  id={ `toggle${ idx }` }
                              >
                                  <EditCandidateFormBody
                                      candidate={ candidate }
                                      removeCandidateFunc={ removeCandidateFunc }
                                      handleChange={ handleChange }
                                      inputField={ inputField }
                                  />
                              </div>
                          </td>
                      </tr>
                  </tbody>
              );
          })}
            </table>
        </>
    );
};
EditCandidateForm.propTypes = {
    candidateList: PropTypes.array,
    removeCandidateFunc: PropTypes.func,
    handleCandidateData: PropTypes.func,
    handleChange: PropTypes.func,
    history: PropTypes.object,
    inputField: PropTypes.object,
    dispatch: PropTypes.func,
};
export default EditCandidateForm;
