import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import WardClientHeader from '../WardClients/wardClientHeader'
import { Link } from 'react-router-dom';
import Sort from 'shared/Sorting';
import Play from 'static/images/play.svg';
import Pause from 'static/images/pause.svg';
import { getCandidateActiveCampaigns } from 'actions/clientActions';
import Loading from 'shared/Loader';
import moment from 'moment';
import { handleStatus } from '../../../utils/Helper';
import { ddmmyyFormat } from 'shared/Constant';

function VoteSection(props , history) {
    const dispatch = useDispatch()
    const candId = props.history && props.history.location && props.history.location.state && props.history.location.state.campId !== undefined ? props.history.location.state.campId : props.history && props.history.location && props.history.location.state && props.history.location.state.candId;
    const candName = props.history && props.history.location && props.history.location.state && props.history.location.state.candName
    const [ searchValue, setSearchValue ] = useState('');
    const [ sortType, setSortType ] = useState('');
    const [ isHover, setIsHover ] = useState('');
    const [ columnName, setColumnName ] = useState('');
    const [ campaignsArrowUp, setCampaignsArrowUp ] = useState(false);
    const [ campaignsArrowDown, setCampaignsArrowDown ] = useState(false);
    const [ companyArrowUp, setCompanyArrowUp ] = useState(false);
    const [ companyArrowDown, setCompanyArrowDown ] = useState(false);
    const [ techArrowUp, setTechArrowUp ] = useState(false);
    const [ techArrowDown, setTechArrowDown ] = useState(false);
    const [ registeredArrowUp, setRegisteredArrowUp ] = useState(false);
    const [ registeredArrowDown, setRegisteredArrowDown ] = useState(false);
    const candidateActiveCampaigns = useSelector(state => state.candidateActiveCampaigns)
    const isLoading = useSelector(state => state.applicationIsLoading)
    const [ filterOptions, setFilterOptions ] = useState({
        city: '',
        state: '',
        tech_stack: '',
    });
    useEffect(() => {
        dispatch(getCandidateActiveCampaigns(candId, filterOptions))
    }, [ dispatch, candId, filterOptions ])
    const updateSearch = (event) => {
        setSearchValue(event.target.value.substr(0, 20));
    };
    const handleCandidateOptions = (event) => {
        setFilterOptions({
            ...filterOptions,
            [ event.target.name ]: event.target.value,
        });
    };
    const tableData =
    candidateActiveCampaigns &&
    candidateActiveCampaigns.filter((d) => {
        return (
            (d.campaign_name &&
          d.campaign_name.toLowerCase().indexOf(searchValue.toLowerCase()) !==
            -1) ||
        (d.tech_stack &&
          d.tech_stack.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) ||
          (d.company_name &&
            d.company_name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1));
    });
    const sorted = tableData && tableData.sort((a, b) => {
        const isReversed = !sortType ? '' :  (sortType === 'asc') ? 1 : -1
        if(columnName === 'campaign_name'){
            return isReversed*a.campaign_name.toLowerCase().localeCompare(b.campaign_name.toLowerCase())
        }
        else if(columnName === 'company'){
            return isReversed*a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
        }
        else if(columnName === 'tech_stack'){
            return (isReversed*a.tech_stack.toLowerCase().localeCompare(b.tech_stack.toLowerCase()))
        }
        else if(columnName === 'registered'){
            const date1 = new Date(a.created_datetime);
            const date2 = new Date(b.created_datetime);
            return (isReversed*date1.getTime() - isReversed*date2.getTime())
        } else {
            return a.campaign_name.toLowerCase().localeCompare(b.campaign_name.toLowerCase())
        }
    })
    const handleColumnName = (data) => {
        setColumnName(data)
    }
    const onSort = (order, data) => {
        setSortType(order)
        setColumnName(data)
        if(data === 'campaign_name') {
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setCampaignsArrowUp(!campaignsArrowUp)
        }
        if(data === 'company') {
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setCompanyArrowUp(!companyArrowUp)
        }
        if(data === 'tech_stack') {
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setTechArrowUp(!techArrowUp)
        }
        if(data === 'registered') {
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setRegisteredArrowUp(!registeredArrowUp)
        }
    }
    const onSortUp = (order, data) => {
        setSortType(order)
        if(columnName === 'campaign_name' || data === 'campaign_name') {
            setCampaignsArrowUp(!campaignsArrowUp)
            setCampaignsArrowDown(!campaignsArrowDown)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'company' || data === 'company') {
            setCompanyArrowUp(!companyArrowUp)
            setCompanyArrowDown(!companyArrowDown)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'tech_stack' || data === 'tech_stack') {
            setTechArrowUp(!techArrowUp)
            setTechArrowDown(!techArrowDown)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'registered' || data === 'registered') {
            setRegisteredArrowUp(!registeredArrowUp)
            setRegisteredArrowDown(!registeredArrowDown)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
        }
    }
    const onSortDown = (order) => {
        setSortType(order);
        if(columnName === 'campaign_name') {
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        } else if(columnName === 'company') {
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
        } else if(columnName === 'tech_stack'){
            setTechArrowUp(false)
            setTechArrowDown(false)
        } else if(columnName === 'registered'){
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        }
    }
    const toggleHover = (idx) => {
        if (isHover === idx){
            setIsHover('')
        }
        else{
            setIsHover(idx)
        }
    }
    return(
        <>
            <WardClientHeader candName={ candName } type='Clients' updateSearch={ updateSearch }
                searchValue={ searchValue } handleCandidateOptions={ handleCandidateOptions } />
            <table className="main-table table table-condensed github table-borderless">
                <thead>
                    <tr className="table-head">
                        <th scope="col" className="s_no" style={ { width: '1%' } }>#</th>
                        <td style={ { width: '2%' } }></td>
                        <th scope="col" style={ { width: '10%' } }>Company{' '}
                            <Sort
                                data= { 'company' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { companyArrowUp }
                                sortArrowDown ={ companyArrowDown }
                                onSort={ onSort }
                                onSortUp={ onSortUp }
                                onSortDown={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '15%' } }>Active Campaign(s){' '}
                            <Sort
                                data= { 'campaign_name' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { campaignsArrowUp }
                                sortArrowDown ={ campaignsArrowDown }
                                onSort={ onSort }
                                onSortUp={ onSortUp }
                                onSortDown={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '35%' } }>Notes </th>
                        <th scope="col" style={ { width: '10%', textAlign: 'center' } }>Started{' '}
                            <Sort
                                data= { 'registered' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { registeredArrowUp }
                                sortArrowDown ={ registeredArrowDown }
                                onSort={ onSort }
                                onSortUp={ onSortUp }
                                onSortDown={ onSortDown }
                            />
                        </th>
                    </tr>
                </thead>
                {isLoading && (
                    <div className="loader">
                        <Loading />
                    </div>
                )}
                <tbody>
                    {!isLoading && sorted && sorted.map((data, idx)=> {
                        return  <tr className="table-row" key={ idx }>
                            <th scope="row" className="s_no">{ idx + 1 }</th>
                            <td>
                                {data.is_active ? (
                                    <img
                                        src={ Play }
                                        alt="play"
                                        className="play-icon"
                                        onClick={ () => handleStatus(dispatch, data, 2, 0) }
                                        onMouseEnter={ () => toggleHover(data.campaign_id) }
                                    />
                                ) : (
                                    <img
                                        src={ Pause }
                                        alt="pause"
                                        className="pause-icon pause-img"
                                        onClick={ () => handleStatus(dispatch, data, 2, 1) }
                                    />
                                )}
                            </td>
                            <td><Link className="epicdetail" to={ {
                                pathname: '/epic-passport',
                                state: {
                                    clientId: data.client_id,
                                }
                            } }>{data.company_name}</Link></td>
                            <td><Link className="epicdetail" to={ {
                                pathname: '/storage',
                                state: {
                                    campId: parseInt(data.campaign_id),
                                    campaign_name: data.campaign_name
                                }
                            } }>{data.campaign_name}</Link></td>
                            <td>{data.campaign_description}</td>
                            <td style={ { textAlign: 'center' } }>{moment(data.created_datetime).format(ddmmyyFormat) }</td>
                        </tr>
                    }) }
                </tbody>
            </table>
        </>
    )
}
VoteSection.propTypes = {
    history: PropTypes.object,
};

export default VoteSection