import React, { memo } from 'react'

function SideBannerLogo() {
    return (
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="Banner d-none d-md-block d-lg-block d-xl-block">
                <div className="content-background">
                    <div className="banner-content">
                        <h2 className="banner-heading">
                            Suspendisse ut sapien id neque.
                        </h2>
                        <div className="blueDash"></div>
                        <p className="banner-tagline">
                            Pellentesque habitant morbi tristique senectus et netus et
                            malesuada fames ac turpis egestas.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(SideBannerLogo)
