import React from 'react';
import 'static/style/not-found.scss';

const handleBack = () => {
    window.location = '/'
    sessionStorage.removeItem('accessToken');
}

const NotFound = () => (
    <div id="notfound-page">
        <div className="notfound-page">
            <div className="label-404">
                <h2>OOPS!</h2>
            </div>
            <div className="page-label">The Page can not be found.</div>
            <div className="backbtn">
                <button type="back-button" className="btn btn-danger"  onClick={ handleBack }>Go back</button>
            </div>
        </div>
    </div>
);
export default NotFound;