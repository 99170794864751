import React , { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';
import moment from 'moment';
import NotesSection from '../Note';
import HiringProfileModal from '../HiringProfileModal';
import Loading from 'shared/Loader';
import Play from 'static/images/play.svg';
import Pause from 'static/images/pause.svg';
import Trash from 'static/images/trash.svg';
import { FcGenericSortingAsc } from 'react-icons/fc';
import { ddmmyyFormat, stateName } from 'shared/Constant';
import { handleStatus } from 'utils/Helper';
import ClientDeleteModal from './DeleteModal';

function Edit(props) {
    const dispatch = useDispatch();
    const { clientProfileDetails, campArray, handleChange, removeClientFunc, removeClientCampaign, handleCampaignChange, addNewCampaign, inputField } = props
    const isLoading = useSelector(state => state.applicationIsLoading)
    const [ isOpenNote, setIsOpenNote ] = useState(false);
    const [ notesValue, setNotesValues ] = useState('');
    const [ isOpenDeleteModal, setIsOpenDeleteModal ] = useState(false);
    const isSpinnerLoading = useSelector((state) => state.spinnerIsLoading);
    const [ clientId, setClientId ] = useState();
    const [ userType, setuserType ] = useState('');

    const handleOpenNotes = (desc) => {
        setIsOpenNote(!isOpenNote);
        setNotesValues(desc)
        if(!isOpenNote) {
            setTimeout(function() {
                window.scroll({
                    top: document.body.offsetHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 300);
        }
    }
    const handleEditNotes = (event) => {
        setNotesValues(event.target.value);
    };
    const handleUpdateNotes = (data) => {
        setNotesValues(data)
        setIsOpenNote(!isOpenNote);
    }
    const handleDeleteModal = (id, type) => {
        setIsOpenDeleteModal(!isOpenDeleteModal)
        setClientId(id)
        setuserType(type)
    }
    const state =
     stateName.map((data, id) => {
         return (
             <option
                 key={ id }
                 value={ data }
                 name='state'
             >
                 {data}
             </option>
         );
     });
    return (
        <>
            <div className="table-reponsive">
                <table className="table table-condensed github table-borderless">
                    <thead className="passportapply">
                        <tr>
                            <td colSpan="12" className="edit-section">
                                <div className="emergency">
                                    <div className="epic-extendedrev row mt-3">
                                        <div className="col-lg-2 col-md-2 text-center mt-3 photo-content">
                                            <Avatar
                                                size="100"
                                                square={ true }
                                                src={ inputField.avatar }
                                                className="edit-avatar"
                                            />
                                            { isSpinnerLoading && (
                                                <div className="spinner-grow spinner-grow-sm" role="status" style={ { position: 'absolute', margin: '48px 0px 0px -58px' } }>
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            )}
                                            <input
                                                id="avatar"
                                                className="edit-input upload-picture"
                                                name="avatar"
                                                type="file"
                                                accept="image/*"
                                                onChange={ (event) => handleChange(event, clientProfileDetails[ 0 ]) }
                                            />
                                            <br />
                                            <div className="clien mt-2">
                                                <u onClick ={ () => handleDeleteModal(clientProfileDetails[ 0 ].id, 'client') } className="delete">Delete Client </u>
                                            </div>
                                        </div>
                                        <div className="linkedin col-lg-4 col-md-6">
                                            <form>
                                                <div className="form-group row">
                                                    <label
                                                        htmlFor="inputPassword"
                                                        className="col-form-label edit-label"
                                                    >
                                                        <b>Company name</b>
                                                    </label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="text"
                                                            className="form-control custodian"
                                                            id="company_name"
                                                            name='company_name'
                                                            defaultValue={ clientProfileDetails[ 0 ].company_name }
                                                            onChange={ handleChange }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group mt-1 row">
                                                    <label
                                                        htmlFor="inputPassword"
                                                        className="col-form-label edit-label"
                                                    >
                                                        <b>Location</b>
                                                    </label>
                                                    <div className="col-sm-6 position-relative d-flex">
                                                        <input
                                                            type="text"
                                                            className="form-control custodian"
                                                            id='city'
                                                            name='city'
                                                            defaultValue={ clientProfileDetails[ 0 ].city }
                                                            onChange={ handleChange }
                                                        />
                                                        <div className="position-absolute custom">
                                                            <select className="custom-select-loca" id="state" name='state' onChange ={ handleChange }>
                                                                <option defaultValue = { clientProfileDetails[ 0 ].state }>{ clientProfileDetails[ 0 ].state }</option>
                                                                {state}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row align-items-center">
                                                    <label
                                                        htmlFor="inputPassword"
                                                        className="col-form-label edit-label"
                                                    >
                                                        <b>Email</b>
                                                    </label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="email"
                                                            readOnly
                                                            className="form-control-plaintext custodian"
                                                            id="email_id"
                                                            name="email_id"
                                                            defaultValue={ clientProfileDetails[ 0 ].email_id }
                                                            onChange={ handleChange }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row mt-1">
                                                    <label
                                                        htmlFor="inputPassword"
                                                        className="col-form-label edit-label"
                                                    >
                                                        <b>Phone</b>
                                                    </label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="number"
                                                            className="form-control custodian"
                                                            id="phone_number"
                                                            name="phone_number"
                                                            defaultValue=
                                                                { !(clientProfileDetails[ 0 ].phone_number)? ( <span>-</span>):((clientProfileDetails[ 0 ].phone_number)) }
                                                            onChange={ handleChange }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row mt-1">
                                                    <label
                                                        htmlFor="inputPassword"
                                                        className="col-form-label edit-label"
                                                    >
                                                        <b>URL</b>
                                                    </label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="text"
                                                            className="form-control custodian"
                                                            id="company_url"
                                                            name="company_url"
                                                            defaultValue={ clientProfileDetails[ 0 ].company_url }
                                                            onChange={ handleChange }
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="linkedin portal col-lg-4 col-md-6">
                                            <form>
                                                <div className="form-group row">
                                                    <label
                                                        htmlFor="inputPassword"
                                                        className="col-form-label edit-label"
                                                    >
                                                        <b>First Name</b>
                                                    </label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="text"
                                                            className="form-control custodian"
                                                            id="first_name"
                                                            name="first_name"
                                                            defaultValue={ clientProfileDetails[ 0 ].first_name }
                                                            onChange={ handleChange }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row mt-1">
                                                    <label
                                                        htmlFor="inputPassword"
                                                        className="col-form-label edit-label"
                                                    >
                                                        <b>Last Name</b>
                                                    </label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="text"
                                                            className="form-control custodian"
                                                            id="last_name"
                                                            name="last_name"
                                                            defaultValue={ clientProfileDetails[ 0 ].last_name }
                                                            onChange={ handleChange }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row mt-1">
                                                    <label
                                                        htmlFor="inputPassword"
                                                        className="col-form-label edit-label"
                                                    >
                                                        <b>Job Title</b>
                                                    </label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="text"
                                                            className="form-control custodian"
                                                            id="job_title"
                                                            name="job_title"
                                                            defaultValue={ clientProfileDetails[ 0 ].job_title }
                                                            onChange={ handleChange }
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-lg-2 col-md-5 mt-5 text-center">
                                            <button
                                                type="button"
                                                className="hiring text-center"
                                                disabled
                                            >
                                                Hiring Profile
                                            </button>
                                            <HiringProfileModal />
                                            <button
                                                className="epic-btn text-center"
                                                type="button"
                                                name="button"
                                                onClick={ addNewCampaign }
                                            >
                                                New Campaign
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr style={ { background: '#ffffff' } } className="table-head">
                            <th scope="col" className="s_no">#</th>
                            <th></th>
                            <th scope="col">
                                Campaign Name <FcGenericSortingAsc />
                            </th>
                            <th scope="col">Description </th>
                            <th scope="col">
                                Resources <FcGenericSortingAsc />
                            </th>
                            <th scope="col">
                                Started <FcGenericSortingAsc />
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    {isLoading && (
                        <div className="loader">
                            <Loading />
                        </div>
                    )}
                    <tbody>
                        {campArray && campArray.map((camp, idx)=>{
                            return <tr
                                key ={ idx }
                                data-toggle="collapse"
                                data-target="#demo2"
                                className="accordion-toggle table-row"
                            >
                                <th scope="row" className="s_no">{ idx+ 1 }</th>
                                <td>
                                    {camp.is_active ? (
                                        <img
                                            src={ Play }
                                            alt="play"
                                            className="play-icon"
                                            onClick={ () => handleStatus(dispatch, camp, 2, 0) }
                                        />
                                    ) : (
                                        <img
                                            src={ Pause }
                                            alt="pause"
                                            className="pause-icon pause-img"
                                            onClick={ () => handleStatus(dispatch, camp, 2, 1) }
                                        />
                                    )}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className=" bxmodel"
                                        id="campaign_name"
                                        name="campaign_name"
                                        autoComplete='off'
                                        defaultValue={ camp.campaign_name }
                                        onChange={ (e) => handleCampaignChange(e, idx) }
                                    />
                                </td>
                                <td onClick = { () =>  handleOpenNotes(camp.campaign_description) }> {camp.campaign_description }</td>
                                <td>
                                    <span className="tabzz">
                                        {camp.total_hired_candidate ? camp.total_hired_candidate : 0} Hire
                                    </span>
                                </td>
                                <td>{moment(camp.created_datetime).format(ddmmyyFormat) }</td>
                                <td>
                                    <span className='pointer'>
                                        <img src={ Trash } alt="trash" onClick = { () => handleDeleteModal(camp, 'campaign') } />
                                    </span>
                                </td>
                            </tr>
                        }) }
                    </tbody>
                </table>
                {isOpenNote && (
                    <NotesSection
                        handleCloseNotes={ handleOpenNotes }
                        handleEditNotes={ handleEditNotes }
                        notesValue={ notesValue }
                        handleCandidatesNotes={ handleUpdateNotes }
                    />
                )}
                {isOpenDeleteModal && (
                    <ClientDeleteModal
                        isOpenDeleteModal={ isOpenDeleteModal }
                        removeClientFunc={ removeClientFunc }
                        clientId={ clientId }
                        handleDeleteModal={ handleDeleteModal }
                        userType={ userType }
                        removeClientCampaign={ removeClientCampaign }
                    />
                )}
            </div>
        </>
    );
}

Edit.propTypes = {
    history: PropTypes.object,
    handleChange: PropTypes.func,
    removeClientFunc: PropTypes.func,
    removeClientCampaign: PropTypes.func,
    updateCampaignDetails: PropTypes.func,
    handleCampaignChange: PropTypes.func,
    clientProfileDetails: PropTypes.array,
    campArray: PropTypes.array,
    updateNotes: PropTypes.func,
    addNewCampaign: PropTypes.func,
    inputField: PropTypes.object
};

export default Edit;
