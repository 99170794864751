import React from 'react'
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';

function AvatarComp(props) {
    const  { name, image } = props
    return (
        <Avatar size="100" square={ true } name={ name } src={ image } />
    )
}
AvatarComp.propTypes = {
    name: PropTypes.string,
    image: PropTypes.string,
};

export default AvatarComp
