/* eslint-disable react/prop-types */
/* eslint-disable quotes */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { getCampaignCandidates } from 'actions/clientActions';
import { cityCountryFormatter, fullNameFormatter } from 'shared/Constant';
import UpVoteCampaign from './UpVoteCampaign';
import CandidatesList from './CandidatesList';

function CampaignCandidates(props) {
    const dispatch = useDispatch();
    const active_tab = localStorage.getItem('activeTab');
    const { campId , handleOpenNotes, campaignCandidates, isEdit, educationArray, handleInputChange, handleAddInput, handleRemoveInput, handleHobbiesChange, handleSkillsChange, handleCandidateStatus, removeCandidateFunc, uploadProfilePicture, handleChange, inputField, handleToggleTable, toggleArrowIndex, handleDeleteModal, uploadResumeHandler  } = props;
    const [ activeTab, setActiveTab ] = useState(active_tab === null ? "2" : active_tab);
    useEffect(() => {
        if(active_tab === null || active_tab === "2"){
            dispatch(getCampaignCandidates(campId, 0));
        } else {
            dispatch(getCampaignCandidates(campId, 1));
        }
    }, [ dispatch, campId, active_tab ]);
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
        if (tab === "1") {
            dispatch(getCampaignCandidates(campId, 1));
            localStorage.setItem('activeTab', "1");
        } else {
            dispatch(getCampaignCandidates(campId, 0));
            localStorage.setItem('activeTab', "2");
        }
    };
    return (
        <>
            <div>
                <Nav tabs className="storage-tabs justify-content-center">
                    <NavItem>
                        <NavLink
                            className={ classnames({ active: activeTab === "1" }) }
                            onClick={ () => {
                                toggle("1");
                            } }
                        >
                            VOTED UP
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={ classnames({ active: activeTab === "2" }) }
                            storage-vote
                            onClick={ () => {
                                toggle("2");
                            } }
                        >
                            CANDIDATES
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
            <TabContent activeTab={ activeTab }>
                <TabPane tabId="1">
                    <UpVoteCampaign
                        campaignCandidates={ campaignCandidates }
                        cityCountryFormatter={ cityCountryFormatter }
                        fullNameFormatter={ fullNameFormatter }
                        dispatch={ dispatch }
                        campId={ campId }
                        isEdit={ isEdit }
                        handleOpenNotes={ handleOpenNotes }
                        educationArray={ educationArray }
                        handleInputChange= { handleInputChange }
                        handleAddInput= { handleAddInput }
                        handleRemoveInput = { handleRemoveInput }
                        handleSkillsChange = { handleSkillsChange }
                        handleHobbiesChange = { handleHobbiesChange }
                        handleCandidateStatus = { handleCandidateStatus }
                        removeCandidateFunc={ removeCandidateFunc }
                        uploadProfilePicture={ uploadProfilePicture }
                        InputChange={ handleChange }
                        inputField={ inputField }
                        handleToggleTable={ handleToggleTable }
                        toggleArrowIndex={ toggleArrowIndex }
                        handleDeleteModal={ handleDeleteModal }
                        uploadResumeHandler={ uploadResumeHandler }
                    />
                </TabPane>
                <TabPane tabId="2">
                    <CandidatesList
                        campaignCandidates={ campaignCandidates }
                        cityCountryFormatter={ cityCountryFormatter }
                        fullNameFormatter={ fullNameFormatter }
                        dispatch={ dispatch }
                        campId={ campId }
                        isEdit={ isEdit }
                        handleOpenNotes={ handleOpenNotes }
                        educationArray={ educationArray }
                        handleInputChange= { handleInputChange }
                        handleAddInput= { handleAddInput }
                        handleRemoveInput = { handleRemoveInput }
                        handleSkillsChange = { handleSkillsChange }
                        handleHobbiesChange = { handleHobbiesChange }
                        handleCandidateStatus = { handleCandidateStatus }
                        removeCandidateFunc={ removeCandidateFunc }
                        uploadProfilePicture={ uploadProfilePicture }
                        InputChange={ handleChange }
                        inputField={ inputField }
                        handleToggleTable={ handleToggleTable }
                        toggleArrowIndex={ toggleArrowIndex }
                        handleDeleteModal={ handleDeleteModal }
                        uploadResumeHandler={ uploadResumeHandler }
                    />
                </TabPane>
            </TabContent>
        </>
    );
}
CampaignCandidates.propTypes = {
    CampaignCandidates: PropTypes.array,
    history: PropTypes.object,
    handleOpenNotes: PropTypes.func,
    handleEditNotes: PropTypes.func,
    campId: PropTypes.number,
    isOpenNote: PropTypes.bool,
    isEdit: PropTypes.bool,
    educationArray: PropTypes.array,
    handleInputChange: PropTypes.func,
    handleAddInput: PropTypes.func,
    handleRemoveInput: PropTypes.func,
    handleSkillsChange: PropTypes.func,
    handleHobbiesChange: PropTypes.func,
    handleCandidateStatus: PropTypes.func,
    removeCandidateFunc: PropTypes.func,
    uploadProfilePicture: PropTypes.func,
    handleChange: PropTypes.func,
    inputField: PropTypes.array,
    handleToggleTable: PropTypes.array,
    toggleArrowIndex: PropTypes.string,
    handleDeleteModal: PropTypes.func,
    uploadResumeHandler: PropTypes.func
};
export default CampaignCandidates;
