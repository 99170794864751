import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import BackArrow from 'static/images/back.svg';
import PenImage from 'static/images/pen.svg';
import Apply from 'static/images/apply.svg';
import { ReactSVG } from 'react-svg';

function EpicPassportHeader(props) {
    const { handleClientEdit, clientProfileDetails, updateNotes, isEdit } = props;
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dashboard">
                <div className="container-fluid">
                    <Link className="navbar-brand" to=""></Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 listclient">
                            <Link to="/clients">
                                <li className="nav-item back-arrow-box">
                                    <ReactSVG src={ BackArrow } className="back-arrow" />
                                </li>
                            </Link>
                            <li className="nav-item bullet">
                                <Link to="/clients" className="nav-link back-heading">
                                    CLIENTS LIST
                                </Link>
                            </li>
                        </ul>
                        <div className="port">
                            {clientProfileDetails &&
                clientProfileDetails[ 0 ] &&
                clientProfileDetails[ 0 ].company_name}
                        </div>
                        <form className="d-flex flex-wrap align-items-center ">
                            <div className="edit-btn-section pb-2">
                                {!isEdit ? (
                                    <button
                                        className="fill applicant pl-2 edit"
                                        type="submit"
                                        onClick={ (event) =>
                                            handleClientEdit(event, clientProfileDetails[ 0 ])
                                        }
                                    >
                                        <img src={ PenImage } alt="pen" />
                                        &nbsp;Edit
                                    </button>
                                ) : (
                                    <Link to="/epic-passport">
                                        <button
                                            className="fill applicant pl-2"
                                            type="submit"
                                            onClick={ (event) => updateNotes(event) }
                                        >
                                            <img src={ Apply } alt="apply" />
                                            &nbsp;&nbsp;Apply
                                        </button>
                                    </Link>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </nav>
        </>
    );
}
EpicPassportHeader.propTypes = {
    handleClientEdit: PropTypes.func,
    updateNotes: PropTypes.func,
    clientProfileDetails: PropTypes.array,
    isEdit: PropTypes.bool,
};

export default EpicPassportHeader;
