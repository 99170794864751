import Loader from 'react-loader-spinner';
import React from 'react';
import 'static/style/dashboard.scss';
function Loading() {
    return (
        <div>
            <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={ 100 }
                width={ 100 }
                timeout={ 3000 }
            />
        </div>
    )
}

export default Loading;
