import React, { useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'static/style/auth.scss';
import { loginUser, sendForgotPassword, sendResetUserPassword, sendVerifyToken, verifyOtp } from 'actions/authActions';
import SideBannerLogo from './SideBannerLogo';
import LogoSection from './LogoSection';
import Button from 'shared/Button';
import { forgotValidator, loginValidator, resetPasswordValidator, verifyValidator } from 'utils/Validater';
import ForgotPassword from './ForgotPassword';
import VerificationCode from './VerificationCode';
import ResetPassword from './ResetPassword';

function LoginPage(props) {
    const dispatch = useDispatch();
    const [ inputFieldError, setInputFieldError ] = useState({});
    const [ isForgotPassword , setIsForgotPassword ] = useState(false);
    const [ isVerifyCode , setIsVerifyCode ] = useState(false);
    const [ isResetScreen , setIsResetScreen ] = useState(false);
    const [ isAuth , setIsAuth ] = useState(false);
    const phoneNumber = useSelector(state => state.phoneNumber)
    const [ inputField, setInputField ] = useState({});
    const handleChange = (e) => {
        setInputField({ ...inputField, [ e.target.name ]: e.target.value });
        setInputFieldError({});
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            email_id: inputField.email,
            password: inputField.password,
            user_role: '0',
        };
        if (loginValidator(inputField, setInputFieldError)) {
            dispatch(loginUser(data)).then((res)=> {
                if (res.statusCode === 200 ){
                    setIsVerifyCode(!isVerifyCode)
                    setIsAuth(!isAuth)
                }
            })
        }
    };
    const handleForgotPassword = () => {
        setIsForgotPassword(!isForgotPassword)
    }
    const handleForgotContinue = (event) => {
        event.preventDefault()
        const data = {
            phone_number: inputField.phone_number
        }
        if (forgotValidator(inputField, setInputFieldError)) {
            dispatch(sendForgotPassword(data));
            setIsVerifyCode(!isVerifyCode)
            setIsForgotPassword(!isForgotPassword)
        }
    }
    const handleResetScreen = (event) => {
        event.preventDefault()
        const data = {
            reset_password_otp: inputField.reset_password_otp,
            phone_number: inputField.phone_number
        }
        const newData = {
            verification_otp:  inputField.reset_password_otp,
            phone_number: phoneNumber,
            device_token: '',
            device_type: '',
            device_model: '',
            os_version: '',
        }
        if (isAuth){
            if (verifyValidator(inputField, setInputFieldError)) {
                dispatch(verifyOtp(newData, props.history));
            }
        }
        else {
            if (verifyValidator(inputField, setInputFieldError)) {
                dispatch(sendVerifyToken(data));
                setIsResetScreen(!isResetScreen)
                setIsVerifyCode(false)
                setIsForgotPassword(false)
            }
        }
    }
    const handleUpdatePassword = (event) => {
        event.preventDefault()
        const data = {
            phone_number: inputField.phone_number,
            password: inputField.confirm_password
        }
        if (resetPasswordValidator(inputField, setInputFieldError)) {
            dispatch(sendResetUserPassword(data))
            setIsResetScreen(false)
            setIsVerifyCode(false)
            setIsForgotPassword(false)
        }
    }
    return (
        <div className="Login-page">
            <div className="row no-gutters main-container">
                {!isForgotPassword && !isVerifyCode && !isResetScreen && <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="row no-gutters d-flex flex-column card">
                        <div className="card-body">
                            <LogoSection isLogin = { true } title = 'Please login to proceed ' />
                            <form className="login-form">
                                <div className="form-group field_cont">
                                    <label htmlFor="email" className="form-label">
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        autoComplete="off"
                                        placeholder="Enter your email"
                                        className="form-control"
                                        onChange={ handleChange }
                                        value={ inputField.email || '' }
                                    />
                                    {inputFieldError.email && (
                                        <span className="error_msg">{inputFieldError.email}</span>
                                    )}
                                </div>
                                <div className="form-group field_cont mb-0">
                                    <label htmlFor="password" className="form-label">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="Enter your password"
                                        className="form-control"
                                        onChange={ handleChange }
                                        value={ inputField.password || '' }
                                    />
                                    {inputFieldError.password && (
                                        <span className="error_msg">
                                            {inputFieldError.password}
                                        </span>
                                    )}
                                </div>
                                <a className="forgot-password" href onClick={ handleForgotPassword }> Forgot password? </a>
                                <Button
                                    handleSubmit={ handleSubmit }
                                    buttonName="SIGN IN TO YOUR ACCOUNT"
                                />
                            </form>
                        </div>
                    </div>
                </div>}
                {isForgotPassword && <ForgotPassword handleChange={ handleChange } inputField ={ inputField } inputFieldError ={ inputFieldError } handleSubmit={ handleForgotContinue } /> }
                {isVerifyCode && <VerificationCode handleChange = { handleChange } inputField ={ inputField } inputFieldError ={ inputFieldError } handleSubmit={ handleResetScreen } isAuth = { isAuth }/> }
                {isResetScreen && <ResetPassword   handleChange = { handleChange } inputField ={ inputField } inputFieldError ={ inputFieldError } handleSubmit={ handleUpdatePassword }/> }
                <SideBannerLogo />
            </div>
        </div>
    );
}
LoginPage.propTypes = {
    history: PropTypes.object,
};

export default memo(LoginPage);
