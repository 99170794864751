import React from 'react';
import PropTypes from 'prop-types';
import { FcGenericSortingAsc } from 'react-icons/fc';
import sortUpArrow from 'static/images/up-arrow.svg';

function Sort(props) {
    const { onSort, onSortDown, onSortUp, sortArrowUp, sortArrowDown, handleColumnName, data } = props;
    return (
        <span>
            {!sortArrowUp && !sortArrowDown && (
                <FcGenericSortingAsc
                    onClick={ () => { onSort('desc', data); handleColumnName(data) } }
                    className="sort-icon"
                />
            )}
            {sortArrowUp && (
                <img
                    src={ sortUpArrow }
                    alt="asc"
                    style={ { cursor: 'pointer' } }
                    onClick={ () => { onSortUp('asc', data); handleColumnName(data) } }
                />
            )}
            {sortArrowDown && !sortArrowUp && (
                <img
                    src={ sortUpArrow }
                    alt="sorter"
                    onClick={ () => { onSortDown(''); handleColumnName(data) } }
                    className="sort-down-icon"
                    style={ { cursor: 'pointer' } }
                />
            )}
        </span>
    );
}
Sort.propTypes = {
    sortArrowUp: PropTypes.bool,
    sortArrowDown: PropTypes.bool,
    onSort: PropTypes.func,
    onSortUp: PropTypes.func,
    onSortDown: PropTypes.func,
    handleColumnName: PropTypes.func,
    data: PropTypes.string
};
export default Sort;
