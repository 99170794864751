import moment from 'moment';
export const loginValidator = (inputField, setInputFieldError) => {
    const errors = {};
    let isValid = true;
    if (!inputField.email) {
        isValid = false;
        errors.email = 'Please enter email';
    }
    if (typeof inputField.email !== 'undefined') {
        const pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(inputField.email)) {
            isValid = false;
            errors.email = 'Please enter valid email address';
        }
    }
    if (!inputField.password) {
        isValid = false;
        errors.password = 'Please enter password';
    }
    setInputFieldError(errors)
    return isValid
}
export const forgotValidator = (inputField, setInputFieldError) => {
    const errors = {};
    let isValid = true;
    if (!inputField.phone_number) {
        isValid = false;
        errors.phone_number = 'Please enter phone number';
    }
    setInputFieldError(errors)
    return isValid
}

export const verifyValidator = (inputField, setInputFieldError) => {
    const errors = {};
    let isValid = true;
    if (!inputField.reset_password_otp) {
        isValid = false;
        errors.reset_password_otp = 'Please enter verification code';
    }
    setInputFieldError(errors)
    return isValid
}

export const resetPasswordValidator = (inputField, setInputFieldError) => {
    const errors = {};
    let isValid = true;
    if (!inputField.confirm_password) {
        isValid = false;
        errors.confirm_password = 'Please enter password';
    }
    setInputFieldError(errors)
    return isValid
}

export const addInterviewValidator = (inputField, setInputFieldError) => {
    const errors = {};
    let isValid = true;
    if (!inputField.candidate_id) {
        isValid = false;
        errors.candidate_id = 'Please enter candidate';
    }
    if (!inputField.interview_date) {
        isValid = false;
        errors.interview_date = 'Please enter interview date';
    }
    if (!inputField.interview_link) {
        isValid = false;
        errors.interview_link = 'Please enter interview link';
    }
    if (!inputField.interview_time) {
        isValid = false;
        errors.interview_time = 'Please enter interview time';
    }
    if (!inputField.interview_description) {
        isValid = false;
        errors.interview_description = 'Please enter interview description';
    }
    if (inputField.interview_time && inputField.interview_date) {
        if(moment(inputField.interview_date + ' ' + inputField.interview_time).diff(moment(),'s') < 0){
            isValid = false;
            errors.interview_date = 'Date must be in the future';
        }

    }
    setInputFieldError(errors)
    return isValid
}

export const addProjectValidator = (inputField, setInputFieldError) => {
    const errors = {};
    let isValid = true;
    if (!inputField.project_name) {
        isValid = false;
        errors.project_name = 'Please enter project_name';
    }
    if (!inputField.project_start_date) {
        isValid = false;
        errors.project_start_date = 'Please enter project start date';
    }
    if (!inputField.project_end_date) {
        isValid = false;
        errors.project_end_date = 'Please enter project end date';
    }
    if (!inputField.project_description) {
        isValid = false;
        errors.project_description = 'Please enter project description';
    }

    // if(moment(inputField.project_start_date).diff(moment(),'d') < -1){
    //     isValid = false;
    //     errors.project_start_date = 'Date must be in the future';
    // }

    if(moment(inputField.project_end_date).diff(moment(inputField.project_start_date),'d') < 0){
        isValid = false;
        errors.project_end_date = 'Date must be in the future';
    }

    setInputFieldError(errors)
    return isValid
}

export const addMilestoneValidator = (inputField, setInputFieldError) => {
    const errors = {};
    let isValid = true;
    if (!inputField.milestone_name) {
        isValid = false;
        errors.milestone_name = 'Please enter milestone_name';
    }
    if (!inputField.start_date) {
        isValid = false;
        errors.start_date = 'Please enter milestone start date';
    }
    if (!inputField.end_date) {
        isValid = false;
        errors.end_date = 'Please enter milestone end date';
    }
    if (!inputField.milestone_description) {
        isValid = false;
        errors.milestone_description = 'Please enter milestone description';
    }

    // if(moment(inputField.start_date).diff(moment(),'d') < -1){
    //     isValid = false;
    //     errors.start_date = 'Date must be in the future';
    // }

    if(moment(inputField.end_date).diff(moment(inputField.start_date),'s') < 0){
        isValid = false;
        errors.end_date = 'Date must be in the future';
    }

    setInputFieldError(errors)
    return isValid
}
export const addTaskValidator = (inputField, setInputFieldError) => {
    const errors = {};
    let isValid = true;
    if (!inputField.task_name) {
        isValid = false;
        errors.task_name = 'Please enter task_name';
    }
    if (!inputField.start_date) {
        isValid = false;
        errors.start_date = 'Please enter task start date';
    }
    if (!inputField.end_date) {
        isValid = false;
        errors.end_date = 'Please enter task end date';
    }
    if (!inputField.task_description) {
        isValid = false;
        errors.task_description = 'Please enter task description';
    }

    // if(moment(inputField.start_date).diff(moment(),'d') < 0){
    //     isValid = false;
    //     errors.start_date = 'Date must be in the future';
    // }

    if(moment(inputField.end_date).diff(moment(inputField.start_date),'s') < 0){
        isValid = false;
        errors.end_date = 'Date must be in the future';
    }

    setInputFieldError(errors)
    return isValid
}