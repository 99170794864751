import axios from 'axios';
import { encryptFunc, getAccessToken } from 'utils/Helper';
import  { REACT_APP_BASE_URL }  from '../config';
import { applicationIsLoading, spinnerIsLoading } from './commonActions';
import { decryptFunc } from '../utils/Helper'
import { triggerNotifier } from 'shared/Notifier';

export function fetchClientsListing(clientsListing) {
    return {
        type: 'FETCH_CLIENTS_LISTING',
        clientsListing,
    };
}
export function fetchClientProfile(clientProfile) {
    return {
        type: 'FETCH_CLIENT_PROFILE',
        clientProfile,
    };
}
export function fetchHiresCandidates(hireCandidates) {
    return {
        type: 'FETCH_HIRES_CANDIDATES',
        hireCandidates,
    };
}
export function fetchActiveHireCandidates(hireActiveCandidates) {
    return {
        type: 'FETCH_ACTIVE_HIRE_CANDIDATES',
        hireActiveCandidates,
    };
}
export function fetchCampaignCandidates(campaignCandidates) {
    return {
        type: 'FETCH_CAMPAIGN_CANDIDATES',
        campaignCandidates,
    };
}
export function fetchCandidateActiveCampaigns(candidateActiveCampaigns) {
    return {
        type: 'FETCH_CANDIDATES_ACTIVE_CAMPAIGNS',
        candidateActiveCampaigns,
    };
}

export function fetchProfileImage(profileImage) {
    return {
        type: 'FECTH_PROFILE_IMAGE',
        profileImage
    }
}

export function storeClientStatus(isDecline) {
    return {
        type: 'STORE_CLIENT_STATUS',
        isDecline,
    };
}
export function fetchClientProjects(clientProfile) {
    return {
        type: 'FETCH_CLIENT_PROJECTS',
        clientProfile,
    };
}
export function getClientsListing(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + `admin/api/getClient?is_pending=0&city=${ (data && data.city === 'All' ? '' : data && data.city ) || '' }&state=${ (data &&  data.state === 'All' ? '' : data &&  data.state) || ' ' }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false));
                dispatch(fetchClientsListing(res.data.clients_list));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error);
                return error;
            });
    };
}

export function getClientProfile(id) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + `admin/api/getClientProfile?client_id=${ id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false));
                dispatch(fetchClientProfile(res.data));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error);
                return error;
            });
    };
}
export function getHiresCandidates(id, data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + `admin/api/getHiresCandidates?client_id=${ id }&tech_stack=${ (data && data.tech_stack  === 'All' ? '' : data && data.tech_stack) || '' }&city=${ (data && data.city === 'All' ? '' : data && data.city ) || '' }&state=${ (data &&  data.state === 'All' ? '' : data &&  data.state) || ' '  }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false));
                dispatch(fetchHiresCandidates(res.data.hires_candidates));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error);
                return error;
            });
    };
}
export function getActiveHireCandidates(id, data) {
    return (dispatch) => {
        const size = data ? Object.values(data).every( (val, i, arr) => val === arr[ 0 ] ) : ''
        const dynamicUrl = size ? `admin/api/getActiveHireCandidates?campaign_id=${ id }` :  `admin/api/getActiveHireCandidates?campaign_id=${ id }&tech_stack=${ (data && data.tech_stack  === 'All' ? '' : data && data.tech_stack) || '' }&city=${ (data && data.city === 'All' ? '' : data && data.city ) || '' }&state=${ (data &&  data.state === 'All' ? '' : data &&  data.state) || ' '  }`
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + dynamicUrl,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(fetchActiveHireCandidates(res.data.active_hires_candidates));
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error);
                return error;
            });
    };
}

export function getCampaignCandidates(campId, voteUp, data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + `admin/api/getCampaignCandidates?campaign_id=${ parseInt(campId) }&is_voted_up=${ voteUp }&tech_stack=${ (data && data.tech_stack  === 'All' ? '' : data && data.tech_stack) || '' }&city=${ (data && data.city === 'All' ? '' : data && data.city ) || '' }&state=${ (data &&  data.state === 'All' ? '' : data &&  data.state) || ' '  }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false));
                dispatch(fetchCampaignCandidates(res.data));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error);
                return error;
            });
    };
}
export function getCandidateActiveCampaigns(candId, data) {
    return (dispatch) => {
        const size = Object.values(data).every( (val, i, arr) => val === arr[ 0 ] )
        const dynamicUrl = size ? `admin/api/getCandidateActiveCampaigns?candidate_id=${ candId }` :  `admin/api/getCandidateActiveCampaigns?candidate_id=${ candId }&tech_stack=${ (data && data.tech_stack  === 'All' ? '' : data && data.tech_stack) || '' }&city=${ (data && data.city === 'All' ? '' : data && data.city ) || '' }&state=${ (data &&  data.state === 'All' ? '' : data &&  data.state) || ' '  }`
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + dynamicUrl,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false));
                dispatch(fetchCandidateActiveCampaigns(res.data.candidate_active_campaigns));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error);
                return error;
            });
    };
}

export function deleteClient(id) {
    return dispatch => {
        return axios({
            method: 'delete',
            url: REACT_APP_BASE_URL + `admin/api/removeClient/${ id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getClientsListing())
                return res;
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }
}
export function updateClientCampaign(id,data) {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + `admin/api/editClientCampaign/${ id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getClientsListing())
                dispatch(getClientProfile(data.client_id))
                return res;
            })
            .catch((error) => {
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}

export function uploadImage(role, data, allData) {
    return (dispatch) => {
        dispatch(spinnerIsLoading(true));
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + 'user/upload/file/'+role,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-Type': 'multipart/form-data',
            },
            data: data,
        })
            .then((response) => {
                const res = response.data
                dispatch(spinnerIsLoading(false));
                dispatch(fetchProfileImage(res.data.file_name.split('/')[ 1 ]))
                return res;
            })
            .catch((error) => {
                dispatch(spinnerIsLoading(false));
                console.log(error);
                return error;
            });
    };
}

export function updateClientNotes(id,data) {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + `admin/api/updateClientNotes/${ id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getClientsListing())
                dispatch(getClientProfile(id))
                return res;
            })
            .catch((error) => {
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                console.log(error);
                return error;
            });
    };
}

export function addInterview(data, history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'admin/api/addClientInterview',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function updateInterview(data, history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'PUT',
            url: REACT_APP_BASE_URL + 'admin/api/editClientInterview',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function deleteInterview( interview_id , history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'DELETE',
            url: REACT_APP_BASE_URL + `admin/api/removeClientInterview?client_interview_id=${ interview_id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            }
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function getClientInterviews( client_id , history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'GET',
            url: REACT_APP_BASE_URL + `admin/api/getClientInterviews?client_id=${ client_id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            }
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}

export function addProject(data, history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'admin/api/addProject',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function updateProject(project_id, data, history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'PUT',
            url: REACT_APP_BASE_URL + 'admin/api/updateProject/'+project_id,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function deleteProject( project_id , history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'DELETE',
            url: REACT_APP_BASE_URL + `admin/api/removeProject/${ project_id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            }
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function getProjects( client_id , history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'GET',
            url: REACT_APP_BASE_URL + `admin/api/getProjectList?client_id=${ client_id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            }
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}

export function addMilestone(data, history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'admin/api/addMilestone',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function updateMilestone(id,data, history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'PUT',
            url: REACT_APP_BASE_URL + 'admin/api/updateMilestone/'+id,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function deleteMilestone( project_id , history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'DELETE',
            url: REACT_APP_BASE_URL + `admin/api/removeMilestone/${ project_id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            }
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function getMilestones( project_id , history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'GET',
            url: REACT_APP_BASE_URL + `admin/api/getMilestoneList?project_id=${ project_id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            }
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function addTask(data, history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'admin/api/addTask',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function updateTask(id, data, history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'PUT',
            url: REACT_APP_BASE_URL + 'admin/api/updateTask/'+id,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function deleteTask( project_id , history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'DELETE',
            url: REACT_APP_BASE_URL + `admin/api/removeTask/${ project_id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            }
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function getTasks( milestone_id , history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'GET',
            url: REACT_APP_BASE_URL + `admin/api/getTaskList?milestone_id=${ milestone_id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            }
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}

export function addTaskCandidate(data, history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'admin/api/addTaskCandidate',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function updateTaskCandidate(id, data, history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'PUT',
            url: REACT_APP_BASE_URL + 'admin/api/updateTaskCandidate/'+id,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function deleteTaskCandidate( project_id , history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'DELETE',
            url: REACT_APP_BASE_URL + `admin/api/removeTaskCandidate/${ project_id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            }
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function getTaskCandidates( task_id , history) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'GET',
            url: REACT_APP_BASE_URL + `admin/api/getTaskCandidateList?task_id=${ task_id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            }
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}