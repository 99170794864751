export function applicationIsLoading(state = false, action) {
    switch (action.type) {
    case 'APPLICATION_IS_LOADING':
        return action.isLoading;
    default:
        return state;
    }
}

export function spinnerIsLoading(state = false, action) {
    switch (action.type) {
    case 'SPINNER_IS_LOADING':
        return action.isLoading;
    default:
        return state;
    }
}