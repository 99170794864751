/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-spacing */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Sort from 'shared/Sorting';
import sideArrow from 'static/images/left.svg';
import minusBox from 'static/images/rectangle.svg';
import { AiOutlineFileSearch } from 'react-icons/ai';
import Play from 'static/images/play.svg';
import Pause from 'static/images/pause.svg';
import { cityCountryFormatter, ddmmyyFormat } from 'shared/Constant';
import { handleStatus } from 'utils/Helper';
import Loading from 'shared/Loader';
import Rating from 'shared/Rating';
import Github from 'static/images/github-img.png';
import Portfolio from 'static/images/portfolio-img.png';
import Linkedin from 'static/images/linkedin-img.png';
import Trash from 'static/images/trash.svg';
import EditCandidate from './EditCandidate';
import AvatarComp from 'shared/AvatarComp';
import { sendInterviewFeedback } from 'actions/candidateActions';
import { getActiveHireCandidates, getCampaignCandidates } from 'actions/clientActions';

function ActiveHiresCandidates(props) {
    const dispatch = useDispatch();
    const { hireActiveCandidates, handleOpenNotes, isEdit, educationArray, handleInputChange, handleAddInput, handleRemoveInput, handleHobbiesChange, handleSkillsChange, handleCandidateStatus, removeCandidateFunc, uploadProfilePicture, handleChange, inputField, handleToggleTable, toggleArrowIndex, handleDeleteModal, uploadResumeHandler } = props;
    const isLoading = useSelector((state) => state.applicationIsLoading);
    const [ sortType, setSortType ] = useState('');
    const [ columnName, setColumnName ] = useState('');
    const [ fullNameArrowUp, setFullNameArrowUp ] = useState(false);
    const [ fullNameArrowDown, setFullNameArrowDown ] = useState(false);
    const [ techArrowUp, setTechArrowUp ] = useState(false);
    const [ techArrowDown, setTechArrowDown ] = useState(false);
    const [ mlArrowUp, setMlArrowUp ] = useState(false);
    const [ mlArrowDown, setMlArrowDown ] = useState(false);
    const [ campaignsArrowUp, setCampaignsArrowUp ] = useState(false);
    const [ campaignsArrowDown, setCampaignsArrowDown ] = useState(false);
    const [ registeredArrowUp, setRegisteredArrowUp ] = useState(false);
    const [ registeredArrowDown, setRegisteredArrowDown ] = useState(false);
    const campaign_Id = localStorage.getItem('campaignId');
    const active_tab = localStorage.getItem('activeTab');

    const sorted = hireActiveCandidates && hireActiveCandidates.sort((a, b) => {
        const isReversed = !sortType ? '' : (sortType === 'asc') ? 1 : -1
        if (columnName === 'full-name') {
            return isReversed * a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
        }
        else if (columnName === 'tech') {
            return (isReversed * a.tech_stack.toLowerCase().localeCompare(b.tech_stack.toLowerCase()))
        }
        else if (columnName === 'campaigns') {
            return ((isReversed * a.total_campaigns) - (b.total_campaigns))
        }
        else if (columnName === 'ml') {
            return isReversed * a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
        }
        else if (columnName === 'registered') {
            const date1 = new Date(a.registration_datetime);
            const date2 = new Date(b.registration_datetime);
            return (isReversed * date1.getTime() - isReversed * date2.getTime())
        }
        else {
            return a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
        }
    })
    const minusIconHandler = (profileStatus) => {
        const data = {
            candidate_profile_status_id: profileStatus,
            is_interview_result: 2,
            is_hired: 0,
            is_rejected: 0
        };
        dispatch(sendInterviewFeedback(data)).then((res) => {
            if (res.statusCode === 200) {
                dispatch(getActiveHireCandidates(campaign_Id));
                if(active_tab === null || active_tab === "2"){
                    dispatch(getCampaignCandidates(campaign_Id, 0));
                } else {
                    dispatch(getCampaignCandidates(campaign_Id, 1));
                }
            }
        });
    }
    const handleColumnName = (data) => {
        setColumnName(data)
    }
    const onSort = (order, data) => {
        setSortType(order)
        setColumnName(data)
        if (data === 'full-name') {
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setFullNameArrowUp(!fullNameArrowUp)
        }
        if (data === 'tech') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setTechArrowUp(!techArrowUp)
        }
        if (data === 'ml') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setMlArrowUp(!mlArrowUp)
        }
        if (data === 'campaigns') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setCampaignsArrowUp(!campaignsArrowUp)
        }
        if (data === 'registered') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowUp(!registeredArrowUp)
        }
    }
    const onSortUp = (order, data) => {
        setSortType(order)
        if (columnName === 'full-name' || data === 'full-name') {
            setFullNameArrowUp(!fullNameArrowUp)
            setFullNameArrowDown(!fullNameArrowDown)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if (columnName === 'tech' || data === 'tech') {
            setTechArrowUp(!techArrowUp)
            setTechArrowDown(!techArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if (columnName === 'ml' || data === 'ml') {
            setMlArrowUp(!mlArrowUp)
            setMlArrowDown(!mlArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if (columnName === 'campaigns' || data === 'campaigns') {
            setCampaignsArrowDown(!campaignsArrowDown)
            setCampaignsArrowUp(!campaignsArrowUp)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if (columnName === 'registered' || data === 'registered') {
            setRegisteredArrowDown(!registeredArrowDown)
            setRegisteredArrowUp(!registeredArrowUp)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        }
    }
    const onSortDown = (order) => {
        setSortType(order)
        if (columnName === 'full-name') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
        } else if (columnName === 'tech') {
            setTechArrowUp(false)
            setTechArrowDown(false)
        } else if (columnName === 'ml') {
            setMlArrowUp(false)
            setMlArrowDown(false)
        } else if (columnName === 'campaigns') {
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        } else if (columnName === 'registered') {
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        }
    }
    return (
        <>
            <table className="main-table table table-condensed github table-borderless hiringactively" id="accordion2">
                <thead>
                    <tr className="multipleprod text-center">
                        <th scope="col" colSpan="10">
                            ACTIVE HIRES
                        </th>
                    </tr>
                    <tr className="table-head">
                        <th scope="col"></th>
                        <th scope="col" className="s_no">#</th>
                        <th scope="col"></th>
                        <th scope="col" style={ { width: '18%' } }>
                            Full Name{' '}
                            <Sort
                                data={ 'full-name' }
                                handleColumnName={ handleColumnName }
                                sortArrowUp={ fullNameArrowUp }
                                sortArrowDown={ fullNameArrowDown }
                                onSort={ onSort }
                                onSortUp={ onSortUp }
                                onSortDown={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '18%' } }>
                            Tech Stack{' '}
                            <Sort
                                data={ 'tech' }
                                handleColumnName={ handleColumnName }
                                sortArrowUp={ techArrowUp }
                                sortArrowDown={ techArrowDown }
                                onSort={onSort }
                                onSortUp={onSortUp}
                                onSortDown={onSortDown}
                            />
                        </th>
                        <th scope="col" style={{ width: '10%' }}>
                            ML%{' '}
                            <Sort
                                data={'ml'}
                                handleColumnName={handleColumnName}
                                sortArrowUp={mlArrowUp}
                                sortArrowDown={mlArrowDown}
                                onSort={onSort}
                                onSortUp={onSortUp}
                                onSortDown={onSortDown}
                            />
                        </th>
                        <th scope="col" style={{ width: '16%' }}>
                            Campaigns{' '}
                            <Sort
                                data={'campaigns'}
                                handleColumnName={handleColumnName}
                                sortArrowUp={campaignsArrowUp}
                                sortArrowDown={campaignsArrowDown}
                                onSort={onSort}
                                onSortUp={onSortUp}
                                onSortDown={onSortDown}
                            />
                        </th>
                        <th scope="col" style={{ width: '12%' }}>
                            Hired{' '}
                            <Sort
                                data={'registered'}
                                handleColumnName={handleColumnName}
                                sortArrowUp={registeredArrowUp}
                                sortArrowDown={registeredArrowDown}
                                onSort={onSort}
                                onSortUp={onSortUp}
                                onSortDown={onSortDown}
                            />
                        </th>
                        <th scope="col" style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                {isLoading && (
                    <div className="loader-load">
                        <Loading />
                    </div>
                )}
                <tbody>
                    {!isLoading &&
                        sorted &&
                        sorted.map((data, idx) => {
                            return (
                                <>
                                    {' '}
                                    <tr
                                        key={idx + 'a'}
                                        className="accordion-toggle table-row storage-row"
                                        data-bs-toggle="collapse"
                                        href={`#toggle${ idx }`}
                                        onClick={() => { handleToggleTable(data.candidate_id, data) }}>
                                        <td>
                                            <span className="minus-sign pointer" onClick={ () => minusIconHandler(data.candidate_profile_status_id) }>
                                                <img src={minusBox} className="minusBox" alt='plus' />
                                            </span>
                                        </td>
                                        <th scope="row" className="s_no">{idx + 1}</th>
                                        <td>
                                            {data.is_active ? (
                                                <img
                                                    src={Play}
                                                    alt="play"
                                                    className="play-icon"
                                                    onClick={() => handleStatus(dispatch, data, 1, 0)}
                                                />
                                            ) : (
                                                <img
                                                    src={Pause}
                                                    alt="pause"
                                                    className="pause-icon pause-img"
                                                    onClick={() => handleStatus(dispatch, data, 1, 1)}
                                                />
                                            )}
                                        </td>
                                        <td>{data.full_name}</td>
                                        <td>{data.tech_stack}</td>
                                        <td>92%</td>
                                        <td>
                                            <Link
                                                to={{
                                                    pathname: '/associate-candidates',
                                                    state: {
                                                        candId: data.candidate_id,
                                                        candName: data.full_name
                                                    },
                                                }}
                                                className="vote-cand-id"
                                            >
                                                <u > {data.total_active_campaigns ? data.total_active_campaigns : 0}/<b>{data.total_campaigns ? data.total_campaigns : 0}</b></u>
                                            </Link>
                                        </td>
                                        <td>
                                            {moment(data.registration_datetime).format(ddmmyyFormat)}
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-end'>
                                                {isEdit && (
                                                    <img
                                                        onClick={() => handleDeleteModal(data, 'candidate')}
                                                        src={Trash}
                                                        alt="trash"
                                                        className='cursor'
                                                    />)
                                                }
                                                {!(toggleArrowIndex === data.candidate_id) ? (
                                                    <img
                                                        src={sideArrow}
                                                        alt="side-arrow"
                                                        className="down"
                                                        style={{ margin: '0 8px 0 8px' }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={sideArrow}
                                                        onClick={() => { handleToggleTable(data.candidate_id) }}
                                                        className="down-arrow"
                                                        alt="side-arrow"
                                                        style={{ margin: '0 8px 0 8px' }}
                                                    />
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        !isEdit && (
                                            <tr className="collapse-row dynamicrow" key={idx + 'b'} data-bs-parent="#myTable2">
                                                <td colSpan="12" className="hiddenRow">
                                                    <div
                                                        className="accordian-body collapse"
                                                        id={`toggle${ idx }`}
                                                        data-bs-parent="#accordion2"
                                                    >
                                                        <div className="emergency">
                                                            <div className="extend row g-0">
                                                                <div className="text-center col-lg-3 col-md-3 col-sm-12">
                                                                    <AvatarComp
                                                                        image={data.avatar}
                                                                        name={data.full_name}
                                                                    />
                                                                    <br />
                                                                    {data.is_active ? (
                                                                        <img
                                                                            src={Play}
                                                                            alt="play"
                                                                            className="play-icon"
                                                                            onClick={() => handleStatus(dispatch, data, 1, 0)}
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={Pause}
                                                                            alt="pause"
                                                                            className="pause-icon pause-img"
                                                                            onClick={() => handleStatus(dispatch, data, 1, 1)}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="storage-clicked col-lg-4 col-md-4 col-sm-12">
                                                                    <table>
                                                                        <tr>
                                                                            <td className="storage-title">Name</td>
                                                                            <td className="storage-sub-title">
                                                                                {data.first_name}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="storage-title">Surname</td>
                                                                            <td className="storage-sub-title">
                                                                                {' '}
                                                                                {data.last_name}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="storage-title">Location</td>
                                                                            <td className="storage-sub-title">
                                                                                {cityCountryFormatter(data)}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="storage-title">Culture Fit</td>
                                                                            <td className="storage-sub-title">
                                                                                <Rating rate={data.culture_fit} />
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div className="storage-clicked  col-lg-4 col-md-4 col-sm-12">
                                                                    <table>
                                                                        <tr>
                                                                            <td className="storage-title">Tech Stack</td>
                                                                            <td className="storage-sub-title">
                                                                                {data.tech_stack}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="storage-title">Experience</td>
                                                                            <td className="storage-sub-title">
                                                                                {data.experience} years
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="storage-title">Assessment</td>
                                                                            <td className="storage-sub-title">
                                                                                {data.assessment_score} %
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <a
                                                                                    href={data.resume}
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                    className="resume-link resume-btn-link">
                                                                                    <AiOutlineFileSearch className="resume-icon" />
                                                                                    View Resume
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div className="storage-clicked col-lg-1 col-md-1 col-sm-12">
                                                                    <div className='d-flex flex-column social-links'>
                                                                        {
                                                                            (data.github_url !== null && data.github_url !== '') ?
                                                                                (
                                                                                    <Link to={{ pathname: data.github_url }} target="_blank" >
                                                                                        <img src={Github} alt='git-img' width='20' />
                                                                                    </Link>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <img src={Github} alt='git-img' className='fade-link' width='20' />
                                                                                )
                                                                        }
                                                                        {
                                                                            (data.linkedin_url !== null && data.linkedin_url !== '') ?
                                                                                (
                                                                                    <Link to={{ pathname: data.linkedin_url }} target="_blank" >
                                                                                        <img src={Linkedin} alt='linkedin-img' width='20' />
                                                                                    </Link>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <img src={Linkedin} alt='linkedin-img' className='fade-link' width='20' />
                                                                                )
                                                                        }
                                                                        {
                                                                            (data.portfolio_url !== null && data.portfolio_url !== '') ?
                                                                                (
                                                                                    <Link to={{ pathname: data.portfolio_url }} target="_blank" >
                                                                                        <img src={Portfolio} alt='portfolio-img' width='20' />
                                                                                    </Link>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <img src={Portfolio} alt='linkedin-img' className='fade-link' width='20' />
                                                                                )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="dataclickp pt-4">
                                                                <div className="notesdata datamine w-100">
                                                                    <b>Notes</b>
                                                                    <form>
                                                                        <div className='d-flex'>
                                                                            <textarea
                                                                                className="w-100"
                                                                                id="w3review"
                                                                                name="notesValue"
                                                                                onClick={() => handleOpenNotes(data)}
                                                                                rows="4"
                                                                                cols="0"
                                                                                placeholder='Add your notes'
                                                                                defaultValue={data.notes}
                                                                            ></textarea>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                <div className="professor pt-2">
                                                                    <h4>Education</h4>
                                                                    <ul className="oxford">
                                                                        {data.educations.map((education, idm) => {
                                                                            return (
                                                                                <>
                                                                                    <li key={idm} className="jobe">
                                                                                        {education.university}
                                                                                    </li>
                                                                                    <li className="computate">
                                                                                        {
                                                                                            `${ education.specialization },
                                                                                            ${ education.course }`
                                                                                        }
                                                                                    </li>
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                                <div className="row pt-3 g-0">
                                                                    <div className="jbondz5 oxford  col-lg-6 col-md-12">
                                                                        <h4>Skills</h4>
                                                                        <ul className="jbond2">
                                                                            {data.skills.map((skill, idm) => {
                                                                                return (
                                                                                    <li className="jobe" key={idm}>
                                                                                        -{skill.skill_name}
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                    <div className="bondz oxford2 col-lg-6 col-md-12">
                                                                        <h4>Hobbies</h4>
                                                                        <ul className="jbond1">
                                                                            {data.hobbies.map((hobby, idm) => {
                                                                                return (
                                                                                    <li className="jobe" key={idm}>
                                                                                        -{hobby.hobby_name}
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        isEdit && (
                                            <tr className="collapse-row dynamicrow" key={idx + 'c'} data-bs-parent="#myTable2">
                                                <td colSpan="12" className="hiddenRow">
                                                    <div
                                                        className="accordian-body collapse"
                                                        id={`toggle${ idx }`}
                                                        data-bs-parent="#accordion1"
                                                    >
                                                        <EditCandidate
                                                            data={data}
                                                            educationArray={educationArray}
                                                            handleInputChange={handleInputChange}
                                                            handleAddInput={handleAddInput}
                                                            handleRemoveInput={handleRemoveInput}
                                                            rowIndex={idx}
                                                            handleSkillsChange={handleSkillsChange}
                                                            handleHobbiesChange={handleHobbiesChange}
                                                            handleCandidateStatus={handleCandidateStatus}
                                                            removeCandidateFunc={removeCandidateFunc}
                                                            uploadProfilePicture={uploadProfilePicture}
                                                            handleChange={handleChange}
                                                            inputField={inputField}
                                                            uploadResumeHandler={ uploadResumeHandler }
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </>
                            );
                        })}
                </tbody>
            </table>
        </>
    );
}
ActiveHiresCandidates.propTypes = {
    hireActiveCandidates: PropTypes.array,
    handleOpenNotes: PropTypes.func,
    campId: PropTypes.string,
    handleEditNotes: PropTypes.func,
    isOpenNote: PropTypes.bool,
    isEdit: PropTypes.bool,
    educationArray: PropTypes.array,
    handleInputChange: PropTypes.func,
    handleAddInput: PropTypes.func,
    handleRemoveInput: PropTypes.func,
    handleSkillsChange: PropTypes.func,
    handleHobbiesChange: PropTypes.func,
    handleCandidateStatus: PropTypes.func,
    removeCandidateFunc: PropTypes.func,
    uploadProfilePicture: PropTypes.func,
    handleChange: PropTypes.func,
    inputField: PropTypes.array,
    handleToggleTable: PropTypes.func,
    toggleArrowIndex: PropTypes.string,
    handleDeleteModal: PropTypes.func,
    uploadResumeHandler: PropTypes.func
};

export default ActiveHiresCandidates;
