import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment'
import { addMilestone, updateMilestone } from '../../../actions/clientActions';
import { addMilestoneValidator } from '../../../utils/Validater';
import { useDispatch } from 'react-redux';

function MilestoneModal(props) {
    const dispatch = useDispatch();

    const { isOpenMilestoneModal, closeMilestoneDetail, projectId, milestoneUpdate, size } = props;
    const [ inputFieldError, setInputFieldError ] = useState({});
    const [ inputField, setInputField ] = useState({});
    const [ isUpdate , setIsUpdate ] = useState(false);

    useEffect(()=>{
        if(milestoneUpdate){
            setIsUpdate(true)
            console.log(milestoneUpdate)
            setInputField({
                ...milestoneUpdate,
                start_date : moment(milestoneUpdate.start_date).format('YYYY-MM-DD'),
                end_date : moment(milestoneUpdate.end_date).format('YYYY-MM-DD')
            })
        }else{
            setInputField({
                milestone_order : size
            })
        }
    },[ milestoneUpdate ])

    const handleChange = (e) => {
        setInputField({ ...inputField, [ e.target.name ]: e.target.value });
        setInputFieldError({});
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            project_id: projectId,
            milestone_order : inputField.milestone_order,
            milestone_id : milestoneUpdate ? milestoneUpdate.id : null,
            start_date: inputField.start_date,
            end_date: inputField.end_date,
            status : inputField.status,
            milestone_name: inputField.milestone_name,
            milestone_description: inputField.milestone_description,

        };
        console.log(data)
        if (addMilestoneValidator(inputField, setInputFieldError)) {
            if(isUpdate){
                delete data.project_id
                dispatch(updateMilestone(milestoneUpdate.id, data)).then((res) => {
                    if (res.statusCode === 200) {
                        closeMilestoneDetail()
                    }
                });
            }else{
                delete data.milestone_id;
                dispatch(addMilestone(data)).then((res) => {
                    if (res.statusCode === 200) {
                        closeMilestoneDetail()
                    }
                });
            }
        }
    };

    return (
        <div id="milestoneModal">

            <Modal isOpen={ isOpenMilestoneModal }>
                <div className="modal-header">
                    <div>
                        <h5 className="modal-title" id="exampleModalLabel">
                            Milestone
                        </h5>
                    </div>
                    <div>
                        <button type="button" className="btn btn-secondary telenory closer" data-dismiss="modal" onClick={ closeMilestoneDetail }>
                            Close
                        </button>
                    </div>
                </div>
                <ModalBody>
                    <form className="login-form">
                        <div className="form-group field_cont">
                            <label htmlFor="milestone_name" className="form-label">
                                Milestone Order
                            </label>
                            <input
                                type="number"
                                min="0"
                                name="milestone_order"
                                id="milestone_order"
                                autoComplete="off"
                                placeholder="Enter your milestone order"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.milestone_order || '' }
                            />
                            {inputFieldError.milestone_order && <span className="error_msg">{inputFieldError.milestone_order}</span>}
                        </div>

                        <div className="form-group field_cont">
                            <label htmlFor="milestone_name" className="form-label">
                                Milestone Name
                            </label>
                            <input
                                type="text"
                                name="milestone_name"
                                id="milestone_name"
                                autoComplete="off"
                                placeholder="Enter your milestone name"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.milestone_name || '' }
                            />
                            {inputFieldError.milestone_name && <span className="error_msg">{inputFieldError.milestone_name}</span>}
                        </div>
                        <div className="form-group field_cont">
                            <label htmlFor="status" className="form-label">
                                Milestone Status
                            </label>
                            <select  className="form-control" id="status" name='status' value={ inputField.status }
                                onChange={ handleChange }>
                                <option name='complete' id='complete' value="complete">Completed</option>
                                <option name='on_track' id='on_track' value="on_track">On Track</option>
                                <option name='at_risk' id='at_risk' value="at_risk">At Risk</option>
                                <option name='behind_schedule' id='behind_schedule' value="behind_schedule">Behind Schedule</option>
                            </select>

                            {inputFieldError.status && <span className="error_msg">{inputFieldError.status}</span>}
                        </div>
                        <div className="form-group field_cont">
                            <label htmlFor="start_date" className="form-label">
                                Milestone Start Date
                            </label>
                            <input
                                type="date"
                                name="start_date"
                                id="start_date"
                                autoComplete="off"
                                placeholder="Enter your milestone start date"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.start_date || '' }
                            />
                            {inputFieldError.start_date && <span className="error_msg">{inputFieldError.start_date}</span>}
                        </div>
                        <div className="form-group field_cont">
                            <label htmlFor="end_date" className="form-label">
                                Milestone End Date
                            </label>
                            <input
                                type="date"
                                name="end_date"
                                id="end_date"
                                autoComplete="off"
                                placeholder="Enter your milestone end date"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.end_date || '' }
                            />
                            {inputFieldError.end_date && <span className="error_msg">{inputFieldError.end_date}</span>}
                        </div>
                        <div className="form-group field_cont">
                            <label htmlFor="milestone_description" className="form-label">
                                Milestone Description
                            </label>
                            <textarea
                                type="textarea"
                                name="milestone_description"
                                id="milestone_description"
                                autoComplete="off"
                                placeholder="Enter your milestone description"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.milestone_description || '' }
                            />
                            {inputFieldError.milestone_description && <span className="error_msg">{inputFieldError.milestone_description}</span>}
                        </div>
                        <button type="submit" className="btn btn-secondary telenory closer" data-dismiss="modal" onClick={ handleSubmit }>
                            Save
                        </button>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
}
MilestoneModal.propTypes = {
    clientHiringDetails: PropTypes.array,
    isOpenMilestoneModal: PropTypes.bool,
    closeMilestoneDetail: PropTypes.func,
    milestoneUpdate : PropTypes.object,
    projectId: PropTypes.number,
    size : PropTypes.number
};
export default MilestoneModal;
