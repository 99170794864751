import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

import AddUpdateInterviewModal from './AddUpdateInterviewModal';
import InterviewListModal from './InterviewListModal';
function InterviewModal(props) {

    const Pages = {
        LIST: 'LIST',
        ADD: 'ADD',
        UPDATE: 'UPDATE'
    }
    const [ page,  setPage ] = useState(Pages.LIST)
    const { isOpenInterviewModal, closeInterviewDetail, clientId } = props;
    const [ interview, setInterview ] = useState(null);
    const updateInterview = (interview) => {
        console.log(interview);
        setInterview(interview);
        setPage(Pages.UPDATE)
    }
    const submitForm = () => {
        setPage(Pages.LIST)
    }
    return (
        <div id="interviewModal">

            <Modal isOpen={ isOpenInterviewModal }>
                <div className="modal-header">
                    <div>
                        <h5 className="modal-title" id="exampleModalLabel">
                            Interview
                        </h5>
                    </div>
                    <div>
                        { page === Pages.LIST && <button type="button" className="btn btn-secondary telenory closer" data-dismiss="modal" onClick={ () => {
                            setPage(Pages.ADD) } }>
                            Add
                        </button>}
                        { ( page === Pages.ADD ||  page === Pages.UPDATE ) && <button type="button" className="btn btn-secondary telenory closer" data-dismiss="modal" onClick={ () => {
                            setPage(Pages.LIST) } }>
                            Cancel
                        </button>}
                        <button type="button" className="btn btn-secondary telenory closer" data-dismiss="modal" onClick={ closeInterviewDetail }>
                            Close
                        </button>
                    </div>
                </div>
                <ModalBody>
                    {
                        page === Pages.LIST &&
                        <InterviewListModal
                            clientId={ clientId }
                            updateInterview = { updateInterview }
                        />
                    }
                    {
                        page === Pages.ADD &&
                        <AddUpdateInterviewModal
                            clientId = { clientId }
                            submitForm = { submitForm }
                        />
                    }
                    {
                        page === Pages.UPDATE &&
                        <AddUpdateInterviewModal
                            clientId = { clientId }
                            interview = { interview }
                            submitForm = { submitForm }
                        />
                    }
                </ModalBody>
            </Modal>
        </div>
    );
}
InterviewModal.propTypes = {
    clientHiringDetails: PropTypes.array,
    isOpenInterviewModal: PropTypes.bool,
    closeInterviewDetail: PropTypes.func,
    clientId: PropTypes.number,
};
export default InterviewModal;
