import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import WardClientHeader from './wardClientHeader';
import { Link } from 'react-router-dom';
import Loading from 'shared/Loader';
import { getAssociateCandidates } from 'actions/candidateActions';
import moment from 'moment';
import { ddmmyyFormat } from 'shared/Constant';
import { handleStatus } from 'utils/Helper';
import Play from 'static/images/play.svg';
import Pause from 'static/images/pause.svg';
import Sort from 'shared/Sorting';

function WardClients(props) {
    const dispatch = useDispatch();
    const candId =
    props.history &&
    props.history.location &&
    props.history.location.state &&
    props.history.location.state.campId;
    const candName =
    props.history &&
    props.history.location &&
    props.history.location.state &&
    props.history.location.state.candName;
    const associateCandidates = useSelector(
        (state) => state.assoCandidatesListing
    );
    const [ searchValue, setSearchValue ] = useState('');
    const [ filterOptions, setFilterOptions ] = useState({
        city: '',
        state: '',
        tech_stack: '',
    });
    const [ sortType, setSortType ] = useState('asc');
    const [ columnName, setColumnName ] = useState('');
    const [ fullNameArrowUp, setFullNameArrowUp ] = useState(false);
    const [ fullNameArrowDown, setFullNameArrowDown ] = useState(false);
    const [ registeredArrowUp, setRegisteredArrowUp ] = useState(false);
    const [ registeredArrowDown, setRegisteredArrowDown ] = useState(false);

    const isLoading = useSelector((state) => state.applicationIsLoading);
    useEffect(() => {
        dispatch(getAssociateCandidates(candId, filterOptions));
    }, [ dispatch, candId, filterOptions ]);
    const updateSearch = (event) => {
        setSearchValue(event.target.value.substr(0, 20));
    };
    const handleCandidateOptions = (event) => {
        setFilterOptions({
            ...filterOptions,
            [ event.target.name ]: event.target.value,
        });
    };
    const tableData =
    associateCandidates &&
    associateCandidates.filter((d) => {
        return (
            (d.company_name &&
          d.company_name.toLowerCase().indexOf(searchValue.toLowerCase()) !==
            -1) ||
        (d.campaign_name &&
          d.campaign_name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1));
    });
    const sorted = tableData && tableData.sort((a, b) => {
        const isReversed = !sortType ? '' :  (sortType === 'asc') ? 1 : -1
        if(columnName === 'company_name'){
            return isReversed*a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
        }
        else if(columnName === 'registered'){
            const date1 = new Date(a.created_datetime);
            const date2 = new Date(b.created_datetime);
            return (isReversed*date1.getTime() - isReversed*date2.getTime())
        }
        else {
            return a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
        }
    })
    const handleColumnName = (data) => {
        setColumnName(data)
    }
    const onSort = (order, data) => {
        setSortType(order);
        setColumnName(data)
        if(data === 'company_name') {
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setFullNameArrowUp(!fullNameArrowUp)
        }
        if(data === 'registered') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setRegisteredArrowUp(!registeredArrowUp)
        }
    };
    const onSortUp = (order, data) => {
        setSortType(order);
        if(columnName === 'company_name' || data === 'company_name') {
            setFullNameArrowUp(!fullNameArrowUp)
            setFullNameArrowDown(!fullNameArrowDown)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'registered' || data === 'registered') {
            setRegisteredArrowDown(!registeredArrowDown)
            setRegisteredArrowUp(!registeredArrowUp)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
        }
    };
    const onSortDown = (order) => {
        setSortType(order);
        if(columnName === 'company_name') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
        } else if(columnName === 'registered'){
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        }
    };
    return (
        <>
            <WardClientHeader candName={ candName } type='Clients' updateSearch={ updateSearch }
                searchValue={ searchValue } handleCandidateOptions = { handleCandidateOptions } />
            <table className="main-table table table-condensed github table-borderless">
                <thead>
                    <tr className="table-head">
                        <th scope="col" className="s_no">#</th>
                        <td></td>
                        <th scope="col">
                            Company {' '}
                            <Sort
                                data= { 'company_name' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { fullNameArrowUp }
                                sortArrowDown ={ fullNameArrowDown }
                                onSort={ onSort }
                                onSortUp={ onSortUp }
                                onSortDown={ onSortDown }
                            />
                        </th>
                        <th scope="col">Active Campaign(s) </th>
                        <th scope="col">Notes</th>
                        <th scope="col" className="act-center">
                            Started{' '}
                            <Sort
                                data= { 'registered' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp={ registeredArrowUp }
                                sortArrowDown={ registeredArrowDown }
                                onSort={ onSort }
                                onSortUp={ onSortUp }
                                onSortDown={ onSortDown }
                            />
                        </th>
                    </tr>
                </thead>
                {isLoading && (
                    <div className="loader">
                        <Loading />
                    </div>
                )}
                <tbody>
                    {!isLoading &&
            sorted.map((candidates, idx) => {
                return (
                    <tr className="table-row" key={ idx }>
                        <th scope="row" className="s_no">{idx + 1}</th>
                        <td>
                            {' '}
                            {candidates.is_active ? (
                                <img
                                    src={ Play }
                                    alt="play"
                                    className="play-icon"
                                    onClick={ () => handleStatus(dispatch, candidates, 0, 0) }
                                />
                            ) : (
                                <img
                                    src={ Pause }
                                    alt="pause"
                                    className="pause-icon pause-img"
                                    onClick={ () => handleStatus(dispatch, candidates, 0, 1) }
                                />
                            )}
                        </td>
                        <td>
                            <Link className="epicdetail" to={ {
                                pathname: '/epic-passport',
                                state: {
                                    clientId: candidates.id
                                }
                            } }>
                                {candidates.company_name}
                            </Link>
                        </td>
                        <td>
                            <Link className="epicdetail" to={ {
                                pathname: '/storage',
                                state: {
                                    campId: candidates.campaign_id,
                                    campaign_name: candidates.campaign_name
                                }
                            } }>
                                {candidates.campaign_name}
                            </Link>
                        </td>
                        <td width="500">{candidates.notes}</td>
                        <td width="300" >
                            {moment(candidates.created_datetime).format(ddmmyyFormat)}
                        </td>
                    </tr>
                );
            })}
                </tbody>
            </table>
        </>
    );
}
WardClients.propTypes = {
    history: PropTypes.object,
};

export default WardClients;
