export function clientListing(state = [], action) {
    switch (action.type) {
    case 'FETCH_CLIENT_LISTING':
        return action.clientListing;
    default:
        return state;
    }
}
export function clientHiringDetails(state = [], action) {
    switch (action.type) {
    case 'FETCH_CLIENT_HIRING_DETAILS':
        return action.clientHiringDetails;
    default:
        return state;
    }
}
export function filterDetails(state = [], action) {
    switch (action.type) {
    case 'FETCH_FILTER_DETAILS':
        return action.filterDetails;
    default:
        return state;
    }
}
export function profileStatus(state = false, action) {
    switch (action.type) {
    case 'FETCH_STATUS':
        return action.profileStatus;
    default:
        return state;
    }
}
export function clientsData(state = [], action) {
    switch (action.type) {
    case 'CLIENT_DATA':
        return action.clientsData;
    default:
        return state;
    }
}