import React from 'react'
import PropTypes from 'prop-types';
import Button from 'shared/Button'
import LogoSection from './LogoSection'

function VerificationCode(props) {
    const { handleChange, handleSubmit, inputField, inputFieldError, isAuth } = props
    return (
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="row no-gutters d-flex flex-column card">
                <div className="card-body">
                    <LogoSection isLogin = { false } title = 'A text message with your code has been sent to' />
                    <form className="login-form">
                        <div className="form-group field_cont">
                            <label htmlFor="email" className="form-label">
                                Code
                            </label>
                            <input
                                type="number"
                                name="reset_password_otp"
                                id="reset_password_otp"
                                autoComplete="off"
                                placeholder="Enter your code"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.reset_password_otp || '' }
                            />
                            {inputFieldError.reset_password_otp && (
                                <span className="error_msg">{inputFieldError.reset_password_otp}</span>
                            )}
                        </div>
                        <Button
                            handleSubmit={ handleSubmit }
                            buttonName= { isAuth ? 'SIGN IN TO YOUR ACCOUNT' : 'Reset Password' }
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}
VerificationCode.propTypes = {
    handleResetPassword: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    inputField: PropTypes.object,
    inputFieldError: PropTypes.object,
    isAuth: PropTypes.bool,
};
export default VerificationCode
