import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment'
import { addProject, updateProject } from '../../../actions/clientActions';
import { addProjectValidator } from '../../../utils/Validater';
import { useDispatch } from 'react-redux';

function ProjectModal(props) {
    const dispatch = useDispatch();

    const { isOpenProjectModal, closeProjectDetail, clientId, projectUpdate } = props;
    const [ inputFieldError, setInputFieldError ] = useState({});
    const [ inputField, setInputField ] = useState({});
    const [ isUpdate , setIsUpdate ] = useState(false);

    useEffect(()=>{
        if(projectUpdate){
            setIsUpdate(true)
            console.log(projectUpdate)
            setInputField({
                ...projectUpdate,
                project_start_date : moment(projectUpdate.project_start_date).format('YYYY-MM-DD'),
                project_end_date : moment(projectUpdate.project_end_date).format('YYYY-MM-DD')
            })
        }
    },[ projectUpdate ])

    const handleChange = (e) => {
        setInputField({ ...inputField, [ e.target.name ]: e.target.value });
        setInputFieldError({});
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            client_id: clientId,
            project_start_date: inputField.project_start_date,
            project_end_date: inputField.project_end_date,
            project_name: inputField.project_name,
            project_description: inputField.project_description,

        };
        console.log(data)
        if (addProjectValidator(inputField, setInputFieldError)) {
            if(isUpdate){
                dispatch(updateProject(projectUpdate.id, data)).then((res) => {
                    if (res.statusCode === 200) {
                        closeProjectDetail()
                    }
                });
            }else{
                dispatch(addProject(data)).then((res) => {
                    if (res.statusCode === 200) {
                        closeProjectDetail()
                    }
                });
            }
        }
    };

    return (
        <div id="projectModal">

            <Modal isOpen={ isOpenProjectModal }>
                <div className="modal-header">
                    <div>
                        <h5 className="modal-title" id="exampleModalLabel">
                            Project
                        </h5>
                    </div>
                    <div>
                        <button type="button" className="btn btn-secondary telenory closer" data-dismiss="modal" onClick={ closeProjectDetail }>
                            Close
                        </button>
                    </div>
                </div>
                <ModalBody>
                    <form className="login-form">

                        <div className="form-group field_cont">
                            <label htmlFor="project_name" className="form-label">
                                Project Name
                            </label>
                            <input
                                type="text"
                                name="project_name"
                                id="project_name"
                                autoComplete="off"
                                placeholder="Enter your project name"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.project_name || '' }
                            />
                            {inputFieldError.project_name && <span className="error_msg">{inputFieldError.project_name}</span>}
                        </div>
                        <div className="form-group field_cont">
                            <label htmlFor="project_start_date" className="form-label">
                                Project Start Date
                            </label>
                            <input
                                type="date"
                                name="project_start_date"
                                id="project_start_date"
                                autoComplete="off"
                                placeholder="Enter your project start date"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.project_start_date || '' }
                            />
                            {inputFieldError.project_start_date && <span className="error_msg">{inputFieldError.project_start_date}</span>}
                        </div>
                        <div className="form-group field_cont">
                            <label htmlFor="project_end_date" className="form-label">
                                Project End Date
                            </label>
                            <input
                                type="date"
                                name="project_end_date"
                                id="project_end_date"
                                autoComplete="off"
                                placeholder="Enter your project end date"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.project_end_date || '' }
                            />
                            {inputFieldError.project_end_date && <span className="error_msg">{inputFieldError.project_end_date}</span>}
                        </div>
                        <div className="form-group field_cont">
                            <label htmlFor="project_description" className="form-label">
                                Project Description
                            </label>
                            <textarea
                                type="textarea"
                                name="project_description"
                                id="project_description"
                                autoComplete="off"
                                placeholder="Enter your project description"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.project_description || '' }
                            />
                            {inputFieldError.project_description && <span className="error_msg">{inputFieldError.project_description}</span>}
                        </div>
                        <button type="submit" className="btn btn-secondary telenory closer" data-dismiss="modal" onClick={ handleSubmit }>
                            Save
                        </button>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
}
ProjectModal.propTypes = {
    clientHiringDetails: PropTypes.array,
    isOpenProjectModal: PropTypes.bool,
    closeProjectDetail: PropTypes.func,
    projectUpdate : PropTypes.object,
    clientId: PropTypes.number,
};
export default ProjectModal;
