import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom'

function Notes(props) {
    const location = useLocation();
    const {
        handleEditNotes,
        clientList,
        handleCloseNotes,
        updateNotes,
        notesValue,
        handleCandidatesNotes,
    } = props;
    return (
        <table className="notes-table w-100">
            <tbody>
                <tr>
                    <td className="githu">
                        <div data-toggle="collapse" data-target="#demo2">
                            <div className="github3">
                                {' '}
                                <textarea
                                    id="editNotes"
                                    onChange={ (event) => handleEditNotes(event) }
                                    name="notesValue"
                                    rows="10"
                                    cols="0"
                                    defaultValue={ (typeof(notesValue) === 'string' || ((typeof(notesValue) === 'object') && (location.pathname === '/storage' || location.pathname === '/candidates' || location.pathname === '/pending'))) ? notesValue.notes : notesValue.notes === undefined ? notesValue.campaign_description : clientList && clientList[ 0 ] && clientList[ 0 ].notes }
                                >
                                </textarea>
                            </div>
                            <div className="zombe d-flex justify-content-center">
                                {!notesValue ? (
                                    <button
                                        className="junkin"
                                        type="button"
                                        name="button"
                                        onClick={ (event) =>
                                            updateNotes(event)
                                        }
                                    >
                                        Save
                                    </button>
                                ) : (
                                    <button
                                        className="junkin"
                                        type="button"
                                        name="button"
                                        onClick={ (event) =>
                                            handleCandidatesNotes(notesValue , event)
                                        }
                                    >
                                        {' '}
                                        Save
                                    </button>
                                )}
                                <button
                                    className="junkin"
                                    type="button"
                                    name="button"
                                    onClick={ handleCloseNotes }
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
Notes.propTypes = {
    clientList: PropTypes.array,
    handleEditNotes: PropTypes.func,
    handleOpenNotes: PropTypes.func,
    handleCloseNotes: PropTypes.func,
    handleCandidatesNotes: PropTypes.func,
    updateNotes: PropTypes.func,
    notesValue: PropTypes.string,
};
export default Notes;
