export function applicationIsLoading(bool) {
    return {
        type: 'APPLICATION_IS_LOADING',
        isLoading: bool,
    };
}

export function spinnerIsLoading(bool) {
    return {
        type: 'SPINNER_IS_LOADING',
        isLoading: bool,
    };
}