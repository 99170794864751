import React from 'react';
import PropTypes from 'prop-types';
import {  Modal, ModalBody, ModalFooter } from 'reactstrap';

const ResultModal = (props) => {
    const {
        isOpenResultModal,
        handleDecision
    } = props;

    return (
        <>
            <Modal isOpen={ isOpenResultModal } className="result">
                <ModalBody>
                    <p className="result-heading pb-0">What is the result of the interview?</p>
                </ModalBody>
                <ModalFooter>
                    <div className="modal-footer result">
                        <button type="button" className="decision-btn" data-dismiss="modal" onClick ={ () => handleDecision(1) } >Positive</button>
                        <button type="button" className="decision-btn" onClick ={ () => handleDecision(2) }>Negative</button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}
ResultModal.propTypes = {
    isOpenResultModal: PropTypes.bool,
    handleDecision: PropTypes.func,
};
export default ResultModal;