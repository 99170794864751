import React from 'react';
import BackImage from 'static/images/back.svg';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import Filter from '../FilterHeader';
import { ReactSVG } from 'react-svg';
function VoteHeader(props) {
    const { candName , updateSearch, searchValue , type, handleCandidateOptions } = props;
    return(
        <>
            <nav className="navbar navbar-expand-lg navbar-dashboard">
                <div className="container-fluid">
                    <Link className="navbar-brand" to=""></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 richard">
                            <li className="nav-item amand"><Link to="/candidates"><ReactSVG src={ BackImage } className="back-arrow" /></Link></li>
                            <li className="nav-item amand2">
                                <a className="nav-link back-heading" href="/candidates">{candName}</a>
                            </li>
                        </ul>
                        <div className="port3">
                            {candName} - {type}
                        </div>
                        <Filter updateSearch={ updateSearch } searchValue={ searchValue } handleCandidateOptions={ handleCandidateOptions } />
                    </div>
                </div>
            </nav>
        </>
    )
}
VoteHeader.propTypes = {
    candName: PropTypes.string,
    searchValue: PropTypes.string,
    type: PropTypes.string,
    updateSearch: PropTypes.func,
    handleCandidateOptions: PropTypes.func
};

export default VoteHeader