/* eslint-disable no-debugger */
import axios from 'axios';
import { encryptFunc, getAccessToken } from 'utils/Helper';
import  { REACT_APP_BASE_URL }  from '../config';
import { applicationIsLoading } from './commonActions';
import { decryptFunc } from '../utils/Helper'
import { getActiveHireCandidates, getCampaignCandidates, getClientProfile } from './clientActions';
import { triggerNotifier } from 'shared/Notifier';

export function fetchAssociateCandidates(assoCandidatesListing) {
    return {
        type: 'FETCH_ASSOCIATE_CANDIDATES',
        assoCandidatesListing,
    };
}
export function fetchSendVotedDetails(sendVotedDetails) {
    return {
        type: 'FETCH_SEND_VOTED_DETAILS',
        sendVotedDetails,
    };
}
export function fetchResumeFileName(resumeFileName) {
    return {
        type: 'FECTH_RESUME_FILE_NAME',
        resumeFileName
    }
}
export function fetchSharedProfileDetails(sharedProfileStatus) {
    return {
        type: 'FETCH_SHARED_PROFILE_DETAILS',
        sharedProfileStatus
    }
}

export function getCandidateListing(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + `admin/api/getCandidateList?tech_stack=${ (data && data.tech_stack  === 'All' ? '' : data && data.tech_stack) || '' }&city=${ (data && data.city === 'All' ? '' : data && data.city ) || '' }&state=${ (data &&  data.state === 'All' ? '' : data &&  data.state) || ' '  }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error);
                return error;
            });
    };
}

export function deleteCandidate(data, id) {
    return dispatch => {
        return axios({
            method: 'delete',
            url: REACT_APP_BASE_URL + `admin/api/removeCandidate/${ id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                if(data.is_resume_delete !== 1){
                    dispatch(getCandidateListing())
                }
                return res;
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }
}
export function deleteClientCampaign(data) {
    return dispatch => {
        return axios({
            method: 'delete',
            url: REACT_APP_BASE_URL + `admin/api/removeClientCampaign/${ data.id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getClientProfile(data.client_id))
                return res;
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }
}

export function sendInterviewFeedback(data) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'admin/api/addInterviewFeedback',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error)
                return error;
            });
    };
}
export function addCandidateAction(data) {
    return function (dispatch){
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'admin/api/addCandidate',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}
export function getAssociateCandidates(candId, data) {
    return (dispatch) => {
        const size = data ? Object.values(data).every( (val, i, arr) => val === arr[ 0 ] ) : ''
        const dynamicUrl = size ? `admin/api/getCandidateClients?candidate_id=${ candId }` : `admin/api/getCandidateClients?candidate_id=${ candId }&tech_stack=${ (data && data.tech_stack  === 'All' ? '' : data && data.tech_stack) || '' }&city=${ (data && data.city === 'All' ? '' : data && data.city ) || '' }&state=${ (data &&  data.state === 'All' ? '' : data &&  data.state) || ''  }`
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + dynamicUrl,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false));
                dispatch(fetchAssociateCandidates(res.data.candidate_clients));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error);
                return error;
            });
    };
}
export function getSendToVoteDetails(id, clientId, data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + `admin/api/getSendToVote?candidate_id=${ id }&tech_stack=${ (data && data.tech_stack  === 'All' ? '' : data && data.tech_stack) || '' }&city=${ (data && data.city === 'All' ? '' : data && data.city ) || '' }&state=${ (data &&  data.state === 'All' ? '' : data &&  data.state) || ' '  }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false));
                dispatch(fetchSendVotedDetails(res.data.send_to_vote));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error);
                return error;
            });
    };
}

export function updateCandidatesNotes(id,data) {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + `admin/api/updateCandidateNotes/${ id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getActiveHireCandidates(id))
                dispatch(getCampaignCandidates(id, 0))
                return res;
            })
            .catch((error) => {
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}

export function updateCandidates(id,data) {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + `admin/api/updateCandidate/${ id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getCandidateListing())
                window.location.reload();
                return res;
            })
            .catch((error) => {
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}

export function updateCandidatesFromHireSection(id, data, campId, filterOptions) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + `admin/api/updateCandidate/${ id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}

export function uploadResume(role, data) {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + 'user/upload/file/'+role,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-Type': 'multipart/form-data',
            },
            data: data,
        })
            .then((response) => {
                const res = response.data
                dispatch(fetchResumeFileName(res.data.file_name.split('/')[ 1 ]))
                return res;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    };
}

export function sendCulturalFitRating(data) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'admin/api/shareCandidateProfile',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(fetchSharedProfileDetails(res.data.data.is_shared))
                dispatch(getSendToVoteDetails(data.candidate_id))
                dispatch(applicationIsLoading(false));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error)
                return error;
            });
    };
}

export function withdrawalCandidateProfileAction(data) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'admin/api/withdrawalCandidateProfile',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                dispatch(applicationIsLoading(false));
                getSendToVoteDetails(data.candidate_id)
                window.location.reload();
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error)
                return error;
            });
    };
}
