import React from 'react';
import PropTypes from 'prop-types';
import {  Modal, ModalBody, ModalFooter } from 'reactstrap';
import CrossIcon from 'static/images/cross-black.svg';

const DeleteModal = (props) => {
    const {
        isOpenDeleteModal,
        clientId,
        removeClientFunc,
        handleDeleteModal,
        userType,
        removeClientCampaign,
        removeCandidateFunc,
        candidateInfo,
        handleChange
    } = props;
    return (
        <>
            <Modal isOpen={ isOpenDeleteModal } className="result">
                <ModalBody>
                    {
                        userType === 'uploadResume' ? (
                            <img
                                src={ CrossIcon }
                                className="delete-modal-icon"
                                alt="cross"
                                onClick={ () => handleDeleteModal() }
                            />
                        ) : ''
                    }
                    {
                        userType === 'client' ?
                            <p className="result-heading mb-0">You’re about to delete this client from your list and archive their hiring profile/campaigns, are you sure?</p>
                            :
                            userType === 'campaign' ?
                                <p className="result-heading mb-0">You’re about to permanently delete this campaign, are you sure?</p>
                                :
                                userType === 'uploadResume' ?
                                    <div>
                                        <p className="result-heading mb-0">Please reupload a new resume.</p><br />
                                        <div className="input-group input-button">
                                            <input
                                                type="file"
                                                name="resume"
                                                id="resume"
                                                onChange={ handleChange }
                                                className="form-control"
                                                accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                                            />
                                        </div>
                                    </div>
                                    :
                                    <p className="result-heading mb-0">You’re about to permanently delete this candidate from your list and remove them from any active campaigns, are you sure?</p>
                    }
                </ModalBody>
                <ModalFooter>
                    {
                        userType === 'uploadResume' ? '' : (
                            <div className="modal-footer result">
                                {
                                    userType === 'client' ?
                                        <button
                                            type="button"
                                            className="decision-btn"
                                            onClick={ () => removeClientFunc(clientId) }
                                        >
                                            Delete it
                                        </button>
                                        :
                                        userType === 'campaign' ?
                                            <button
                                                type="button"
                                                className="decision-btn"
                                                onClick={ () => { removeClientCampaign(clientId); handleDeleteModal() } }
                                            >
                                                I’m sure
                                            </button>
                                            :
                                            <button
                                                type="button"
                                                className="decision-btn"
                                                onClick={ () => { removeCandidateFunc(candidateInfo); handleDeleteModal() } }
                                            >
                                                Delete it
                                            </button>
                                }
                                <button
                                    type="button"
                                    className="decision-btn"
                                    onClick={ () => handleDeleteModal() }
                                >
                                    Nope
                                </button>
                            </div>
                        )
                    }
                </ModalFooter>
            </Modal>
        </>
    );
}
DeleteModal.propTypes = {
    isOpenDeleteModal: PropTypes.bool,
    removeClientFunc: PropTypes.func,
    clientId: PropTypes.number,
    handleDeleteModal: PropTypes.func,
    userType: PropTypes.string,
    removeClientCampaign : PropTypes.func,
    removeCandidateFunc : PropTypes.func,
    candidateInfo: PropTypes.object,
    handleChange: PropTypes.func,
};
export default DeleteModal;