import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import moment from 'moment'
import { getClientInterviews, deleteInterview } from '../../../actions/clientActions';
import Loading from '../../../shared/Loader';
function InterviewListModal(props) {
    const dispatch = useDispatch();

    const {  clientId, updateInterview } = props;
    console.log(props)
    const [ isLoading, setIsLoading ] = useState(false);
    const [ interviews, setInterviews ] = useState([]);
    const fetchInteviews = async () => {
        setIsLoading(true)
        dispatch(getClientInterviews(clientId)).then((res) => {
            if (res.statusCode === 200) {
                setInterviews(res.data.client_interviews);
            }
            setIsLoading(false)
        });
    }
    useEffect(() => {
        fetchInteviews()
    }, []);

    const removeInterview = async (interview_id) => {
        const result = window.confirm('Are you sure you want to delete?');
        if(result){
            setIsLoading(true)
            dispatch(deleteInterview(interview_id)).then((res) => {
                fetchInteviews();
                setIsLoading(false)
            });
        }

    }

    return (
        <div className="main-div">
            <table className="main-table table table-condensed github table-borderless">

                <thead>
                    <tr className="table-head">
                        <th scope="col" className="s_no">#</th>
                        <th scope="col" style={ { width: '10%' } }>First Name</th>
                        <th scope="col" style={ { width: '10%' } }>
                            Last Name <img src="Images/hiphen.svg" alt="" />
                        </th>
                        <th scope="col" style={ { width: '30%' } }>Description </th>
                        <th scope="col" style={ { width: '20%' } }>
                            Date <img src="Images/hiphen.svg" alt="" />
                        </th>
                        <th scope="col" style={ { width: '30%' } }>
                            Action <img src="Images/hiphen.svg" alt="" />
                        </th>

                    </tr>
                </thead>
                {isLoading && (
                    <div className="loader">
                        <Loading />
                    </div>
                )}
                <tbody>
                    {!isLoading &&
                        interviews &&
                        interviews.map((camp, idx) => {
                            return (
                                <tr key={ idx } className="table-row">
                                    <th scope="row" className="s_no">{idx + 1}</th>

                                    <td>{camp.first_name}</td>
                                    <td>{camp.last_name}</td>
                                    <td>{camp.interview_description}</td>
                                    <td> {moment(camp.interview_date).format('YYYY-MM-DD HH:mm')}{' '}</td>
                                    <td >
                                        <button type="submit" className="btn btn-secondary telenory closer" onClick = { () =>{
                                            updateInterview(camp)
                                        } } >
                                            Edit
                                        </button>
                                        <button type="submit" className="btn btn-secondary telenory closer" data-dismiss="modal"
                                            onClick = { () =>{
                                                removeInterview(camp.id)
                                            } }  >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>

        </div>

    );
}
InterviewListModal.propTypes = {
    clientHiringDetails: PropTypes.array,
    isOpenInterviewListModal: PropTypes.bool,
    updateInterview: PropTypes.func,
    clientId: PropTypes.number,
};
export default InterviewListModal;
