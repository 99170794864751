import axios from 'axios';
import { getAccessToken } from 'utils/Helper';
import  { REACT_APP_BASE_URL }  from '../config';
import { applicationIsLoading } from './commonActions';
import { encryptFunc, decryptFunc } from '../utils/Helper'
import { getCandidateListing, getSendToVoteDetails } from './candidateActions';
import { getActiveHireCandidates, getCampaignCandidates, getClientProfile, getClientsListing } from './clientActions';
import { triggerNotifier } from 'shared/Notifier';

export function fetchClientsListing(clientListing) {
    return {
        type: 'FETCH_CLIENT_LISTING',
        clientListing,
    };
}
export function fetchClientsItems(clientsData) {
    return {
        type: 'CLIENT_DATA',
        clientsData,
    };
}
export function fetchClientHiringDetails(clientHiringDetails) {
    return {
        type: 'FETCH_CLIENT_HIRING_DETAILS',
        clientHiringDetails,
    };
}
export function fetchFilterDetails(filterDetails) {
    return {
        type: 'FETCH_FILTER_DETAILS',
        filterDetails
    }
}
export function fetchStatus(profileStatus) {
    return {
        type: 'FETCH_STATUS',
        profileStatus
    }
}

export function getClientListing(data) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + `admin/api/getClient?is_pending=1&city=${ (data && data.city === 'All' ? '' : data && data.city ) || '' }&state=${ (data &&  data.state === 'All' ? '' : data &&  data.state) || ' ' }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false));
                dispatch(fetchClientsListing(res.data.clients_list));
                dispatch(fetchClientsItems(res.data.clients_list))
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error);
                return error;
            });
    };
}
export function acceptDeclineClient(id, data) {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + `admin/api/acceptOrDeclineClient/${ id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getClientListing())
                return res;
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    };
}
export function getClientHiringDetails(id) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + `admin/api/getClientHiringProfileData?client_id=${ id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false));
                dispatch(fetchClientHiringDetails(res.data.client_hiring_profile_data));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error);
                return error;
            });
    };
}
export function getFilters(id, isPending) {
    return (dispatch) => {
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'get',
            url: REACT_APP_BASE_URL + (isPending === 1 ? `user/api/getFiltersData?is_candidate_filter=${ id }&is_pending=${ 1 }` : `user/api/getFiltersData?is_candidate_filter=${ id }&is_pending=${ 0 }`),
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(applicationIsLoading(false));
                dispatch(fetchFilterDetails(res.data));
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                console.log(error);
                return error;
            });
    };
}

export function updateNotesDetails(id,data) {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + `admin/api/editClientProfile/${ id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getClientListing())
                dispatch(getClientsListing())
                return res;
            })
            .catch((error) => {
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}

export function updateStatus(data, details) {
    return function (dispatch){
        dispatch(applicationIsLoading(true));
        return axios({
            method: 'post',
            url: REACT_APP_BASE_URL + 'user/api/updateStatus',
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data);
                if (res.data.status_type === 0 ){
                    dispatch(applicationIsLoading(false));
                    dispatch(getClientProfile(data.id))
                    dispatch(getClientListing())
                    dispatch(getClientsListing())
                    window.location.reload()
                }
                else if (res.data.status_type === 1){
                    dispatch(applicationIsLoading(false));
                    dispatch(getClientHiringDetails(data.id))
                    dispatch(getActiveHireCandidates(data.id))
                    dispatch(getCandidateListing())
                    window.location.reload()
                }
                else if (res.data.status_type === 2) {
                    dispatch(applicationIsLoading(false));
                    dispatch(getCampaignCandidates('',0))
                    dispatch(getSendToVoteDetails(details.candidate_id ? details.candidate_id : details.client_id))
                    dispatch(getClientProfile(details.client_id))
                    window.location.reload()
                }
                return res;
            })
            .catch((error) => {
                dispatch(applicationIsLoading(false));
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}

export function updatePendingNotes(id,data) {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: REACT_APP_BASE_URL + `admin/api/updateClientNotes/${ id }`,
            headers: {
                Authorization:  `Bearer ${ getAccessToken() }`,
                'content-type': 'application/json',
            },
            data: { data: { newData: encryptFunc(data) } },
        })
            .then((response) => {
                const res = decryptFunc(response.data)
                dispatch(getClientListing())
                dispatch(getClientsListing())
                return res;
            })
            .catch((error) => {
                const message = error.response && error.response.data && (error.response.data.message || 'Invalid Credentials')
                triggerNotifier({ type: 'error', message })
                return error;
            });
    };
}