import React from 'react';
import PropTypes from 'prop-types';
import BackArrow from 'static/images/back.svg';
import { Link } from 'react-router-dom';
import FilterHeader from '../FilterHeader';
import { ReactSVG } from 'react-svg';

function HiringHeader(props) {
    const { companyName, updateSearch, searchValue, handleCandidateOptions } = props
    return(
        <>
            <nav className="navbar navbar-expand-lg navbar-dashboard">
                <div className="container-fluid">
                    <Link className="navbar-brand" href></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 richard">
                            <li className="nav-item amand  back-arrow-icon"><Link to="/clients"><ReactSVG src={ BackArrow } className="back-arrow" /></Link></li>
                            <li className="nav-item">
                                <a className="nav-link back-heading" href="/clients">CLIENTS LIST</a>
                            </li>
                        </ul>
                        <div className="port3">
                            {companyName ? `${ companyName }  Active Resources` : '' }
                        </div>
                        <FilterHeader updateSearch={ updateSearch } searchValue={ searchValue } handleCandidateOptions={ handleCandidateOptions }/>
                    </div>
                </div>
            </nav>
        </>
    )
}
HiringHeader.propTypes = {
    companyName: PropTypes.string,
    updateSearch: PropTypes.func,
    handleCandidateOptions: PropTypes.func,
    searchValue: PropTypes.string,
};

export default HiringHeader