import React, { useEffect , useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Sort from 'shared/Sorting';
import Play from 'static/images/play.svg';
import Pause from 'static/images/pause.svg';
import SendToVoteIcon from 'static/images/send-to-vote.svg';
import Reload from 'static/images/reload.svg';
import Loading from 'shared/Loader';
import { getSendToVoteDetails, sendCulturalFitRating, withdrawalCandidateProfileAction } from 'actions/candidateActions';
import VoteHeader from '../WardClients/wardClientHeader';
import { SearchTableData } from 'shared/Constant';
import CultureFitModal from './CultureFitModal';
import { ReactSVG } from 'react-svg';
import Bag from 'static/images/bag.svg';
import { handleStatus } from 'utils/Helper';

function SendToVote(props) {
    const dispatch = useDispatch()
    const [ searchValue, setSearchValue ] = useState('');
    const [ cultureFitRating, setCultureFitRating ] = useState('');
    const [ currentCampData, setCurrentCampData ] = useState({});
    const [ isCultureFit, setIsCultureFit ] = useState(false);

    const [ sortType, setSortType ] = useState('');
    const [ columnName, setColumnName ] = useState('');
    const [ campaignsArrowUp, setCampaignsArrowUp ] = useState(false);
    const [ campaignsArrowDown, setCampaignsArrowDown ] = useState(false);
    const [ companyArrowUp, setCompanyArrowUp ] = useState(false);
    const [ companyArrowDown, setCompanyArrowDown ] = useState(false);
    const [ techArrowUp, setTechArrowUp ] = useState(false);
    const [ techArrowDown, setTechArrowDown ] = useState(false);
    const [ mlArrowUp, setMlArrowUp ] = useState(false);
    const [ mlArrowDown, setMlArrowDown ] = useState(false);
    const [ statusArrowUp, setStatusArrowUp ] = useState(false);
    const [ statusArrowDown, setStatusArrowDown ] = useState(false);

    const candName = props.history && props.history.location && props.history.location.state && props.history.location.state.candName
    const candId = props.history && props.history.location && props.history.location.state && props.history.location.state.candId
    const sendVotedDetails = useSelector(state => state.sendVotedDetails)
    const isLoading = useSelector(state => state.applicationIsLoading)
    const clientId = props.history.location.state.clientId
    const [ filterOptions, setFilterOptions ] = useState({
        city: '', state: '', tech_stack:''
    });
    const tableData = SearchTableData(sendVotedDetails, searchValue);
    useEffect(() => {
        dispatch(getSendToVoteDetails(candId, clientId, filterOptions))
    }, [ dispatch,candId, clientId, filterOptions ])
    const sorted = tableData && tableData.sort((a, b) => {
        const isReversed = !sortType ? '' :  (sortType === 'asc') ? 1 : -1
        if(columnName === 'campaigns'){
            return isReversed*a.campaign_name.toLowerCase().localeCompare(b.campaign_name.toLowerCase())
        } else if(columnName === 'company'){
            return isReversed*a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
        } else if(columnName === 'tech'){
            return (isReversed*a.job_title.toLowerCase().localeCompare(b.job_title.toLowerCase()))
        } else if(columnName === 'ml'){
            return isReversed*a.campaign_name.toLowerCase().localeCompare(b.campaign_name.toLowerCase())
        } if(columnName === 'status'){
            if(isReversed === 1){
                return isReversed*a.profile_status - b.profile_status
            } else if(isReversed === -1){
                return b.profile_status - a.profile_status
            } else {
                return a.campaign_name.toLowerCase().localeCompare(b.campaign_name.toLowerCase())
            }
        } else {
            return a.campaign_name.toLowerCase().localeCompare(b.campaign_name.toLowerCase())
        }
    })

    const updateSearch = (event) => {
        setSearchValue(event.target.value.substr(0, 20));
    };
    const handleCandidateOptions = (event) => {
        setFilterOptions({ ...filterOptions , [ event.target.name ]: event.target.value })
    }
    const handleSendToVote = (data) => {
        setCurrentCampData(data)
        setIsCultureFit(!isCultureFit)
    }
    const handleWithdrawalCandidateProfile = (data) => {
        const finalData = {
            candidate_id : data.candidate_id,
            client_id : data.client_id,
            campaign_id : data.campaign_id
        }
        dispatch(withdrawalCandidateProfileAction(finalData))
    }
    const handleSubmit = () => {
        const data = {
            candidate_id : candId,
            client_id : currentCampData.client_id,
            campaign_id : currentCampData.campaign_id,
            profile_status : 1,
            culture_fit : cultureFitRating.cultureFit || '3'
        }
        dispatch(sendCulturalFitRating(data))
        setIsCultureFit(!isCultureFit)
    }
    const handleChange = (event) => {
        setCultureFitRating({ [ event.target.name ]: event.target.value })
    }
    const handleColumnName = (data) => {
        setColumnName(data)
    }
    const onSort = (order, data) => {
        setSortType(order)
        setColumnName(data)
        if(data === 'campaigns') {
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
            setCampaignsArrowUp(!campaignsArrowUp)
        }
        if(data === 'company') {
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
            setCompanyArrowUp(!companyArrowUp)
        }
        if(data === 'tech') {
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
            setTechArrowUp(!techArrowUp)
        }
        if(data === 'ml') {
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
            setMlArrowUp(!mlArrowUp)
        }
        if(data === 'status') {
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setStatusArrowUp(!statusArrowUp)
        }
    }
    const onSortUp = (order, data) => {
        setSortType(order)
        if(columnName === 'campaigns' || data === 'campaigns') {
            setCampaignsArrowDown(!campaignsArrowDown)
            setCampaignsArrowUp(!campaignsArrowUp)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
        } else if(columnName === 'company' || data === 'company') {
            setCompanyArrowUp(!companyArrowUp)
            setCompanyArrowDown(!companyArrowDown)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
        } else if(columnName === 'tech' || data === 'tech') {
            setTechArrowUp(!techArrowUp)
            setTechArrowDown(!techArrowDown)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
        } else if(columnName === 'ml' || data === 'ml') {
            setMlArrowUp(!mlArrowUp)
            setMlArrowDown(!mlArrowDown)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
        } else if(columnName === 'status' || data === 'status') {
            setStatusArrowUp(!statusArrowUp)
            setStatusArrowDown(!statusArrowDown)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
        }
    }
    const onSortDown = (order) => {
        setSortType(order)
        if(columnName === 'company') {
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
        } else if(columnName === 'campaigns'){
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        } else if(columnName === 'tech') {
            setTechArrowUp(false)
            setTechArrowDown(false)
        } else if(columnName === 'ml') {
            setMlArrowUp(false)
            setMlArrowDown(false)
        }  else if(columnName === 'status') {
            setStatusArrowUp(false)
            setStatusArrowDown(false)
        }
    }
    return(
        <>
            <VoteHeader candName={ candName } type='Send to Vote' updateSearch={ updateSearch }
                searchValue={ searchValue } handleCandidateOptions={ handleCandidateOptions } />
            <table className="main-table table table-condensed github table-borderless">
                <thead>
                    <tr className="table-head">
                        <th scope="col" className="s_no" style={ { width: '2%' } }>#</th>
                        <td style={ { width: '2%' } }></td>
                        <th scope="col" style={ { width: '20%' } }>
                            Campaign name{' '}
                            <Sort
                                data= { 'campaigns' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { campaignsArrowUp }
                                sortArrowDown ={ campaignsArrowDown }
                                onSort={ onSort }
                                onSortUp={ onSortUp }
                                onSortDown={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '38%' } }>
                            Description
                        </th>
                        <th scope="col" style={ { width: '14%' } }>
                            Company{' '}
                            <Sort
                                data= { 'company' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { companyArrowUp }
                                sortArrowDown ={ companyArrowDown }
                                onSort={ onSort }
                                onSortUp={ onSortUp }
                                onSortDown={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '12%' } }>
                            Tech Stack{' '}
                            <Sort
                                data= { 'tech' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { techArrowUp }
                                sortArrowDown ={ techArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '5%' } }>
                            ML%{' '}
                            <Sort
                                data= { 'ml' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { mlArrowUp }
                                sortArrowDown ={ mlArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '5%' } }>
                            Status{' '}
                            <Sort
                                data= { 'status' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { statusArrowUp }
                                sortArrowDown ={ statusArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                    </tr>
                </thead>
                {isLoading && (
                    <div className="loader">
                        <Loading />
                    </div>
                )}
                <tbody>
                    {!isLoading && sorted && sorted.map((data, idx)=> {
                        return  <tr className="table-row" key={ idx }>
                            <th scope="row" className="s_no">&nbsp;&nbsp;{ idx + 1 }</th>
                            <td>
                                {data.is_active ? (
                                    <img
                                        src={ Play }
                                        alt="play"
                                        className="play-icon"
                                        onClick={ () => handleStatus(dispatch, data, 2, 0, clientId) }
                                    />
                                ) : (
                                    <img
                                        src={ Pause }
                                        alt="pause"
                                        className="pause-icon pause-img"
                                        onClick={ () => handleStatus(dispatch, data, 2, 1, clientId) }
                                    />
                                )}
                            </td>
                            <td><Link className="epicdetail" to={ {
                                pathname: '/storage',
                                state: {
                                    campId: data.campaign_id,
                                    campaign_name: data.campaign_name
                                }
                            } }>{data.campaign_name}</Link></td>
                            <td>{data.campaign_description}</td>
                            <td><Link className="epicdetail" to={ { pathname:'/epic-passport', state: { id: data.client_id } } } >{data.company_name}</Link></td>
                            <td>{data.job_title}</td>
                            <td>92%</td>
                            <td>
                                {
                                    data.profile_status === 0 ?
                                        <img src={ SendToVoteIcon } alt="SendToVoteIcon" className="send-to-vote pointer"  onClick={ () => handleSendToVote(data) }/>
                                        :
                                        data.profile_status === 6 ?
                                            <img
                                                src={ Bag }
                                                alt="Bag"
                                            />
                                            :
                                            <ReactSVG src={ Reload } alt="reload" className="reload" onClick={ () => handleWithdrawalCandidateProfile(data) } />
                                }
                            </td>
                        </tr>
                    }) }
                </tbody>
            </table>
            {isCultureFit && <CultureFitModal isCultureFit={ isCultureFit } handleSendToVote = { handleSendToVote } handleSubmit={ handleSubmit } handleChange={ handleChange }/>}
        </>
    )
}
SendToVote.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
}
export default SendToVote
