/* eslint-disable quotes */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Loading from 'shared/Loader';
import { useHistory } from 'react-router-dom';
import {  addTaskCandidate, deleteTaskCandidate,  getHiresCandidates,  getTaskCandidates } from 'actions/clientActions';

import { ReactSVG } from 'react-svg';
import  BackArrow from 'static/images/back.svg';
function TaskCandidates(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [ isEdit, ] = useState(false);

    const [ taskCandidates, setTaskCandidates ] = useState([]);
    const [ hiredCandidates, setHiredCandidates ] = useState([]);

    const taskId = props.history.location.state ? props.history.location.state.taskId : "";
    const projectId = props.history.location.state ? props.history.location.state.projectId : "";
    const milestoneId = props.history.location.state ? props.history.location.state.milestoneId : "";
    const clientProfileDetails = useSelector(
        (state) => state.clientProfile.client_profile
    );
    const clientCampDetails = useSelector(
        (state) => state.clientProfile.campaigns
    );
    const isLoading = useSelector((state) => state.applicationIsLoading);

    const getTaskCandidateList = () =>{
        dispatch(getTaskCandidates(taskId)).then((res) => {
            if (res.statusCode === 200) {
                setTaskCandidates(res.data.task_candidate_list);
            }
        });

        dispatch(getHiresCandidates(clientProfileDetails[ 0 ].id)).then((res) => {
            if (res.statusCode === 200) {
                setHiredCandidates(res.data.hires_candidates)
            }
        });
    }
    useEffect(() => {
        if(clientProfileDetails){
            getTaskCandidateList()

        }
    }, [ dispatch, taskId, clientProfileDetails ]);

    const campArray = [];
    clientProfileDetails &&
        clientProfileDetails.map((data, idx) => {
            clientCampDetails &&
                clientCampDetails.map((camp, idm) => {
                    if (data.id === camp.client_id) {
                        campArray.push(camp);
                    }
                });
        });

    const assignTaskCandidate = (candidate_id) => {
        const data = {
            project_id : projectId,
            milestone_id : milestoneId,
            candidate_id : candidate_id,
            task_id : taskId
        }
        dispatch(addTaskCandidate(data)).then((res) => {
            if (res.statusCode === 200) {
                getTaskCandidateList()
            }
        });
    }

    const removeTaskCandidate = async (milestone_id) => {
        const result = window.confirm('Are you sure you want to delete?');
        if(result){
            dispatch(deleteTaskCandidate(milestone_id)).then((res) => {
                getTaskCandidateList();
            });
        }

    }
    return (
        <>
            <>
                <nav className="navbar navbar-expand-lg navbar-dashboard">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to=""></Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 listclient">

                                <li className="nav-item back-arrow-box" onClick={ ()=>{
                                    history.goBack()
                                } }>
                                    <ReactSVG src={ BackArrow } className="back-arrow" />
                                </li>
                                <li className="nav-item back-arrow-box" style={ { color: "white" } }onClick={ ()=>{
                                    history.goBack()
                                } }>
                                    Task List
                                </li>

                            </ul>
                            <div className="port">
                                {clientProfileDetails &&
                clientProfileDetails[ 0 ] &&
                clientProfileDetails[ 0 ].company_name}
                            </div>

                        </div>
                    </div>
                </nav>
            </>

            {!isEdit && (
                <div className="main-div">
                    <table  className="main-table table table-condensed github table-borderless">
                        <thead style = { { width : "100%" } }>
                            <tr className="table-head">
                                <th scope="col" className="s_no">#</th>
                                <th scope="col" style={ { width: '10%' } }>
                                    Full Name <img src="Images/hiphen.svg" alt="" />
                                </th>

                                <th scope="col" style={ { width: '12%' } }>
                                    Tech Stack <img src="Images/hiphen.svg" alt="" />
                                </th>
                                <th scope="col" style={ { width: '12%' } }>
                                    Location <img src="Images/hiphen.svg" alt="" />
                                </th>
                                <th scope="col" style={ { width: '10%' } }>
                                    ML% <img src="Images/hiphen.svg" alt="" />
                                </th>

                                <th scope="col" style={ { width: '12%' } }>
                                    Engagement <img src="Images/hiphen.svg" alt="" />
                                </th>
                                <th scope="col" style={ { width: '30%' } }>
                                </th>
                            </tr>
                        </thead>
                        {isLoading && (
                            <div className="loader">
                                <Loading />
                            </div>
                        )}
                        <tbody>
                            {!isLoading &&
                                            hiredCandidates &&
                                            hiredCandidates.map((candidate, idx) => {
                                                const isAssigned = taskCandidates && taskCandidates.filter(can => can.candidate_id === candidate.candidate_id).length > 0;
                                                const cand = isAssigned ? taskCandidates.filter(can => can.candidate_id === candidate.candidate_id)[ 0 ] : null;
                                                return (
                                                    <tr key={ idx } className="table-row">
                                                        <th scope="row" className="s_no">{idx + 1}</th>
                                                        <td>{candidate.full_name}</td>
                                                        <td>{candidate.tech_stack}</td>

                                                        <td>{candidate.city},{candidate.state}</td>

                                                        <td>
                                                            -
                                                        </td>
                                                        <td>{candidate.project_count} Projects</td>
                                                        <td  >
                                                            <button type="submit" className="btn btn-secondary telenory closer" disabled={ isAssigned } style={ isAssigned ? {
                                                                "background": "#14b9b8",
                                                                "color": "#fff"
                                                            } : {} } onClick = { () =>{
                                                                assignTaskCandidate(candidate.candidate_id)
                                                            } } >
                                                                {taskCandidates &&   isAssigned ?  'Assigned': 'Available'}

                                                            </button>
                                                            {isAssigned &&
                                                            <button type="submit" className="btn btn-secondary telenory closer" data-dismiss="modal"
                                                                onClick = { () =>{
                                                                    removeTaskCandidate(cand.id)
                                                                } }  >
                                                                Unassign
                                                            </button>}
                                                        </td>

                                                        <td >

                                                        </td>
                                                    </tr>
                                                );
                                            })}
                        </tbody>
                    </table>

                </div>
            )}

        </>
    );
}
TaskCandidates.propTypes = {
    history: PropTypes.object,
};

export default TaskCandidates;
