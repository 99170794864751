export function clientsListing(state = [], action) {
    switch (action.type) {
    case 'FETCH_CLIENTS_LISTING':
        return action.clientsListing;
    default:
        return state;
    }
}
export function clientProfile(state = [], action) {
    switch (action.type) {
    case 'FETCH_CLIENT_PROFILE':
        return action.clientProfile;
    default:
        return state;
    }
}
export function hireCandidates(state = [], action) {
    switch (action.type) {
    case 'FETCH_HIRES_CANDIDATES':
        return action.hireCandidates;
    default:
        return state;
    }
}
export function hireActiveCandidates(state = [], action) {
    switch (action.type) {
    case 'FETCH_ACTIVE_HIRE_CANDIDATES':
        return action.hireActiveCandidates;
    default:
        return state;
    }
}
export function campaignCandidates(state = [], action) {
    switch (action.type) {
    case 'FETCH_CAMPAIGN_CANDIDATES':
        return action.campaignCandidates;
    default:
        return state;
    }
}
export function candidateActiveCampaigns(state = [], action) {
    switch (action.type) {
    case 'FETCH_CANDIDATES_ACTIVE_CAMPAIGNS':
        return action.candidateActiveCampaigns;
    default:
        return state;
    }
}
export function profileImage(state = '', action) {
    switch (action.type) {
    case 'FECTH_PROFILE_IMAGE':
        return action.profileImage;
    default:
        return state;
    }
}
export function isDecline(state = false, action) {
    switch (action.type) {
    case 'STORE_CLIENT_STATUS':
        return action.isDecline;
    default:
        return state;
    }
}