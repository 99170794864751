import React, { useState } from 'react';
import PropTypes from 'prop-types';

function ProjectNotes(props) {
    const {
        updateNotes,
        notesValue,
        handleCloseNotes
    } = props;
    const [ noteValue, setNoteValue ] = useState(notesValue)
    return (
        <table className="notes-table w-100">
            <tbody>
                <tr>
                    <td className="githu">
                        <div data-toggle="collapse" data-target="#demo2">
                            <div className="github3">
                                {' '}
                                <textarea
                                    id="editNotes"
                                    onChange={ (event) => setNoteValue(event.target.value) }
                                    name="notesValue"
                                    rows="10"
                                    cols="0"
                                    value = { noteValue }
                                >
                                </textarea>
                            </div>
                            <div className="zombe d-flex justify-content-center">
                                <button
                                    className="junkin"
                                    type="button"
                                    name="button"
                                    onClick={ (event) =>
                                        updateNotes(noteValue)
                                    }
                                >
                                    Save
                                </button>
                                <button
                                    className="junkin"
                                    type="button"
                                    name="button"
                                    onClick={ handleCloseNotes }
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
ProjectNotes.propTypes = {
    clientList: PropTypes.array,
    handleEditNotes: PropTypes.func,
    handleOpenNotes: PropTypes.func,
    handleCloseNotes: PropTypes.func,
    updateNotes: PropTypes.func,
    notesValue: PropTypes.string,
};
export default ProjectNotes;
