import React from 'react'
import Button from 'shared/Button'
import LogoSection from './LogoSection'
import PropTypes from 'prop-types';

function ForgotPassword(props) {
    const { handleChange, inputField, inputFieldError, handleSubmit } = props
    return (
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="row no-gutters d-flex flex-column card">
                <div className="card-body">
                    <LogoSection isLogin = { false } title = 'Please enter your mobile numer.' />
                    <form className="login-form">
                        <div className="form-group field_cont">
                            <label htmlFor="email" className="form-label">
                                Mobile Number
                            </label>
                            <input
                                type="text"
                                name="phone_number"
                                id="phone_number"
                                autoComplete="off"
                                placeholder="Enter your mobile number"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.phone_number || '' }
                            />
                            {inputFieldError.phone_number && (
                                <span className="error_msg">{inputFieldError.phone_number}</span>
                            )}
                        </div>
                        <Button
                            handleSubmit={ handleSubmit }
                            buttonName="Continue"
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}
ForgotPassword.propTypes = {
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    inputField: PropTypes.object,
    inputFieldError: PropTypes.object,
};

export default ForgotPassword
