/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Sort from 'shared/Sorting';
import { AiOutlineFileSearch } from 'react-icons/ai';
import Play from 'static/images/play.svg';
import sideArrow from 'static/images/left.svg';
import Pause from 'static/images/pause.svg';
import ResultModal from './ResultModal';
import { handleStatus } from 'utils/Helper';
import { sendInterviewFeedback } from 'actions/candidateActions';
import CrossIcon from 'static/images/cross-black.svg';
import RightIcon from 'static/images/right.svg';
import Loading from 'shared/Loader';
import Rating from 'shared/Rating';
import Github from 'static/images/github-img.png';
import Portfolio from 'static/images/portfolio-img.png';
import Linkedin from 'static/images/linkedin-img.png';
import { Link } from 'react-router-dom';
import Trash from 'static/images/trash.svg';
import EditCandidate from './EditCandidate';
import AvatarComp from 'shared/AvatarComp';
import { getActiveHireCandidates, getCampaignCandidates } from 'actions/clientActions';

function UpVoteCampaign(props) {
    const {
        campaignCandidates,
        cityCountryFormatter,
        dispatch,
        handleOpenNotes,
        isEdit,
        educationArray, handleInputChange, handleAddInput, handleRemoveInput, handleHobbiesChange, handleSkillsChange, handleCandidateStatus,
        removeCandidateFunc,
        uploadProfilePicture,
        InputChange,
        inputField,
        handleToggleTable,
        toggleArrowIndex, handleDeleteModal, uploadResumeHandler
    } = props;
    const campaign_Id = localStorage.getItem('campaignId');
    const [ isOpenResultModal, setIsOpenResultModal ] = useState(false);
    const [ sortType, setSortType ] = useState('');
    const [ columnName, setColumnName ] = useState('');
    const [ fullNameArrowUp, setFullNameArrowUp ] = useState(false);
    const [ fullNameArrowDown, setFullNameArrowDown ] = useState(false);
    const [ techArrowUp, setTechArrowUp ] = useState(false);
    const [ techArrowDown, setTechArrowDown ] = useState(false);
    const [ mlArrowUp, setMlArrowUp ] = useState(false);
    const [ mlArrowDown, setMlArrowDown ] = useState(false);
    const [ campaignsArrowUp, setCampaignsArrowUp ] = useState(false);
    const [ campaignsArrowDown, setCampaignsArrowDown ] = useState(false);
    const [ locationArrowUp, setLocationArrowUp ] = useState(false);
    const [ locationArrowDown, setLocationArrowDown ] = useState(false);
    const [ isCandidateProfileStatusId, setIsCandidateProfileStatusId ] = useState('');
    const isLoading = useSelector((state) => state.applicationIsLoading);
    const active_tab = localStorage.getItem('activeTab');

    const handleResultModal = (id) => {
        setIsCandidateProfileStatusId(id);
        setIsOpenResultModal(!isOpenResultModal);
    };
    const handleDecision = (value) => {
        const data = {
            candidate_profile_status_id: isCandidateProfileStatusId,
            is_interview_result: value === 1 ? 1 : 2,
            is_hired: 0,
            is_rejected: 0
        };
        dispatch(sendInterviewFeedback(data)).then((res) => {
            if (res.statusCode === 200) {
                dispatch(getActiveHireCandidates(campaign_Id));
                if(active_tab === null || active_tab === "2"){
                    dispatch(getCampaignCandidates(campaign_Id, 0));
                } else {
                    dispatch(getCampaignCandidates(campaign_Id, 1))
                }
            }
        });
        handleResultModal(isCandidateProfileStatusId);
    };
    const plusIconHandler = (profileStatus) => {
        const data = {
            candidate_profile_status_id: profileStatus,
            is_interview_result: 1,
            is_hired: 1,
            is_rejected: 0
        };
        dispatch(sendInterviewFeedback(data)).then((res) => {
            if (res.statusCode === 200) {
                dispatch(getActiveHireCandidates(campaign_Id));
                if(active_tab === null || active_tab === "2"){
                    dispatch(getCampaignCandidates(campaign_Id, 0));
                } else {
                    dispatch(getCampaignCandidates(campaign_Id, 1))
                }
            }
        });
    }
    const crossIconHandler = (status) => {
        const data = {
            candidate_profile_status_id: status,
            is_interview_result: 2,
            is_hired: 0,
            is_rejected: 1
        }
        dispatch(sendInterviewFeedback(data)).then((res) => {
            if (res.statusCode === 200) {
                dispatch(getActiveHireCandidates(campaign_Id));
                if(active_tab === null || active_tab === "2"){
                    dispatch(getCampaignCandidates(campaign_Id, 0));
                } else {
                    dispatch(getCampaignCandidates(campaign_Id, 1))
                }
            }
        });
    }
    const sorted = campaignCandidates && campaignCandidates.sort((a, b) => {
        const isReversed = !sortType ? '' :  (sortType === 'asc') ? 1 : -1
        if(columnName === 'full-name'){
            return isReversed*a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
        }
        else if(columnName === 'tech'){
            return (isReversed*a.tech_stack.toLowerCase().localeCompare(b.tech_stack.toLowerCase()))
        }
        else if(columnName === 'campaigns'){
            return ((isReversed*a.total_campaigns) - (b.total_campaigns))
        }
        else if(columnName === 'location'){
            return (isReversed*a.city.toLowerCase().localeCompare(b.city.toLowerCase()))
        }
        else if(columnName === 'ml'){
            return isReversed*a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
        }
        else {
            return a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
        }
    })
    const handleColumnName = (data) => {
        setColumnName(data)
    }
    const onSort = (order, data) => {
        setSortType(order)
        setColumnName(data)
        if(data === 'full-name') {
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setFullNameArrowUp(!fullNameArrowUp)
        }
        if(data === 'tech') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setTechArrowUp(!techArrowUp)
        }
        if(data === 'ml') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setMlArrowUp(!mlArrowUp)
        }
        if(data === 'campaigns') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setCampaignsArrowUp(!campaignsArrowUp)
        }
        if(data === 'location') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(!locationArrowUp)
        }
    }
    const onSortUp = (order, data) => {
        setSortType(order)
        if(columnName === 'full-name' || data === 'full-name') {
            setFullNameArrowUp(!fullNameArrowUp)
            setFullNameArrowDown(!fullNameArrowDown)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
        } else if(columnName === 'tech' || data === 'tech') {
            setTechArrowUp(!techArrowUp)
            setTechArrowDown(!techArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
        } else if(columnName === 'ml' || data === 'ml') {
            setMlArrowUp(!mlArrowUp)
            setMlArrowDown(!mlArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
        } else if(columnName === 'campaigns' || data === 'campaigns') {
            setCampaignsArrowDown(!campaignsArrowDown)
            setCampaignsArrowUp(!campaignsArrowUp)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
        } else if(columnName === 'location' || data === 'location') {
            setLocationArrowUp(!locationArrowUp)
            setLocationArrowDown(!locationArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        }
    }
    const onSortDown = (order) => {
        setSortType(order)
        if(columnName === 'full-name') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
        } else if(columnName === 'tech') {
            setTechArrowUp(false)
            setTechArrowDown(false)
        } else if(columnName === 'ml') {
            setMlArrowUp(false)
            setMlArrowDown(false)
        } else if(columnName === 'campaigns'){
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        } else if(columnName === 'location'){
            setLocationArrowUp(false)
            setLocationArrowDown(false)
        }
    }
    return (
        <>
            <table className="table table-condensed github table-borderless" id="accordion3">
                <thead>
                    <tr className=" table-head">
                        <th scope="col"></th>
                        <th scope="col" className="s_no">#</th>
                        <th scope="col"></th>
                        <th scope="col" style={ { width: '18%' } }>
                            Full Name{' '}
                            <Sort
                                data= { 'full-name' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { fullNameArrowUp }
                                sortArrowDown ={ fullNameArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '16%' } }>
                            Tech Stack{' '}
                            <Sort
                                data= { 'tech' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { techArrowUp }
                                sortArrowDown ={ techArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '16%' } }>
                            Location{' '}
                            <Sort
                                data= { 'location' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { locationArrowUp }
                                sortArrowDown ={ locationArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '9%' } }>
                            ML%{' '}
                            <Sort
                                data= { 'ml' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { mlArrowUp }
                                sortArrowDown ={ mlArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '14%' } }>
                            Campaigns{' '}
                            <Sort
                                data= { 'campaigns' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { campaignsArrowUp }
                                sortArrowDown ={ campaignsArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '10%' } }></th>
                    </tr>
                </thead>
                {isLoading && (
                    <div className="loader-right">
                        <Loading />
                    </div>
                )}
                <tbody>
                    {!isLoading &&
            sorted &&
            sorted.map((data, idm) => {
                return (
                    <>
                        <tr
                            key={ idm+12 }
                            href={ `#togglevoteup${ idm }` }
                            data-bs-toggle="collapse"
                            className="accordion-toggle table-row storage-row"
                            onClick={ () => { handleToggleTable(data.candidate_profile_status_id, data) } }
                        >
                            <td>
                                {
                                    data.is_interview_result === 1 ?
                                        (
                                            <div
                                                className="minusBox plusBox plus-ico pointer"
                                                alt='plus'
                                                onClick={ () => plusIconHandler(data.candidate_profile_status_id) }
                                            />
                                        )
                                        :
                                        data.is_interview_result === 2 ?
                                            (
                                                <div
                                                    className="right-outline-icon pointer"
                                                >
                                                    <img src={ RightIcon } alt="right-icon" className='right-icon'
                                                    onClick={ () => handleResultModal(data.candidate_profile_status_id) } />
                                                </div>
                                            )
                                            :
                                            <div
                                                className="right-outline-icon pointer"
                                                onClick={ () => handleResultModal(data.candidate_profile_status_id)
                                            }>
                                                <img src={ RightIcon } alt="right-icon" className='right-icon' />
                                            </div>
                                }
                            </td>
                            <th scope="row" className="s_no">{idm + 1}</th>
                            <td>
                                {data.is_active ? (
                                    <img
                                        src={ Play }
                                        alt="play"
                                        className="play-icon"
                                        onClick={ () => handleStatus(dispatch, data, 1, 0) }
                                    />
                                ) : (
                                    <img
                                        src={ Pause }
                                        alt="pause"
                                        className="pause-icon pause-img"
                                        onClick={ () => handleStatus(dispatch, data, 1, 1) }
                                    />
                                )}
                            </td>
                            <td>{data.full_name}</td>
                            <td>{data.tech_stack} </td>
                            <td>{cityCountryFormatter(data)}</td>
                            <td>
                                <div className='d-flex align-items-center justify-content-center'>
                                    {data.assessment_score}%
                                </div>
                            </td>
                            <td>
                                <div className="storage-center-text d-flex align-items-center justify-content-center">
                                    <u > { data.total_active_campaigns ? data.total_active_campaigns : 0 }/<b>{ data.total_campaigns ? data.total_campaigns : 0 }</b></u>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center justify-content-end'>
                                    {
                                        data.is_interview_result === 2 ?
                                            (<img src={ CrossIcon } alt="CrossIcon" className='cursor' onClick={ () => crossIconHandler(data.candidate_profile_status_id) } />)
                                            :
                                            ''
                                    }
                                    {isEdit && (
                                        <img
                                            onClick={ () => handleDeleteModal(data, 'candidate') }
                                            src={ Trash }
                                            alt="trash"
                                            className='cursor'
                                            style={ { margin: '0 0 0 8px' } }
                                        />
                                    )}
                                    {!(toggleArrowIndex === data.candidate_profile_status_id) ? (
                                        <img
                                            src={ sideArrow }
                                            alt="side-arrow"
                                            className="down"
                                            style={ { margin: '0 8px 0 8px' } }
                                        />
                                    ) : (
                                        <img
                                            src={ sideArrow }
                                            onClick={ () => { handleToggleTable(data.candidate_profile_status_id, data) } }
                                            className="down-arrow"
                                            alt="side-arrow"
                                            style={ { margin: '0 8px 0 8px' } }
                                        />
                                    )}
                                </div>
                            </td>
                        </tr>
                            {
                                !isEdit && (
                                    <tr className="collapse-row dynamicrow" key={ idm+14 } data-bs-parent="#myTable3">
                                    <td colSpan="12" className="hiddenRow">
                                        <div
                                            className="accordian-body collapse"
                                            id={ `togglevoteup${ idm }` }
                                            data-bs-parent="#accordion3"
                                        >
                                            <div className="emergency">
                                                <div className="extend row">
                                                    <div className=" text-center col-lg-3 col-md-3 col-sm-12">
                                                        <AvatarComp
                                                            image={ data.avatar }
                                                            name={ data.full_name }
                                                        />
                                                        <br />
                                                    </div>
                                                    <div className="storage-clicked col-lg-4 col-md-4 col-sm-12">
                                                        <table>
                                                            <tr>
                                                                <td className="storage-title">Name</td>
                                                                <td className="storage-sub-title">
                                                                    {data.first_name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="storage-title">Surname</td>
                                                                <td className="storage-sub-title">
                                                                    {data.last_name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="storage-title">Location</td>
                                                                <td className="storage-sub-title">
                                                                    {cityCountryFormatter(data)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="storage-title">Culture Fit</td>
                                                                <td className="storage-sub-title">
                                                                    <Rating rate ={ data.culture_fit } />
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="storage-clicked col-lg-4 col-md-4 col-sm-12">
                                                        <table>
                                                            <tr>
                                                                <td className="storage-title">Tech Stack</td>
                                                                <td className="storage-sub-title">
                                                                    {data.tech_stack}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="storage-title">Experience</td>
                                                                <td className="storage-sub-title">
                                                                    {data.experience} years
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="storage-title">Assessment</td>
                                                                <td className="storage-sub-title">
                                                                    {data.assessment_score}%
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a
                                                                        href={ data.resume }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        className="resume-link resume-btn-link">
                                                                        <AiOutlineFileSearch className="resume-icon" />
                                                                        View Resume
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="storage-clicked col-lg-1 col-md-1 col-sm-12">
                                                        <div className='d-flex flex-column social-links'>
                                                            {
                                                                (data.github_url !== null && data.github_url !== '') ?
                                                                    (
                                                                        <Link to={ { pathname: data.github_url } }  target="_blank" >
                                                                            <img src={ Github } alt='git-img' width='20' />
                                                                        </Link>
                                                                    )
                                                                    :
                                                                    (
                                                                        <img src={ Github } alt='git-img' className='fade-link' width='20' />
                                                                    )
                                                            }
                                                            {
                                                                (data.linkedin_url !== null && data.linkedin_url !== '') ?
                                                                    (
                                                                        <Link to={ { pathname: data.linkedin_url } }  target="_blank" >
                                                                            <img src={ Linkedin } alt='linkedin-img' width='20' />
                                                                        </Link>
                                                                    )
                                                                    :
                                                                    (
                                                                        <img src={ Linkedin } alt='linkedin-img' className='fade-link' width='20' />
                                                                    )
                                                            }
                                                            {
                                                                (data.portfolio_url !== null && data.portfolio_url !== '') ?
                                                                    (
                                                                        <Link to={ { pathname: data.portfolio_url } }  target="_blank" >
                                                                            <img src={ Portfolio } alt='portfolio-img' width='20' />
                                                                        </Link>
                                                                    )
                                                                    :
                                                                    (
                                                                        <img src={ Portfolio } alt='linkedin-img' className='fade-link' width='20' />
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dataclickp pt-4">
                                                    <div className="notesdata datamine">
                                                        <b>Notes</b>
                                                        <form>
                                                            <textarea
                                                                className="w-100"
                                                                id="w3review"
                                                                onClick={ handleOpenNotes }
                                                                name="w3review"
                                                                rows="4"
                                                                cols="0"
                                                                placeholder='Add your notes'
                                                                defaultValue={ data.notes }
                                                            ></textarea>
                                                        </form>
                                                    </div>
                                                    <div className="professor pt-2">
                                                        <h4>Education</h4>
                                                        <ul className="oxford">
                                                            {data.educations.map((education, idr) => {
                                                                return (
                                                                    <>
                                                                        <li className="jobe" key={ idr }>
                                                                            {education.university}
                                                                        </li>
                                                                        <li className="computate">
                                                                            {`${ education.specialization }, ${ education.course }`}
                                                                        </li>
                                                                    </>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="jbondz5 oxford col-lg-6 col-md-12">
                                                            <h4>Skills</h4>
                                                            <ul className="jbond2">
                                                                {data.skills.map((skill, idn) => {
                                                                    return (
                                                                        <li className="jobe" key={ idn }>
                                                                            -{skill.skill_name}
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                        <div className="bondz oxford2  col-lg-6 col-md-12">
                                                            <h4>Hobbies</h4>
                                                            <ul className="jbond1">
                                                                {data.hobbies.map((hobby, idq) => {
                                                                    return (
                                                                        <li className="jobe" key={ idq }>
                                                                            -{hobby.hobby_name}
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    </tr>
                                )
                            }
                            {
                                isEdit && (
                                    <tr className="collapse-row dynamicrow" key={ idm+16 } data-bs-parent="#myTable3">
                                    <td colSpan="12" className="hiddenRow">
                                        <div
                                            className="accordian-body collapse"
                                            id={ `togglevoteup${ idm }` }
                                            data-bs-parent="#accordion3"
                                        >
                                            <EditCandidate
                                                data = { data }
                                                educationArray={ educationArray }
                                                handleInputChange= { handleInputChange }
                                                handleAddInput= { handleAddInput }
                                                handleRemoveInput = { handleRemoveInput }
                                                rowIndex={ idm }
                                                handleSkillsChange = { handleSkillsChange }
                                                handleHobbiesChange = { handleHobbiesChange }
                                                handleCandidateStatus={ handleCandidateStatus }
                                                removeCandidateFunc={ removeCandidateFunc }
                                                uploadProfilePicture={ uploadProfilePicture }
                                                handleChange={ InputChange }
                                                inputField={ inputField }
                                                uploadResumeHandler={ uploadResumeHandler }
                                            />
                                        </div>
                                    </td>
                                    </tr>
                                )
                            }
                    </>
                );
            })}
                </tbody>
            </table>
            {isOpenResultModal && (
                <ResultModal
                    isOpenResultModal={ isOpenResultModal }
                    handleDecision={ handleDecision }
                />
            )}
        </>
    );
}
UpVoteCampaign.propTypes = {
    history: PropTypes.object,
    campaignCandidates: PropTypes.array,
    handleOpenNotes: PropTypes.func,
    dispatch: PropTypes.func,
    fullNameFormatter: PropTypes.func,
    cityCountryFormatter: PropTypes.func,
    isEdit: PropTypes.bool,
    educationArray: PropTypes.array,
    handleInputChange: PropTypes.func,
    handleAddInput: PropTypes.func,
    handleRemoveInput: PropTypes.func,
    handleSkillsChange: PropTypes.func,
    handleHobbiesChange: PropTypes.func,
    handleCandidateStatus: PropTypes.func,
    removeCandidateFunc: PropTypes.func,
    uploadProfilePicture: PropTypes.func,
    InputChange: PropTypes.func,
    inputField: PropTypes.array,
    handleToggleTable: PropTypes.func,
    toggleArrowIndex: PropTypes.string,
    handleDeleteModal: PropTypes.func,
    uploadResumeHandler: PropTypes.func
};

export default UpVoteCampaign;
