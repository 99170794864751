import React  from 'react';
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';
import { fullNameFormatter, stateName } from 'shared/Constant';
import EditHobbieBody from './EditHobbieBody';
import EditSkillBody from './EditSkillBody';
import Trash from 'static/images/trash.svg';
import Github from 'static/images/github-img.png';
import Portfolio from 'static/images/portfolio-img.png';
import Linkedin from 'static/images/linkedin-img.png';
import WhiteBin from 'static/images/white-bin.svg';
import Play from 'static/images/play.svg';
import Pause from 'static/images/pause.svg';
import { useSelector } from 'react-redux';
function EditCandidateFormBody(props) {
    const {
        removeCandidateFunc,
        handleChange,
        inputField,
        uploadProfilePicture,
        isAddNewCandidate,
        handleCloseCandidate,
        addNewCandidateFunc,
        handleSkillsChange,
        handleHobbiesChange,
        handleCandidateStatus,
        rowIndex,
        educationArray,
        handleInputChange,
        handleAddInput,
        handleRemoveInput,
        uploadResumeHandler,
    } = props;
    const isSpinnerLoading = useSelector((state) => state.spinnerIsLoading);
    const state = stateName.map((data, id) => {
        return (
            <option key={ id } value={ data } name="state">
                {data}
            </option>
        );
    });

    return (
        <div className="edit-candidate-main extendedrev">
            <div className="edit-candidate-extendedrev">
                <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 d-md-flex flex-wrap edit-candidate-wrap">
                    <div
                        className=" col-lg-2 col-md-2
                       col-sm-12 col-xs-12 d-flex"
                    >
                        <div className="rectangle text-center edit-avatar">
                            <Avatar
                                size="100"
                                square={ true }
                                name={ fullNameFormatter(inputField) }
                                src={ inputField.avatar }
                            />
                            { isSpinnerLoading && (
                                <div className="spinner-grow spinner-grow-sm" role="status" style={ { position: 'absolute', margin: '48px 0px 0px -58px' } }>
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            )}
                            <input
                                id="avatar"
                                className="edit-input"
                                name="avatar"
                                type="file"
                                accept="image/*"
                                onChange={ (event) => uploadProfilePicture(event, inputField) }
                            />
                            <br />
                            {inputField.is_active ? (
                                <img
                                    src={ Play }
                                    alt="play"
                                    className="play-icon"
                                    onClick={ () =>
                                        handleCandidateStatus(inputField, 1, 0)
                                    }
                                />
                            ) : (
                                <img
                                    src={ Pause }
                                    alt="pause"
                                    className="pause-icon pause-img"
                                    onClick={ () =>
                                        handleCandidateStatus(inputField, 1, 1)
                                    }
                                />
                            )}
                        </div>
                        <div className="deletebin">
                            <img
                                src={ Trash }
                                alt="trash"
                                onClick={ () => removeCandidateFunc(inputField, 'profile') }
                            />
                        </div>
                    </div>
                    <div className="linkedin valuelabs col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <form>
                            <div className="form-group d-flex">
                                <label
                                    htmlFor="inputPassword"
                                    className="col-form-label edit-candidate-label col-lg-3 col-md-3
                                       col-sm-3"
                                >
                                    <b>First Name</b>
                                </label>
                                <div>
                                    <input
                                        type="text"
                                        className="form-control custodian"
                                        id="first_name"
                                        name="first_name"
                                        autoComplete='off'
                                        value={ inputField.first_name || '' }
                                        onChange={ handleChange }
                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mt-2">
                                <label
                                    htmlFor="inputPassword"
                                    className="col-form-label edit-candidate-label col-lg-3 col-md-3
                                       col-sm-3"
                                >
                                    <b>Surname</b>
                                </label>
                                <div className="">
                                    <input
                                        type="text"
                                        className="form-control custodian"
                                        id="last_name"
                                        name="last_name"
                                        autoComplete='off'
                                        value={ inputField.last_name || '' }
                                        onChange={ handleChange }
                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mt-2">
                                <label
                                    htmlFor="inputPassword"
                                    className="col-form-label edit-candidate-label col-lg-3 col-md-3
                                       col-sm-3"
                                >
                                    <b>Email</b>
                                </label>
                                <div className="">
                                    <input
                                        type="email"
                                        className="form-control custodian"
                                        id="email_id"
                                        autoComplete='off'
                                        name="email_id"
                                        value={ inputField.email_id || '' }
                                        onChange={ handleChange }
                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mt-2">
                                <label
                                    htmlFor="inputPassword"
                                    className="col-form-label edit-candidate-label d-flex col-lg-3 col-md-3
                                       col-sm-3"
                                >
                                    <b>Location</b>
                                </label>
                                <div className="d-flex">
                                    <div>
                                        <input
                                            type="text"
                                            className="form-control multi-select"
                                            id="city"
                                            name="city"
                                            autoComplete='off'
                                            value={ inputField.city || '' }
                                            onChange={ handleChange }
                                        />
                                    </div>
                                    <select
                                        className="custom-select"
                                        id="state"
                                        name="state"
                                        onChange={ handleChange }
                                    >
                                        {
                                            (isAddNewCandidate && inputField.full_name === 'New Candidate') ?
                                                <option defaultValue={ 'select' }>select</option> :
                                                (isAddNewCandidate && inputField.full_name !== 'New Candidate') ?
                                                    <option defaultValue={ inputField.state }>{inputField.state}</option> :
                                                    !isAddNewCandidate ?
                                                        <option defaultValue={ inputField.state }>{inputField.state}</option> :
                                                        <option defaultValue={ 'select' }>select</option>

                                        }
                                        {state}
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="stack col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <form>
                            <div className="form-group d-flex">
                                <label
                                    htmlFor="inputPassword"
                                    className=" col-form-label edit-candidate-label col-lg-4 col-md-3
                                       col-sm-3"
                                >
                                    <b>Tech Stack</b>
                                </label>
                                <div className="">
                                    <input
                                        type="text"
                                        className="form-control custodian"
                                        id="tech_stack"
                                        name="tech_stack"
                                        autoComplete='off'
                                        value={ inputField.tech_stack || '' }
                                        onChange={ handleChange }
                                    />
                                </div>
                            </div>

                            <div className="form-group  d-flex mt-2">
                                <label
                                    htmlFor="inputPassword"
                                    className="col-form-label edit-candidate-label col-lg-4 col-md-3
                                       col-sm-3"
                                >
                                    <b>Experience</b>
                                </label>
                                <div className="input-group">
                                    <div className="input-group-prepend"></div>
                                    <select
                                        className="custom-select explore"
                                        id="inputGroupSelect01"
                                        name="experience"
                                        onChange={ handleChange }
                                    >
                                        Years
                                        {
                                            (isAddNewCandidate && inputField.full_name === 'New Candidate') ?
                                                <option defaultValue={ 'select' }>select</option> :
                                                (isAddNewCandidate && inputField.full_name !== 'New Candidate') ?
                                                    <option defaultValue={ inputField.experience }>{inputField.experience}</option> :
                                                    !isAddNewCandidate ?
                                                        <option defaultValue={ inputField.experience }>{inputField.experience}</option> :
                                                        <option defaultValue={ inputField.experience }>{inputField.experience}</option>

                                        }
                                        {inputField.number}
                                        <option name="experience" value="1">
                                            1
                                        </option>
                                        <option name="experience" value="2">
                                            2
                                        </option>
                                        <option name="experience" value="3">
                                            3
                                        </option>
                                        <option name="experience" value="4">
                                            4
                                        </option>
                                        <option name="experience" value="5">
                                            5
                                        </option>
                                    </select>
                                    <div className="years">years</div>
                                </div>
                            </div>

                            <div className="form-group  d-flex mt-2">
                                <label
                                    htmlFor="inputPassword"
                                    className="col-form-label edit-candidate-label col-lg-4 col-md-3
                                       col-sm-3"
                                >
                                    <b>Assessment</b>
                                </label>
                                <div className=" d-flex">
                                    <input
                                        type="text"
                                        className="form-control custodian w-25"
                                        id="assessment_score"
                                        name="assessment_score"
                                        autoComplete='off'
                                        value={ inputField.assessment_score || '' }
                                        onChange={ handleChange }
                                    />
                                    <h6 className="years">%</h6>
                                </div>
                            </div>
                            <div className="form-group d-flex mt-2">
                                <label
                                    htmlFor="inputPassword"
                                    className="col-form-label edit-candidate-label col-lg-3 col-md-3
                                       col-sm-3"
                                >
                                    <b>Phone</b>
                                </label>
                                <div className="">
                                    <input
                                        type="number"
                                        className="form-control custodian"
                                        id="phone_number"
                                        name="phone_number"
                                        autoComplete='off'
                                        value={ inputField.phone_number || '' }
                                        onChange={ handleChange }
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="skills col-lg-4 col-md-8 col-sm-12">
                        <h4 className="skill-title">
                            <b>Skills</b>
                        </h4>
                        <ul className="skill1">
                            {!isAddNewCandidate ? (
                                <>
                                    <EditSkillBody
                                        handleSkillsChange={ handleSkillsChange }
                                        skill={ inputField.skills && inputField.skills[ 0 ] && inputField.skills[ 0 ].skill_name }
                                        index={ 0 }
                                        rowIndex = { rowIndex }
                                    />
                                    <EditSkillBody
                                        handleSkillsChange={ handleSkillsChange }
                                        skill={ inputField.skills && inputField.skills[ 1 ] && inputField.skills[ 1 ].skill_name }
                                        index={ 1 }
                                        rowIndex = { rowIndex }
                                    />
                                    <EditSkillBody
                                        handleSkillsChange={ handleSkillsChange }
                                        skill={ inputField.skills && inputField.skills[ 2 ] && inputField.skills[ 2 ].skill_name }
                                        index={ 2 }
                                        rowIndex = { rowIndex }
                                    />
                                    <EditSkillBody
                                        handleSkillsChange={ handleSkillsChange }
                                        skill={ inputField.skills && inputField.skills[ 3 ] && inputField.skills[ 3 ].skill_name }
                                        index={ 3 }
                                        rowIndex = { rowIndex }
                                    />
                                </>
                            ) : (
                                <>
                                    <EditSkillBody
                                        handleSkillsChange={ handleSkillsChange }
                                        index={ 0 }
                                        rowIndex = { rowIndex }
                                    />
                                    <EditSkillBody
                                        handleSkillsChange={ handleSkillsChange }
                                        index={ 1 }
                                        rowIndex = { rowIndex  }
                                    />
                                    <EditSkillBody
                                        handleSkillsChange={ handleSkillsChange }
                                        index={ 2 }
                                        rowIndex = { rowIndex }
                                    />
                                    <EditSkillBody
                                        handleSkillsChange={ handleSkillsChange }
                                        index={ 3 }
                                        rowIndex = { rowIndex  }
                                    />
                                </>
                            )}
                        </ul>
                    </div>

                    <div className="skills-hobby col-lg-6 col-md-8 col-sm-12">
                        <h4 className="hobbie-title">Hobbies</h4>
                        <ul className="hobbie1">
                            {!isAddNewCandidate ? (
                                <>
                                    <EditHobbieBody
                                        handleHobbiesChange={ handleHobbiesChange }
                                        index={ 0 }
                                        hobby= { inputField.hobbies && inputField.hobbies[ 0 ] &&  inputField.hobbies[ 0 ].hobby_name }
                                        rowIndex = { rowIndex }
                                    />
                                    <EditHobbieBody
                                        handleHobbiesChange={ handleHobbiesChange }
                                        index={ 1 }
                                        hobby= { inputField.hobbies && inputField.hobbies[ 1 ] &&  inputField.hobbies[ 1 ].hobby_name }
                                        rowIndex = { rowIndex }
                                    />
                                    <EditHobbieBody
                                        handleHobbiesChange={ handleHobbiesChange }
                                        index={ 2 }
                                        hobby= { inputField.hobbies && inputField.hobbies[ 2 ] &&  inputField.hobbies[ 2 ].hobby_name }
                                        rowIndex = { rowIndex }
                                    />
                                </>
                            ) : (
                                <>
                                    <EditHobbieBody
                                        handleHobbiesChange={ handleHobbiesChange }
                                        index={ 0 }
                                    />
                                    <EditHobbieBody
                                        handleHobbiesChange={ handleHobbiesChange }
                                        index={ 1 }
                                    />
                                    <EditHobbieBody
                                        handleHobbiesChange={ handleHobbiesChange }
                                        index={ 2 }
                                    />
                                </>
                            )}
                        </ul>
                    </div>
                    {
                        inputField.full_name !== 'New Candidate' ? (
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <form>
                                    <div className="social-media-section">
                                        <h4 className="edit-candidate-label mb-2">
                                            <b>Additional Links</b>
                                        </h4>
                                        <div className='d-flex align-items-center my-3'>
                                            <img src={ Github } alt="Github-img" className='link-icon' width="20" />
                                            <div className="link-field">
                                                <input
                                                    type="text"
                                                    className="form-control custodian"
                                                    id="github_url"
                                                    name="github_url"
                                                    autoComplete='off'
                                                    value={ inputField.github_url || '' }
                                                    onChange={ handleChange }
                                                />
                                            </div>
                                            { inputField && inputField.github_url && inputField.github_url.length > 0 ?
                                                (
                                                    <label className="container-checkbox">
                                                        <input type="checkbox" checked="checked" disabled/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                )
                                                :
                                                (
                                                    <label className="container-checkbox">
                                                        <input type="checkbox" disabled />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                )
                                            }
                                        </div>
                                        <div className='d-flex align-items-center my-3'>
                                            <img src={ Linkedin } alt="Linkedin-img" className='link-icon' width="20" />
                                            <div className="link-field">
                                                <input
                                                    type="text"
                                                    className="form-control custodian"
                                                    id="linkedin_url"
                                                    name="linkedin_url"
                                                    autoComplete='off'
                                                    value={ inputField.linkedin_url || '' }
                                                    onChange={ handleChange }
                                                />
                                            </div>
                                            { inputField && inputField.linkedin_url && inputField.linkedin_url.length > 0 ?
                                                (
                                                    <label className="container-checkbox">
                                                        <input type="checkbox" checked="checked" disabled/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                )
                                                :
                                                (
                                                    <label className="container-checkbox">
                                                        <input type="checkbox" disabled />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                )
                                            }
                                        </div>
                                        <div className='d-flex align-items-center mt-3'>
                                            <img src={ Portfolio } alt="Portfolio-img" className='link-icon' width="20" />
                                            <div className="link-field">
                                                <input
                                                    type="text"
                                                    className="form-control custodian"
                                                    id="portfolio_url"
                                                    name="portfolio_url"
                                                    autoComplete='off'
                                                    value={ inputField.portfolio_url || '' }
                                                    onChange={ handleChange }
                                                />
                                            </div>
                                            { inputField && inputField.portfolio_url && inputField.portfolio_url.length > 0 ?
                                                (
                                                    <label className="container-checkbox">
                                                        <input type="checkbox" checked="checked" disabled/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                )
                                                :
                                                (
                                                    <label className="container-checkbox">
                                                        <input type="checkbox" disabled />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                )
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        ) : ''
                    }
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                    <form className="education-section">
                        <h4 className="edit-candidate-label">
                            <b>Education</b>
                        </h4>

                        { inputField && inputField.educations && inputField.educations.map((item, i) => {
                            return(
                                <div className="qualify" key={ i }>
                                    <div className="qualify7 row">
                                        <div className="form-group col-lg-4 col-md-12 col-sm-12">
                                            <label
                                                htmlFor="inputPassword"
                                                className="col-form-label education-label col-sm-3"
                                            >
                                                <b>Institution</b>
                                            </label>
                                            <br />
                                            <div className="">
                                                <input
                                                    type="text"
                                                    className="form-control custodian"
                                                    id="university"
                                                    name="university"
                                                    autoComplete='off'
                                                    value={ item.university }
                                                    onChange={ handleChange }
                                                />
                                            </div>
                                        </div>
                                        <div className="generate2 col-lg-8 col-md-12 col-sm-12 d-flex">
                                            <div className="form-group">
                                                <label
                                                    htmlFor="inputPassword"
                                                    className="col-form-label education-label col-sm-3"
                                                >
                                                    <b>Major/Subject</b>
                                                </label>
                                                <br />
                                                <div className="input-group">
                                                    <div className="">
                                                        <input
                                                            type="text"
                                                            className="form-control multi-select softskill"
                                                            id="specialization"
                                                            name="specialization"
                                                            autoComplete='off'
                                                            value= { item.specialization }
                                                            onChange={ handleChange }
                                                        />
                                                    </div>
                                                    <select className="custom-select pandemic " id="course" name='course'
                                                        onChange={ handleChange }>
                                                        <option defaultValue>{ item.course }</option>
                                                        <option name='course' id='course' value="M.A.">M.A.</option>
                                                        <option name='course' id='course' value="B.S.">B.S.</option>
                                                        <option name='course' id='course' value="B.A.">B.A.</option>
                                                        <option name='course' id='course' value="BSE">BSE</option>
                                                        <option name='course' id='course' value="Ed.D.">Ed.D.</option>
                                                        <option name='course' id='course' value="Course">Course</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="binbox  mt-4 pt-1" onClick={ () => removeCandidateFunc(item, 'education') }  style={ { cursor: 'pointer' } }>
                                                <img src={ Trash } alt="trash" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) } ) }

                        { (educationArray && educationArray.map((item , i ) => {
                            return(
                                <>
                                    <div className="qualify" key={ i }>
                                        <div className="qualify7 row">
                                            <div className="form-group col-lg-4 col-md-12 col-sm-12">
                                                <label
                                                    htmlFor="inputPassword"
                                                    className="col-form-label education-label col-sm-3"
                                                >
                                                    <b>Institution</b>
                                                </label>
                                                <br />
                                                <div className="">
                                                    <input
                                                        type="text"
                                                        className="form-control custodian"
                                                        id="university"
                                                        name="university"
                                                        autoComplete='off'
                                                        value={ item.university }
                                                        onChange={ (e) => handleInputChange(e , i ) }
                                                    />
                                                </div>
                                            </div>
                                            <div className="generate2 col-lg-8 col-md-12 col-sm-12 d-flex">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="inputPassword"
                                                        className="col-form-label education-label col-sm-3"
                                                    >
                                                        <b>Major/Subject</b>
                                                    </label>
                                                    <br />
                                                    <div className="input-group">
                                                        <div className="">
                                                            <input
                                                                type="text"
                                                                className="form-control multi-select softskill"
                                                                id="specialization"
                                                                name="specialization"
                                                                autoComplete='off'
                                                                value={ item.specialization }
                                                                onChange={ (e) => handleInputChange(e , i) }
                                                            />
                                                        </div>
                                                        <select className="custom-select pandemic " id="course" name='course' value={ item.course } onChange={ (e) => handleInputChange(e , i) }>
                                                            <option defaultValue>select</option>
                                                            <option name='course' id='course' value="M.A.">M.A.</option>
                                                            <option name='course' id='course' value="B.S.">B.S.</option>
                                                            <option name='course' id='course' value="B.A.">B.A.</option>
                                                            <option name='course' id='course' value="BSE">BSE</option>
                                                            <option name='course' id='course' value="Ed.D.">Ed.D.</option>
                                                            <option name='course' id='course' value="Course">Course</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="binbox  mt-4 pt-1" style={ { cursor: 'pointer' } }>
                                                    <img src={ Trash } alt="trash" onClick={ () => handleRemoveInput(i) } />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </>
                            )
                        } )) }
                        <div className="plus-container" onClick={ handleAddInput }>
                            <span className="plus-icon">+</span>
                        </div>

                    </form>
                    <div className="form-group resume-file d-flex">
                        <label
                            htmlFor="inputPassword"
                            className="col-form-label education-label col-sm-3"
                        >
                            <p className="fileresum">Resume File</p>
                        </label>
                        <div className="input-group input-button">
                            <input
                                type="file"
                                name="resume"
                                id="resume"
                                onChange={ handleChange }
                                className="form-control"
                                accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                            />
                            <button className="btn-bin" type="button" id="button-addon2">
                                <img
                                    src={ WhiteBin }
                                    onClick={ () => { removeCandidateFunc(inputField, 'resume'); uploadResumeHandler() } }
                                    alt="white-bin"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {(isAddNewCandidate && inputField.full_name === 'New Candidate') && (
                <div className="zombe d-flex justify-content-center">
                    <button
                        className="junkin"
                        type="button"
                        name="button"
                        onClick={ () => addNewCandidateFunc() }
                    >
                        Save
                    </button>
                    <button
                        className="junkin"
                        type="button"
                        name="button"
                        onClick={ () => handleCloseCandidate() }
                    >
                        Cancel
                    </button>
                </div>
            )}
        </div>
    );
}
EditCandidateFormBody.propTypes = {
    candidate: PropTypes.object,
    inputField: PropTypes.object,
    removeCandidateFunc: PropTypes.func,
    addNewField: PropTypes.func,
    addNewCandidateFunc: PropTypes.func,
    handleChange: PropTypes.func,
    uploadProfilePicture: PropTypes.func,
    handleCloseCandidate: PropTypes.func,
    handleSkillsChange: PropTypes.func,
    handleHobbiesChange: PropTypes.func,
    handlEducationChange: PropTypes.func,
    handleSetData: PropTypes.func,
    handleCandidateStatus: PropTypes.func,
    educationArray: PropTypes.array,
    handleInputChange: PropTypes.func,
    handleAddInput: PropTypes.func,
    handleRemoveInput: PropTypes.func,
    rowIndex: PropTypes.number,
    isAddNewCandidate: PropTypes.bool,
    handleRemoveData1: PropTypes.func,
    uploadResumeHandler: PropTypes.func
};

export default EditCandidateFormBody;
