/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import AvatarComp from 'shared/AvatarComp';
import sideArrow from 'static/images/left.svg';
import TickIcon from 'static/images/tick.svg';
import CrossIcon from 'static/images/cross.svg';
import StopIcon from 'static/images/stop.svg';
import NotesSection from '../Note';
import HiringProfileModal from '../HiringProfileModal';
import {
    acceptDeclineClient,
    getClientHiringDetails,
    getClientListing,
    updateNotesDetails,
    updatePendingNotes,
} from 'actions/dashboardActions';
import { cityCountryFormatter, ddmmyyFormat, formatPhoneNumber, SearchTableData } from 'shared/Constant';
import Loading from 'shared/Loader';
import AuthHeaderSection from '../AuthHeaderSection';
import Sort from 'shared/Sorting';
import { triggerNotifier } from 'shared/Notifier';

function PendingSection(props) {
    const dispatch = useDispatch();
    const [ isOpenNote, setIsOpenNote ] = useState(false);
    const [ sortType, setSortType ] = useState('');
    const [ sortArrowUp, setSortArrowUp ] = useState(false);
    const [ sortArrowDown, setSortArrowDown ] = useState(false);
    const [ clientId, setClientId ] = useState('');
    const [ toggleArrowIndex, setToggleArrowIndex ] = useState('');
    const [ notesValue, setNotesValues ] = useState(false);
    const [ clientDetails, setClientDetails ] = useState({});
    const [ filterOptions, setFilterOptions ] = useState({
        city: '', state: ''
    });
    const [ isOpenHiringModal, setIsOpenHiringModal ] = useState('');
    const [ searchValue, setSearchValue ] = useState('');
    const [ sortedData, setSortedData ] = useState([]);
    const [ columnName, setColumnName ] = useState('');
    const [ companyArrowUp, setCompanyArrowUp ] = useState(false);
    const [ companyArrowDown, setCompanyArrowDown ] = useState(false);
    const [ locationArrowUp, setLocationArrowUp ] = useState(false);
    const [ locationArrowDown, setLocationArrowDown ] = useState(false);
    const [ registeredArrowUp, setRegisteredArrowUp ] = useState(false);
    const [ registeredArrowDown, setRegisteredArrowDown ] = useState(false);

    const clientList = useSelector((state) => state.clientListing);
    const isLoading = useSelector((state) => state.applicationIsLoading);
    const clientHiringDetails = useSelector((state) => state.clientHiringDetails);
    const tableData = SearchTableData(clientList, searchValue)
    useEffect(() => {
        dispatch(getClientListing(filterOptions));
    }, [ dispatch, filterOptions ]);
    const handleOpenNotes = (data) => {
        setClientDetails(data)
        setIsOpenNote(true);
        if(!isOpenNote) {
            setTimeout(function() {
                window.scroll({
                    top: document.body.offsetHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 300);
        }
    };
    const handleCloseNotes = () =>{
        setIsOpenNote(false);
    }
    const handleAcceptance = (id, value) => {
        const data = {
            client_id: id,
            is_accept: value,
        };
        dispatch(acceptDeclineClient(id, data));
    };
    const handleHiringDetails = (event, client) => {
        event.preventDefault();
        setClientId(client.id)
        dispatch(getClientHiringDetails(client.id));
        setIsOpenHiringModal(!isOpenHiringModal);
    };
    const closeHiringDetails = () => {
        setIsOpenHiringModal(!isOpenHiringModal);
    };
    const handleEditNotes = (event) => {
        setNotesValues(event.target.value);
    };
    const updateNotes = (event) => {
        const data = {
            client_id: clientDetails.id,
            notes: notesValue || '',
        };
        const message = 'The notes entered exceeds the maximum limit and its should be 500 words'
        let numWords = 0;
        for (var i = 0; i < data.notes.length; i++) {
            var currentCharacter = data.notes[ i ];
            if (currentCharacter === " ") {
                numWords += 1;
            }
        }
        numWords += 1;
        if(numWords <= 500) {
            dispatch(updatePendingNotes(clientDetails.id, data)).then((res)=> {
                if (res.statusCode === 200 ){
                    setIsOpenNote(false);
                    dispatch(getClientListing(filterOptions));
                    window.location.reload();
                }
            })
        } else {
            triggerNotifier({ type: 'error', message })
        }
    };
    const updateSearch = (event) => {
        setSearchValue(event.target.value.substr(0, 20));
    };
    const handleOptions = (event) => {
        setFilterOptions({ [ event.target.name ]: event.target.value })
    }
    const handleToggleTable = (idx) => {
        if (toggleArrowIndex === idx){
            setToggleArrowIndex('')
        }
        else{
            setToggleArrowIndex(idx)
        }
    }
    const sorted = tableData && tableData.sort((a, b) => {
        const isReversed = !sortType ? '' :  (sortType === 'asc') ? 1 : -1
        if(columnName === 'company'){
            return isReversed*a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
        }
        else if(columnName === 'location'){
            return (isReversed*a.city.toLowerCase().localeCompare(b.city.toLowerCase()))
        }
        else if(columnName === 'registered'){
            const date1 = new Date(a.joined_date);
            const date2 = new Date(b.joined_date);
            return (isReversed*date1.getTime() - isReversed*date2.getTime())
        } else {
            return a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
        }
    })
    const handleColumnName = (data) => {
        setColumnName(data)
    }
    const onSort = (order, data) => {
        setSortType(order)
        setColumnName(data)
        if(data === 'company') {
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setCompanyArrowUp(!companyArrowUp)
        }
        if(data === 'location') {
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
            setLocationArrowUp(!locationArrowUp)
        }
        if(data === 'registered') {
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setRegisteredArrowUp(!registeredArrowUp)
        }
    }
    const onSortUp = (order, data) => {
        setSortType(order)
        if(columnName === 'company' || data === 'company') {
            setCompanyArrowUp(!companyArrowUp)
            setCompanyArrowDown(!companyArrowDown)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'location' || data === 'location') {
            setLocationArrowUp(!locationArrowUp)
            setLocationArrowDown(!locationArrowDown)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        } else if(columnName === 'registered' || data === 'registered') {
            setRegisteredArrowDown(!registeredArrowDown)
            setRegisteredArrowUp(!registeredArrowUp)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
        }
    }
    const onSortDown = (order) => {
        setSortType(order);
        if(columnName === 'company') {
            setCompanyArrowUp(false)
            setCompanyArrowDown(false)
        } else if(columnName === 'location') {
            setLocationArrowUp(false)
            setLocationArrowDown(false)
        } else if(columnName === 'registered'){
            setRegisteredArrowDown(false)
            setRegisteredArrowUp(false)
        }
    }
    return (
        <>
            <AuthHeaderSection
                props={ props }
                updateSearch={ updateSearch }
                searchValue={ searchValue }
                handleOptions={ handleOptions }
            />
            <div className="table-responsive main-div">
                <table className=" main-table table table-condensed github table-borderless" id="accordion">
                    <thead>
                        <tr className="table-head">
                            <th scope="col" className="s_no" style={ { width: '5%' } }>#</th>
                            <th scope="col" style={ { width: '20%' } }>
                                Company Name{' '}
                                <Sort
                                    data= { 'company' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp = { companyArrowUp }
                                    sortArrowDown ={ companyArrowDown }
                                    onSort ={ onSort }
                                    onSortUp ={ onSortUp }
                                    onSortDown ={ onSortDown }
                                />
                            </th>
                            <th scope="col" style={ { width: '18%' } }>
                                Location{' '}
                                <Sort
                                    data= { 'location' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp = { locationArrowUp }
                                    sortArrowDown ={ locationArrowDown }
                                    onSort ={ onSort }
                                    onSortUp ={ onSortUp }
                                    onSortDown ={ onSortDown }
                                />
                            </th>
                            <th scope="col" style={ { width: '22%' } }>Email</th>
                            <th scope="col" style={ { width: '15%' } }>Phone</th>
                            <th scope="col" style={ { width: '12%' } }>
                                Registered{' '}
                                <Sort
                                    data= { 'registered' }
                                    handleColumnName = { handleColumnName }
                                    sortArrowUp = { registeredArrowUp }
                                    sortArrowDown ={ registeredArrowDown }
                                    onSort ={ onSort }
                                    onSortUp ={ onSortUp }
                                    onSortDown ={ onSortDown }
                                />
                            </th>
                            <th scope="col" style={ { width: '4%' } }></th>
                        </tr>
                    </thead>
                    {isLoading && (
                        <div className="loader">
                            <Loading />
                        </div>
                    )}
                    {!isLoading &&
            sorted &&
            sorted.map((client, idx) => {
                return (
                    <tbody key={ idx } >
                        <tr
                            className="accordion-toggle table-row"
                            data-bs-toggle="collapse"
                            href={ `#toggle${ idx }` }
                            onClick={ () => { handleToggleTable(client.id) } }
                        >
                            <th scope="row" className="s_no">{idx + 1}</th>
                            <td>{client.company_name}</td>
                            <td>{cityCountryFormatter(client)}</td>
                            <td className="email-text">{client.email_id}</td>
                            <td>{!formatPhoneNumber(client.phone_number)? ( <span>-</span>):(formatPhoneNumber(client.phone_number))}</td>
                            <td>{moment(client.joined_date).format(ddmmyyFormat)}</td>
                            <td>
                                <img
                                    src={ sideArrow }
                                    alt="side-arrow"
                                    className="down"
                                />
                            </td>
                        </tr>
                        <tr className="collapse-row dynamicrow" data-bs-parent="#myTable1">
                            <td colSpan="7" className="hiddenRow">
                                <div
                                    className="accordian-body collapse"
                                    id={ `toggle${ idx }` }
                                    data-bs-parent="#accordion"
                                >
                                    <div className="emergency collapse-row">
                                        <div className="extendedrev row p-3 pr-0 m-0 g-0">
                                            <div className="col-lg-1 col-md-2 col-sm-12">
                                                <AvatarComp name={ client.first_name } image={ client.avatar }/>
                                            </div>
                                            <div className="clicked col-lg-3 col-md-4 col-sm-12 overflow-auto">
                                                <table>
                                                    <tr>
                                                        <td className="title">Company</td>
                                                        <td className="sub-title">
                                                            {client.company_name}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="title">Email</td>
                                                        <td className="sub-title">
                                                            {client.email_id}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="title">Phone</td>
                                                        <td className="sub-title">
                                                            {!formatPhoneNumber(client.phone_number)? ( <span>-</span>):(formatPhoneNumber(client.phone_number))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="title">URL</td>
                                                        <td className="sub-title">
                                                            {client.company_url}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="clicks1 col-lg-2 col-md-4 col-sm-12">
                                                <tr>
                                                    <td className="title">First Name</td>
                                                    <td className="sub-title">
                                                        {client.first_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="title">Last Name</td>
                                                    <td className="sub-title">
                                                        {client.last_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="title">Job Title</td>
                                                    <td className="sub-title">
                                                        {client.job_title}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="title">Registered</td>
                                                    <td className="sub-title">
                                                        {moment(client.joined_date).format(
                                                            ddmmyyFormat
                                                        )}
                                                    </td>
                                                </tr>
                                            </div>
                                            <div className="col-lg-3 col-md-12 col-sm-12">
                                                <p className="mb-0">
                                                    <b>Notes</b>
                                                </p>
                                                <form>
                                                    <textarea
                                                        id="w3review"
                                                        placeholder="Make your note"
                                                        name="w3review"
                                                        onClick={ () => handleOpenNotes(client) }
                                                        defaultValue={ client.notes }
                                                        readOnly
                                                    ></textarea>
                                                </form>
                                            </div>
                                            <div className="clickeb d-grid paddingL col-lg-3 col-md-4 col-sm-12">
                                                <button
                                                    type="button"
                                                    className="hiring-profile-btn text-center"
                                                    data-toggle="modal"
                                                    data-target="#exampleModal"
                                                    onClick={ (event) =>
                                                        handleHiringDetails(event, client)
                                                    }
                                                >
                                                    Hiring Profile
                                                </button>
                                                <button
                                                    className="hiring-btn mt-3"
                                                    type="button"
                                                    name="button"
                                                    onClick={ () => handleAcceptance(client.id, 0) }
                                                >
                                                    <img
                                                        src={ TickIcon }
                                                        className="hiring"
                                                        alt="tick"
                                                    />
                                                    Accept
                                                </button>
                                                { client.is_declined ?
                                                    <button className='stop-btn'>
                                                        <img
                                                            src={ StopIcon }
                                                            className="stop"
                                                            alt="stop"
                                                            width="25"
                                                        />
                                                        Stop
                                                    </button>
                                                    : <button
                                                        className='hiring-btn'
                                                        type="button"
                                                        name="button"
                                                        onClick={ () => handleAcceptance(client.id, 1) }
                                                    >
                                                        {' '}
                                                        <img
                                                            src={ CrossIcon }
                                                            className="hiring"
                                                            alt="cross"
                                                        />
                                                        Decline
                                                    </button> }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                );
            })}
                </table>
            </div>
            {isOpenNote && (
                <NotesSection
                    handleOpenNotes={ handleOpenNotes }
                    handleEditNotes={ handleEditNotes }
                    clientList={ clientList }
                    handleCandidatesNotes={ updateNotes }
                    handleCloseNotes={ handleCloseNotes }
                    notesValue={ clientDetails }
                />
            )}
            {isOpenHiringModal && (
                <HiringProfileModal
                    isOpenHiringModal={ isOpenHiringModal }
                    clientHiringDetails={ clientHiringDetails }
                    closeHiringDetails={ closeHiringDetails }
                    clientId={ clientId }
                />
            )}
        </>
    );
}

export default PendingSection;
