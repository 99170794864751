import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Dashboard from 'components/dashboard/Pending';
import NotFound from 'shared/NotFound';
import AuthRoutes from './AuthRoutes'
import LoginPage from 'components/authentication';
import ClientSection from 'components/dashboard/Clients';
import CandidatesSection from 'components/dashboard/Candidates';
import HiringSection from 'components/dashboard/Hiring';
import StorageSection from 'components/dashboard/Storage';
import SendToVoteSection from 'components/dashboard/Vote';
import EpicPassportSection from 'components/dashboard/EpicPassport';
import MilestoneSection from 'components/dashboard/Milestone';
import TaskSection from 'components/dashboard/Task';

import WardClientsSection from 'components/dashboard/WardClients';
import EditSection from 'components/dashboard/Edit';
import EditCandidateForm from 'components/dashboard/EditCandidateForm';
import SendToVote from 'components/dashboard/SendVote';
import TaskCandidates from '../../components/dashboard/TaskCandidate/index';

const AppRoutes = () => (
    <Router>
        <Switch>
            <AuthRoutes  exact path="/" component={ LoginPage } />
            <AuthRoutes  exact path="/pending" component={ Dashboard } />
            <AuthRoutes  exact path="/clients" component={ ClientSection } />
            <AuthRoutes  exact path="/candidates" component={ CandidatesSection } />
            <AuthRoutes  exact path="/not-found" component={ NotFound } />
            <AuthRoutes  exact path="/hiring" component={ HiringSection } />
            <AuthRoutes  exact path="/storage" component={ StorageSection } />
            <AuthRoutes  exact path="/associate-candidates" component={ SendToVoteSection } />
            <AuthRoutes  exact path="/send-to-vote" component={ SendToVote } />
            <AuthRoutes  exact path="/epic-passport" component={ EpicPassportSection } />
            <AuthRoutes  exact path="/milestone" component={ MilestoneSection } />
            <AuthRoutes  exact path="/task" component={ TaskSection } />
            <AuthRoutes  exact path="/taskcandidate" component={ TaskCandidates } />
            <AuthRoutes  exact path="/ward-clients" component={ WardClientsSection } />
            <AuthRoutes  exact path="/edit" component={ EditSection } />
            <AuthRoutes  exact path="/edit-candidate" component={ EditCandidateForm } />
        </Switch>
    </Router>
);

export default AppRoutes;