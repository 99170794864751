/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Sort from 'shared/Sorting';
import sideArrow from 'static/images/left.svg';
import { AiOutlineFileSearch } from 'react-icons/ai';
import Play from 'static/images/play.svg';
import Pause from 'static/images/pause.svg';
import { handleStatus } from 'utils/Helper';
import Loading from 'shared/Loader';
import Rating from 'shared/Rating';
import SendToVoteIcon from 'static/images/send-to-vote.svg';
import CultureFitModal from '../SendVote/CultureFitModal';
import { ReactSVG } from 'react-svg';
import Reload from 'static/images/reload.svg';
import Bag from 'static/images/bag.svg';
import Thumbsup from 'static/images/thumb.svg';
import ThumbsDown from 'static/images/thumb-red.svg';
import { sendCulturalFitRating, withdrawalCandidateProfileAction } from 'actions/candidateActions';
import Github from 'static/images/github-img.png';
import Portfolio from 'static/images/portfolio-img.png';
import Linkedin from 'static/images/linkedin-img.png';
import { Link } from 'react-router-dom';
import Trash from 'static/images/trash.svg';
import EditCandidate from './EditCandidate';
import AvatarComp from 'shared/AvatarComp';

function CandidatesList(props) {
    const {
        campaignCandidates,
        handleOpenNotes,
        cityCountryFormatter,
        dispatch,
        isEdit,
        educationArray, handleInputChange, handleAddInput, handleRemoveInput, handleHobbiesChange, handleSkillsChange, handleCandidateStatus,
        removeCandidateFunc,
        uploadProfilePicture,
        InputChange,
        inputField,
        handleToggleTable,
        toggleArrowIndex, handleDeleteModal, uploadResumeHandler
    } = props;
    const client_id = localStorage.getItem('clientId');
    const clientId = client_id || (props && props.campaignCandidates && props.campaignCandidates[ 0 ].client_id);
    const isLoading = useSelector((state) => state.applicationIsLoading);
    const [ sortType, setSortType ] = useState('');
    const [ columnName, setColumnName ] = useState('');
    const [ fullNameArrowUp, setFullNameArrowUp ] = useState(false);
    const [ fullNameArrowDown, setFullNameArrowDown ] = useState(false);
    const [ techArrowUp, setTechArrowUp ] = useState(false);
    const [ techArrowDown, setTechArrowDown ] = useState(false);
    const [ mlArrowUp, setMlArrowUp ] = useState(false);
    const [ mlArrowDown, setMlArrowDown ] = useState(false);
    const [ campaignsArrowUp, setCampaignsArrowUp ] = useState(false);
    const [ campaignsArrowDown, setCampaignsArrowDown ] = useState(false);
    const [ locationArrowUp, setLocationArrowUp ] = useState(false);
    const [ locationArrowDown, setLocationArrowDown ] = useState(false);
    const [ statusArrowUp, setStatusArrowUp ] = useState(false);
    const [ statusArrowDown, setStatusArrowDown ] = useState(false);
    const [ isCultureFit, setIsCultureFit ] = useState(false);
    const [ cultureFitRating, setCultureFitRating ] = useState('');
    const [ sendToVoteData, setSendToVoteData ] = useState({})

    const handleSendToVote = (data) => {
        setIsCultureFit(!isCultureFit)
        setSendToVoteData(data)
    }
    const handleWithdrawalCandidateProfile = (data) => {
        const finalData = {
            candidate_id : data.candidate_id,
            client_id : clientId,
            campaign_id : data.campaign_id
        }
        dispatch(withdrawalCandidateProfileAction(finalData))
    }
    const handleSubmit = () => {
        const data = {
            candidate_id : sendToVoteData.candidate_id,
            client_id : clientId,
            campaign_id : sendToVoteData.campaign_id,
            profile_status : 1,
            culture_fit : cultureFitRating.cultureFit || '3'
        }
        dispatch(sendCulturalFitRating(data)).then((res) => {
            if (res.statusCode === 200) {
                window.location.reload();
            }
        });
        setIsCultureFit(!isCultureFit)
    }
    const handleChange = (event) => {
        setCultureFitRating({ [ event.target.name ]: event.target.value })
    }
    const sorted = campaignCandidates && campaignCandidates.sort((a, b) => {
        const isReversed = !sortType ? '' :  (sortType === 'asc') ? 1 : -1
        if(columnName === 'full-name'){
            return isReversed*a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
        }
        else if(columnName === 'tech'){
            return (isReversed*a.tech_stack.toLowerCase().localeCompare(b.tech_stack.toLowerCase()))
        }
        else if(columnName === 'campaigns'){
            return ((isReversed*a.total_campaigns) - (b.total_campaigns))
        }
        else if(columnName === 'location'){
            return (isReversed*a.city.toLowerCase().localeCompare(b.city.toLowerCase()))
        }
        else if(columnName === 'ml'){
            return isReversed*a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
        }
        else if(columnName === 'status'){
            if(isReversed === 1){
                return isReversed*a.profile_status - b.profile_status
            } else if(isReversed === -1){
                return b.profile_status - a.profile_status
            } else {
                return a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
            }
        }
        else {
            return a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
        }
    })
    const handleColumnName = (data) => {
        setColumnName(data)
    }
    const onSort = (order, data) => {
        setSortType(order)
        setColumnName(data)
        if(data === 'full-name') {
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
            setFullNameArrowUp(!fullNameArrowUp)
        }
        if(data === 'tech') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
            setTechArrowUp(!techArrowUp)
        }
        if(data === 'ml') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
            setMlArrowUp(!mlArrowUp)
        }
        if(data === 'campaigns') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
            setCampaignsArrowUp(!campaignsArrowUp)
        }
        if(data === 'location') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
            setLocationArrowUp(!locationArrowUp)
        }
        if(data === 'status') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setStatusArrowUp(!statusArrowUp)
        }
    }
    const onSortUp = (order, data) => {
        setSortType(order)
        if(columnName === 'full-name' || data === 'full-name') {
            setFullNameArrowUp(!fullNameArrowUp)
            setFullNameArrowDown(!fullNameArrowDown)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
        } else if(columnName === 'tech' || data === 'tech') {
            setTechArrowUp(!techArrowUp)
            setTechArrowDown(!techArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
        } else if(columnName === 'ml' || data === 'ml') {
            setMlArrowUp(!mlArrowUp)
            setMlArrowDown(!mlArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
        } else if(columnName === 'campaigns' || data === 'campaigns') {
            setCampaignsArrowDown(!campaignsArrowDown)
            setCampaignsArrowUp(!campaignsArrowUp)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
        } else if(columnName === 'location' || data === 'location') {
            setLocationArrowUp(!locationArrowUp)
            setLocationArrowDown(!locationArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setStatusArrowUp(false)
            setStatusArrowDown(false)
        } else if(columnName === 'status' || data === 'status') {
            setStatusArrowUp(!statusArrowUp)
            setStatusArrowDown(!statusArrowDown)
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
            setTechArrowUp(false)
            setTechArrowDown(false)
            setMlArrowUp(false)
            setMlArrowDown(false)
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
            setLocationArrowUp(false)
            setLocationArrowDown(false)
        }
    }
    const onSortDown = (order) => {
        setSortType(order)
        if(columnName === 'full-name') {
            setFullNameArrowUp(false)
            setFullNameArrowDown(false)
        } else if(columnName === 'tech') {
            setTechArrowUp(false)
            setTechArrowDown(false)
        } else if(columnName === 'ml') {
            setMlArrowUp(false)
            setMlArrowDown(false)
        } else if(columnName === 'campaigns'){
            setCampaignsArrowUp(false)
            setCampaignsArrowDown(false)
        } else if(columnName === 'location'){
            setLocationArrowUp(false)
            setLocationArrowDown(false)
        } else if(columnName === 'status'){
            setStatusArrowUp(false)
            setStatusArrowDown(false)
        }
    }
    return (
        <>
            <table className="table table-condensed github table-borderless" id="accordion1">
                <thead>
                    <tr className="table-head">
                        <th scope="col" className="s_no">#</th>
                        <th scope="col"></th>
                        <th scope="col" style={ { width: '18%' } }>
                            Full Name{' '}
                            <Sort
                                data= { 'full-name' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { fullNameArrowUp }
                                sortArrowDown ={ fullNameArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '16%' } }>
                            Tech Stack{' '}
                            <Sort
                                data= { 'tech' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { techArrowUp }
                                sortArrowDown ={ techArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '14%' } }>
                            Location{' '}
                            <Sort
                                data= { 'location' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { locationArrowUp }
                                sortArrowDown ={ locationArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '9%' } }>
                            ML%{' '}
                            <Sort
                                data= { 'ml' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { mlArrowUp }
                                sortArrowDown ={ mlArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '14%' } }>
                            Campaigns{' '}
                            <Sort
                                data= { 'campaigns' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { campaignsArrowUp }
                                sortArrowDown ={ campaignsArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            />
                        </th>
                        <th scope="col" style={ { width: '10%' } }>Status{' '}
                            <Sort
                                data= { 'status' }
                                handleColumnName = { handleColumnName }
                                sortArrowUp = { statusArrowUp }
                                sortArrowDown ={ statusArrowDown }
                                onSort ={ onSort }
                                onSortUp ={ onSortUp }
                                onSortDown ={ onSortDown }
                            /></th>
                        <th scope="col" style={ { width: '10%' } }></th>
                    </tr>
                </thead>
                {isLoading && (
                    <div className="loader-right">
                        <Loading />
                    </div>
                )}
                <tbody>
                    {!isLoading &&
          sorted &&
          sorted.map((data, idx) => {
              return (
                  <>
                      <tr
                          key={ idx+ 'd' }
                          data-bs-toggle="collapse"
                          href={ `#togglecamplist${ idx }` }
                          className="accordion-toggle table-row storage-row"
                          onClick={ () => { handleToggleTable(data.candidate_id, data) } }
                      >
                          <th scope="row" className="s_no">{idx + 1}</th>
                          <td>
                              {data.is_active ? (
                                  <img
                                      src={ Play }
                                      alt="play"
                                      className="play-icon"
                                      onClick={ () => handleStatus(dispatch, data, 1, 0) }
                                  />
                              ) : (
                                  <img
                                      src={ Pause }
                                      alt="pause"
                                      className="pause-icon pause-img"
                                      onClick={ () => handleStatus(dispatch, data, 1, 1) }
                                  />
                              )}
                          </td>
                          <td>{data.full_name}</td>
                          <td>{data.tech_stack} </td>
                          <td>{cityCountryFormatter(data)}</td>
                          <td>
                              <div className='d-flex align-items-center justify-content-center'>
                                  {data.assessment_score}%
                              </div>
                          </td>
                          <td>
                              <div className="storage-center-text d-flex align-items-center justify-content-center">
                                  <u > { data.total_active_campaigns ? data.total_active_campaigns : 0 }/<b>{ data.total_campaigns ? data.total_campaigns : 0 }</b></u>
                              </div>
                          </td>
                          <td className='d-flex align-items-center justify-content-center'>
                              {
                                  (data.profile_status === 0) || (data.profile_status === 7) ?
                                      <img
                                          src={ SendToVoteIcon }
                                          alt="SendToVoteIcon"
                                          className="send-to-vote pointer"
                                          onClick={ () => handleSendToVote(data) }
                                      />
                                      :
                                      data.profile_status === 1 ?
                                          <ReactSVG
                                              src={ Reload }
                                              alt="reload"
                                              className="reload"
                                              onClick={ () => handleWithdrawalCandidateProfile(data) }
                                          />
                                          :
                                          (data.profile_status === 6) ?
                                              <img
                                                  src={ Bag }
                                                  alt="Bag"
                                              />
                                              :
                                              (data.profile_status === 2) || (data.profile_status === 4) || (data.profile_status === 5)  ?
                                                  <img
                                                      src={ Thumbsup }
                                                      alt="Thumbsup"
                                                  />
                                                  :
                                                  (data.profile_status === 3) ?
                                                  <img
                                                      src={ ThumbsDown }
                                                      alt="ThumbsDown"
                                                      className='down-thumb'
                                                  />
                                                  :
                                                  ''
                              }
                          </td>
                          <td>
                              <div className='d-flex align-items-center justify-content-end'>
                                  {isEdit && (
                                      <img
                                          onClick={ () => handleDeleteModal(data, 'candidate') }
                                          src={ Trash }
                                          alt="trash"
                                          className='cursor'
                                      />)
                                  }
                                  {!(toggleArrowIndex===data.candidate_id) ? (
                                      <img
                                          src={ sideArrow }
                                          alt="side-arrow"
                                          className="down"
                                          style={ { margin: '0 8px 0 8px' } }
                                      />
                                  ) : (
                                      <img
                                          src={ sideArrow }
                                          onClick={ () => { handleToggleTable(data.candidate_id) } }
                                          alt="side-arrow"
                                          className="down-arrow"
                                          style={ { margin: '0 8px 0 8px' } }
                                      />
                                  )}
                              </div>
                          </td>
                      </tr>
                          {
                              !isEdit && (
                                <tr className="collapse-row dynamicrow" key={ idx+ 'e' } data-bs-parent="#myTable1">
                                  <td colSpan="12" className="hiddenRow">
                                      <div
                                          className="accordian-body collapse"
                                          id={ `togglecamplist${ idx }` }
                                          data-bs-parent="#accordion1"
                                      >
                                          <div className="emergency">
                                              <div className="extend row">
                                                  <div className="text-center col-lg-3 col-md-3 col-sm-12">
                                                      <AvatarComp
                                                          image={ data.avatar }
                                                          name={ data.full_name }
                                                      />
                                                      <br />
                                                      {data.is_active ? (
                                                          <img
                                                              src={ Play }
                                                              alt="play"
                                                              className="play-icon"
                                                              onClick={ () => handleStatus(dispatch, data, 1, 0) }
                                                          />
                                                      ) : (
                                                          <img
                                                              src={ Pause }
                                                              alt="pause"
                                                              className="pause-icon pause-img"
                                                              onClick={ () => handleStatus(dispatch, data, 1, 1) }
                                                          />
                                                      )}
                                                  </div>
                                                  <div className="storage-clicked col-lg-4 col-md-4 col-sm-12">
                                                      <table>
                                                          <tr>
                                                              <td className="storage-title">Name</td>
                                                              <td className="storage-sub-title">
                                                                  {data.first_name}
                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td className="storage-title">Surname</td>
                                                              <td className="storage-sub-title">
                                                                  {data.last_name}
                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td className="storage-title">Location</td>
                                                              <td className="storage-sub-title">
                                                                  {cityCountryFormatter(data)}
                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td className="storage-title">Culture Fit</td>
                                                              <td className="storage-sub-title">
                                                                  <Rating rate ={ data.culture_fit } />
                                                              </td>
                                                          </tr>
                                                      </table>
                                                  </div>
                                                  <div className="storage-clicked col-lg-4 col-md-4 col-sm-12">
                                                      <table>
                                                          <tr>
                                                              <td className="storage-title">Tech Stack</td>
                                                              <td className="storage-sub-title">
                                                                  {data.tech_stack}
                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td className="storage-title">Experience</td>
                                                              <td className="storage-sub-title">
                                                                  {data.experience} years
                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td className="storage-title">Assessment</td>
                                                              <td className="storage-sub-title">
                                                                  {data.assessment_score}%
                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td>
                                                                  <a
                                                                      href={ data.resume }
                                                                      target="_blank"
                                                                      rel="noreferrer"
                                                                      className="resume-link resume-btn-link">
                                                                      <AiOutlineFileSearch className="resume-icon" />
                                                                      View Resume
                                                                  </a>
                                                              </td>
                                                          </tr>
                                                      </table>
                                                  </div>
                                                  <div className="storage-clicked col-lg-1 col-md-1 col-sm-12">
                                                      <div className='d-flex flex-column social-links'>
                                                          {
                                                              (data.github_url !== null && data.github_url !== '') ?
                                                                  (
                                                                      <Link to={ { pathname: data.github_url } }  target="_blank" >
                                                                          <img src={ Github } alt='git-img' width='20' />
                                                                      </Link>
                                                                  )
                                                                  :
                                                                  (
                                                                      <img src={ Github } alt='git-img' className='fade-link'   width='20' />
                                                                  )
                                                          }
                                                          {
                                                              (data.linkedin_url !== null && data.linkedin_url !== '') ?
                                                                  (
                                                                      <Link to={ { pathname: data.linkedin_url } }    target="_blank" >
                                                                          <img src={ Linkedin } alt='linkedin-img' width='20' />
                                                                      </Link>
                                                                  )
                                                                  :
                                                                  (
                                                                      <img src={ Linkedin } alt='linkedin-img' className='fade-link' width='20' />
                                                                  )
                                                          }
                                                          {
                                                              (data.portfolio_url !== null && data.portfolio_url !== '') ?
                                                                  (
                                                                      <Link to={ { pathname: data.portfolio_url } }    target="_blank" >
                                                                          <img src={ Portfolio } alt='portfolio-img' width='20' />
                                                                      </Link>
                                                                  )
                                                                  :
                                                                  (
                                                                      <img src={ Portfolio } alt='linkedin-img' className='fade-link' width='20' />
                                                                  )
                                                          }
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="dataclickp pt-4">
                                                  <div className="notesdata datamine">
                                                      <b>Notes</b>
                                                      <form>
                                                          <textarea
                                                              className="w-100"
                                                              id="w3review"
                                                              onClick={ () => handleOpenNotes(data) }
                                                              name="w3review"
                                                              rows="4"
                                                              cols="0"
                                                              placeholder='Add your notes'
                                                              defaultValue={ data.notes }
                                                          ></textarea>
                                                      </form>
                                                  </div>
                                                  <div className="professor pt-2">
                                                      <h4>Education</h4>
                                                      <ul className="oxford">
                                                          {data.educations.map((education, idm) => {
                                                              return (
                                                                  <>
                                                                      <li className="jobe" key={ idm }>
                                                                          {education.university}
                                                                      </li>
                                                                      <li className="computate">
                                                                          {`${ education.specialization }, ${ education.course }`}
                                                                      </li>
                                                                  </>
                                                              );
                                                          })}
                                                      </ul>
                                                  </div>
                                                  <div className="row pt-3">
                                                      <div className="jbondz5 oxford col-lg-6 col-md-12">
                                                          <h4>Skills</h4>
                                                          <ul className="jbond2">
                                                              {data.skills.map((skill, idm) => {
                                                                  return (
                                                                      <li className="jobe" key={ idm }>
                                                                          -{skill.skill_name}
                                                                      </li>
                                                                  );
                                                              })}
                                                          </ul>
                                                      </div>
                                                      <div className="bondz oxford2 col-lg-6 col-md-12">
                                                          <h4>Hobbies</h4>
                                                          <ul className="jbond1">
                                                              {data.hobbies.map((hobby, idm) => {
                                                                  return (
                                                                      <li className="jobe" key={ idm }>
                                                                          -{hobby.hobby_name}
                                                                      </li>
                                                                  );
                                                              })}
                                                          </ul>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </td>
                                  </tr>
                              )
                          }
                          {
                              isEdit && (
                                  <tr className="collapse-row dynamicrow" key={ idx+ 'f' } data-bs-parent="#myTable1">
                                  <td colSpan="12" className="hiddenRow" >
                                      <div
                                          className="accordian-body collapse"
                                          id={ `togglecamplist${ idx }` }
                                          data-bs-parent="#accordion1"
                                      >
                                          <EditCandidate
                                              data = { data }
                                              educationArray={ educationArray }
                                              handleInputChange= { handleInputChange }
                                              handleAddInput= { handleAddInput }
                                              handleRemoveInput = { handleRemoveInput }
                                              rowIndex={ idx }
                                              handleSkillsChange = { handleSkillsChange }
                                              handleHobbiesChange = { handleHobbiesChange }
                                              handleCandidateStatus={ handleCandidateStatus }
                                              removeCandidateFunc={ removeCandidateFunc }
                                              uploadProfilePicture={ uploadProfilePicture }
                                              handleChange={ InputChange }
                                              inputField={ inputField }
                                              uploadResumeHandler={ uploadResumeHandler }
                                          />
                                      </div>
                                  </td>
                                </tr>
                              )
                          }
                  </>
              );
          })}
                    {isCultureFit &&
                        <CultureFitModal
                            isCultureFit={ isCultureFit }
                            handleSubmit={ handleSubmit }
                            handleChange={ handleChange }
                            handleSendToVote={ handleSendToVote }
                        />
                    }
                </tbody>
            </table>
        </>
    );
}
CandidatesList.propTypes = {
    history: PropTypes.object,
    campaignCandidates: PropTypes.array,
    handleOpenNotes: PropTypes.func,
    dispatch: PropTypes.func,
    fullNameFormatter: PropTypes.func,
    campId: PropTypes.string,
    cityCountryFormatter: PropTypes.func,
    isEdit: PropTypes.bool,
    educationArray: PropTypes.array,
    handleInputChange: PropTypes.func,
    handleAddInput: PropTypes.func,
    handleRemoveInput: PropTypes.func,
    handleSkillsChange: PropTypes.func,
    handleHobbiesChange: PropTypes.func,
    handleCandidateStatus: PropTypes.func,
    removeCandidateFunc: PropTypes.func,
    uploadProfilePicture: PropTypes.func,
    InputChange: PropTypes.func,
    inputField: PropTypes.array,
    handleToggleTable: PropTypes.func,
    toggleArrowIndex: PropTypes.number,
    handleDeleteModal: PropTypes.func,
    uploadResumeHandler: PropTypes.func
};

export default CandidatesList;
