import React from 'react';
import PropTypes from 'prop-types';

const EditSkillBody = (props) => {
    const { handleSkillsChange, skill, index, rowIndex } = props
    return(
        <div>
            <input
                type="text"
                className="form-control skill-field"
                id="skills"
                autoComplete='off'
                name="skills"
                defaultValue={ skill || '' }
                onChange ={ (e) => handleSkillsChange(e, index, skill, rowIndex) }
            />
        </div>
    )
}
EditSkillBody.propTypes = {
    handleSkillsChange: PropTypes.func,
    skill: PropTypes.string,
    index: PropTypes.string,
    rowIndex: PropTypes.string,
};

export default EditSkillBody