import React from 'react';
import PropTypes from 'prop-types';
import {  Modal, ModalBody, ModalFooter } from 'reactstrap';

const CultureFitModal = (props) => {
    const {
        isCultureFit,
        handleSendToVote,
        handleChange,
        handleSubmit
    } = props;

    return (
        <>
            <Modal isOpen={ isCultureFit } className="culture-fit">
                <ModalBody>
                    <p className="result-heading pb-0">Please input the candidate culture fit rating
                        for Epic Passport</p>
                    <div className="d-flex justify-content-center align-items-center">
                        <span className="culture">Culture Fit</span><select name='cultureFit' className="select-culturefit" onChange={ (event) => handleChange(event) }>
                            <option value='3' name='cultureFit'>3</option>
                            <option value='2.5' name='cultureFit'>2.5</option>
                            <option value='2' name='cultureFit'>2</option>
                            <option value='1.5' name='cultureFit'>1.5</option>
                            <option value='1' name='cultureFit'>1</option>
                        </select>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="modal-footer culturefit-footer">
                        <button type="button" className="decision-btn" data-dismiss="modal" onClick ={ () => handleSubmit(1) } >Confirm</button>
                        <button type="button" className="decision-btn" onClick ={ () => handleSendToVote() }>Cancel</button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}
CultureFitModal.propTypes = {
    isCultureFit: PropTypes.bool,
    handleSendToVote: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
};
export default CultureFitModal;