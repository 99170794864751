/* eslint-disable no-debugger */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import StorageHeader from './storageHeader';
import { getActiveHireCandidates, getCampaignCandidates, uploadImage } from 'actions/clientActions';
import CampaignCandidates from './CampaignCandidates';
import ActiveHiresCandidates from './ActiveHiresCandidates';
import { deleteCandidate, updateCandidatesFromHireSection, updateCandidatesNotes, uploadResume } from 'actions/candidateActions';
import NotesSection from '../Note';
import { SearchTableData } from 'shared/Constant';
import { triggerNotifier } from 'shared/Notifier';
import { updateStatus } from 'actions/dashboardActions';
import DeleteModal from '../Edit/DeleteModal';

function StorageSection(props) {
    const { updateNotes } = props;
    const campaign_Id = localStorage.getItem('campaignId');
    const campaign_Name = localStorage.getItem('campaignName');
    const campId = (props.history.location && props.history.location.query) !== undefined ? props.history.location.query : (props.history.location && props.history.location.state && props.history.location.state.campId) !== undefined ? props.history.location.state.campId : campaign_Id;
    const campName = (props.history.location && props.history.location.campaign_name) !== undefined ? props.history.location.campaign_name : (props.history.location && props.history.location.state && props.history.location.state.campaign_name) !== undefined ? props.history.location.state.campaign_name : campaign_Name;
    const [ isOpenNote, setIsOpenNote ] = useState(false);
    const [ notesValue, setNotesValue ] = useState('');
    const [ searchValue, setSearchValue ] = useState('');
    const [ notesDetails, setNotesDetails ] = useState({});
    const [ filterOptions, setFilterOptions ] = useState({
        city: '',
        state: '',
        tech_stack: '',
    });
    const [ educationArray, setEducationArray ] = useState([
        {
            id: 0,
            candidate_id: 0,
            course: '',
            specialization: '',
            university: '',
        },
    ]);
    const [ isEdit, setIsEdit ] = useState(false)
    const [ skillArray, setSkillArray ] = useState([]);
    const [ hobbyArray, setHobbyArray ] = useState([]);
    const [ rowData, setRowData ] = useState([]);
    const [ inputField, setInputField ] = useState([]);
    const [ profileFileName, setProfileFileName ] = useState();
    const [ toggleArrowIndex, setToggleArrowIndex ] = useState('');
    const [ isOpenDeleteModal, setIsOpenDeleteModal ] = useState(false);
    const [ candidateInfo, setCandidateInfo ] = useState({});
    const [ userType, setuserType ] = useState('');
    console.log('rowData', rowData);

    const dispatch = useDispatch();
    const active_tab = localStorage.getItem('activeTab');
    useEffect(() => {
        dispatch(getActiveHireCandidates(campId, filterOptions));
        if(active_tab === null || active_tab === "2"){
            dispatch(getCampaignCandidates(campId, 0, filterOptions)).then((res) => {
                if (res.statusCode === 200) {
                    setRowData(res.data.campaign_candidates);
                }
            });
        } else {
            dispatch(getCampaignCandidates(campId, 1, filterOptions)).then((res) => {
                if (res.statusCode === 200) {
                    setRowData(res.data.campaign_candidates);
                }
            });
        }
    }, [ dispatch, campId, filterOptions, active_tab ]);

    const hireActiveCandidates = useSelector((state) => state.hireActiveCandidates);
    const campaignCandidates = useSelector((state) => state.campaignCandidates.campaign_candidates);
    const resumeName = useSelector((state) => state.resumeFileName);
    const tableData = SearchTableData(hireActiveCandidates, searchValue);
    const campaignCandidatesData = SearchTableData(campaignCandidates, searchValue);
    const handleOpenNotes = (data) => {
        setNotesDetails(data);
        setIsOpenNote(!isOpenNote);
        if(!isOpenNote) {
            setTimeout(function() {
                window.scroll({
                    top: document.body.offsetHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 300);
        }
    };
    const handleCloseNotes = () => {
        setIsOpenNote(false)
    }
    const handleEditNotes = (event) => {
        setNotesValue(event.target.value);
    };
    const handleCandidatesNotes = (details) => {
        const message = 'The notes entered exceeds the maximum limit and its should be 500 words'
        let numWords = 0;
        for (var i = 0; i < notesValue.length; i++) {
            var currentCharacter = notesValue[ i ];
            if (currentCharacter === " ") {
                numWords += 1;
            }
        }
        numWords += 1;
        if(numWords <= 500) {
            const id = details.candidate_id;
            const data = {
                notes: notesValue,
                campaign_id: campId
            };
            dispatch(updateCandidatesNotes(id, data)).then((res) => {
                if (res.statusCode === 200) {
                    setIsOpenNote(false);
                    dispatch(getActiveHireCandidates(res.data.campaign_id));
                    dispatch(getCampaignCandidates(res.data.campaign_id, 0));
                    window.location.reload();
                }
            });
        } else {
            triggerNotifier({ type: 'error', message })
        }
    };
    const updateSearch = (event) => {
        setSearchValue(event.target.value.substr(0, 20));
    };
    const handleCandidateOptions = (event) => {
        setFilterOptions({
            ...filterOptions,
            [ event.target.name ]: event.target.value,
        });
    };
    const editHandletFunc = () => {
        setIsEdit(!isEdit)
    }
    const handleInputChange = (e , index) => {
        const { name, value } = e.target;
        const list = [ ...educationArray ];
        list[ index ][ name ] = value;
        setEducationArray(list);
    }

    const handleAddInput = () => {
        setEducationArray([ ...educationArray, {  id: 0 , candidate_id: 0 , university: '', specialization: '', course: '' } ])
    }

    const handleRemoveInput = index => {
        const list = [ ...educationArray ];
        list.splice(index, 1);
        setEducationArray(list);
    }
    const handleSkillsChange = (e, idm, skill , rowIndex) => {
        if (isEdit) {
            const oldArray = inputField.skills;
            const obj = {
                id: 0,
                candidate_id: inputField.candidate_id,
                skill_name: e.target.value
            }
            oldArray[ idm ] = obj;
            setSkillArray(oldArray);
            setInputField({ ...inputField, [ e.target.name ]: oldArray });
        }
    };
    const handleHobbiesChange = (e, idm, hobby, rowIndex) => {
        if (isEdit) {
            const oldArray = inputField.hobbies;
            const obj = {
                id: 0,
                candidate_id: inputField.candidate_id,
                hobby_name: e.target.value
            }
            oldArray[ idm ] = obj;
            setHobbyArray(oldArray);
            setInputField({ ...inputField, [ e.target.name ]: oldArray });
        }
    };
    const handleCandidateStatus = (details, type, role) => {
        const data = {
            status_type : type,
            id : details.id ? details.id : details.candidate_id ? details.candidate_id : details.campaign_id ,
            status : role
        }
        dispatch(updateStatus(data)).then((result)=> {
            if (result && result.data && result.data.status_type === 1){
                dispatch(getActiveHireCandidates(campId, filterOptions));
                dispatch(getCampaignCandidates(campId, 0, filterOptions));
            }
        });
    };
    const removeCandidateFunc = (candidate, role) => {
        if(role === 'education'){
            const data = {
                candidate_id : candidate.candidate_id,
                is_profile_delete: role === 'profile' ? 1 : 0,
                is_education_delete: role === 'education' ? 1 : 0,
                candidate_education_id: role === 'education' ? candidate.id : 0,
                is_resume_delete: role === 'resume' ? 1 : 0,
            };
            dispatch(
                deleteCandidate(data, data.candidate_id)
            ).then((res) => {
                if (res.statusCode === 200) {
                    setRowData(res.data.candidates_list);
                    window.location.reload();
                }
            });
        } else  if(role === 'resume') {
            const data = {
                is_profile_delete: role === 'profile' ? 1 : 0,
                is_education_delete: role === 'education' ? 1 : 0,
                candidate_education_id: role === 'education' ? candidate.id : 0,
                is_resume_delete: role === 'resume' ? 1 : 0,
            };
            dispatch(deleteCandidate(data, candidate.id ? candidate.id : candidate.candidate_id))
        } else {
            const data = {
                is_profile_delete: role === 'profile' ? 1 : 0,
                is_education_delete: role === 'education' ? 1 : 0,
                candidate_education_id: role === 'education' ? candidate.id : 0,
                is_resume_delete: role === 'resume' ? 1 : 0,
            };
            dispatch(
                deleteCandidate(data, candidate.id ? candidate.id : candidate.candidate_id)
            ).then((res) => {
                if (res.statusCode === 200) {
                    setRowData(res.data.candidates_list);
                    window.location.reload();
                }
            });
        }
    };
    const uploadProfilePicture = (e) => {
        inputField[ e.target.name ] = e.target.files[ 0 ];
        setProfileFileName(e.target.files[ 0 ].name)
        var data = new FormData();
        data.append('file', inputField.avatar);
        data.append('email', inputField.email_id);
        setInputField({ ...inputField });
        dispatch(uploadImage('Candidates', data));
    };
    const handleChange = (e) => {
        if (e.target.type.includes('file')) {
            inputField[ e.target.name ] = e.target.files[ 0 ];
            var data = new FormData();
            data.append('file', inputField.resume);
            data.append('email', inputField.email_id);
            data.append('is_resume', 1);
            setInputField({ ...inputField });
            dispatch(uploadResume('Candidates', data)).then((res) => {
                if(res.statusCode === 200) {
                    setIsOpenDeleteModal(false)
                }
            });
        } else {
            setInputField({ ...inputField, [ e.target.name ]: e.target.value });
        }
    };
    const handleToggleTable = (idx, details) => {
        setInputField(details);
        if (toggleArrowIndex === idx){
            setToggleArrowIndex('')
        }
        else{
            setToggleArrowIndex(idx)
        }
    }
    const handleDeleteModal = (id, type) => {
        setIsOpenDeleteModal(!isOpenDeleteModal)
        setCandidateInfo(id)
        setuserType(type)
    }
    const uploadResumeHandler = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal)
        setuserType('uploadResume')
    }
    const updateCandidateFunc = (event) => {
        event.preventDefault();
        const {
            first_name,
            last_name,
            email_id,
            phone_number,
            city,
            state,
            resume,
            tech_stack,
            experience,
            assessment_score,
            notes,
            avatar,
            educations,
            skills,
            hobbies,
            candidate_id,
            linkedin_url,
            github_url,
            portfolio_url
        } = inputField;
        const data = {
            first_name: first_name,
            avatar: avatar ? avatar.split('Candidates/')[ 1 ] : profileFileName ? profileFileName : '',
            last_name: last_name,
            email_id: email_id,
            phone_number: phone_number,
            city: city,
            state: state,
            resume: resumeName ? resumeName.split('Candidates/')[ 1 ] : resume || 'notes',
            tech_stack: tech_stack,
            experience: experience,
            assessment_score: assessment_score,
            notes: notes ? notes : '',
            // notes: !notesValue
            //     ? (notes && notes.toString()) || ''
            //     : isNotesData ? notesDate.concat(" : "+ notesValue.toString()) : notesValue.toString(),
            educations: educationArray[ 0 ].course === "" ? educations : educationArray,
            skills: skillArray.length !== 0 ? skillArray : skills,
            hobbies: hobbyArray.length !== 0 ? hobbyArray : hobbies,
            id: candidate_id,
            linkedin_url: linkedin_url ? linkedin_url : '',
            github_url: github_url ? github_url : '',
            portfolio_url: portfolio_url ? portfolio_url : ''
        };
        setTimeout(() => {
            dispatch(updateCandidatesFromHireSection(candidate_id, data, campId, filterOptions))
            setIsEdit(!isEdit)
        }, 500);
    }

    return (
        <>
            <StorageHeader
                updateSearch={ updateSearch }
                searchValue={ searchValue }
                history={ props.history }
                campName={ campName }
                handleCandidateOptions={ handleCandidateOptions }
                editHandletFunc={ editHandletFunc }
                isEdit={ isEdit }
                updateCandidateFunc={ updateCandidateFunc }
            />
            <div className="row semanticd">
                <div className="col-lg-6 col-sm-12 col-xs-12 table-responsive pe-0">
                    <ActiveHiresCandidates
                        hireActiveCandidates={ tableData }
                        handleOpenNotes={ handleOpenNotes }
                        handleEditNotes={ handleEditNotes }
                        handleCandidatesNotes={ handleCandidatesNotes }
                        campId={ campId }
                        isEdit={ isEdit }
                        educationArray={ educationArray }
                        setEducationArray={ setEducationArray }
                        handleInputChange= { handleInputChange }
                        handleAddInput= { handleAddInput }
                        handleRemoveInput = { handleRemoveInput }
                        handleSkillsChange={ handleSkillsChange }
                        handleHobbiesChange={ handleHobbiesChange }
                        handleCandidateStatus={ handleCandidateStatus }
                        removeCandidateFunc={ removeCandidateFunc }
                        uploadProfilePicture={ uploadProfilePicture }
                        handleChange={ handleChange }
                        inputField={ inputField }
                        handleToggleTable={ handleToggleTable }
                        toggleArrowIndex={ toggleArrowIndex }
                        handleDeleteModal={ handleDeleteModal }
                        uploadResumeHandler={ uploadResumeHandler }
                    />
                </div>
                <div className="col-lg-6 col-sm-12 col-xs-12 activehiring table-responsive">
                    <CampaignCandidates
                        campId={ campId }
                        handleOpenNotes={ handleOpenNotes }
                        campaignCandidates={ campaignCandidatesData }
                        isEdit={ isEdit }
                        educationArray={ educationArray }
                        setEducationArray={ setEducationArray }
                        handleInputChange= { handleInputChange }
                        handleAddInput= { handleAddInput }
                        handleRemoveInput = { handleRemoveInput }
                        handleSkillsChange={ handleSkillsChange }
                        handleHobbiesChange={ handleHobbiesChange }
                        handleCandidateStatus={ handleCandidateStatus }
                        removeCandidateFunc={ removeCandidateFunc }
                        uploadProfilePicture={ uploadProfilePicture }
                        handleChange={ handleChange }
                        inputField={ inputField }
                        handleToggleTable={ handleToggleTable }
                        toggleArrowIndex={ toggleArrowIndex }
                        handleDeleteModal={ handleDeleteModal }
                        uploadResumeHandler={ uploadResumeHandler }
                    />
                </div>
                {isOpenNote && <NotesSection notesValue={ notesDetails }  handleEditNotes={ handleEditNotes } handleCandidatesNotes = { handleCandidatesNotes } updateNotes={ updateNotes }  handleCloseNotes={ handleCloseNotes } />}
            </div>
            {isOpenDeleteModal && (
                <DeleteModal
                    isOpenDeleteModal={ isOpenDeleteModal }
                    removeCandidateFunc={ removeCandidateFunc }
                    candidateInfo={ candidateInfo }
                    handleDeleteModal={ handleDeleteModal }
                    userType={ userType }
                    handleChange={ handleChange }
                />
            )}
        </>
    );
}
StorageSection.propTypes = {
    history: PropTypes.object,
};

export default StorageSection;
