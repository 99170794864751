import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch , useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getClientListing } from 'actions/dashboardActions';
import { logoutUser } from 'actions/authActions';
import Filter from './Pending/FilterPendingHeader';
import FilterHeader from './FilterHeader';
import BackIcon from 'static/images/back-icon.svg';
import PenImage from 'static/images/pen.svg';
import MemberImage from 'static/images/member.svg';
import ApplyImage from 'static/images/apply.svg';
import { ReactSVG } from 'react-svg'

function AuthHeaderSection(props) {
    const dispatch = useDispatch()
    const { history, handleEditFunc, isEdit, addNewRow , searchValue , updateSearch, updateCandidateFunc, handleOptions, handleCandidateOptions, btnIsDisabled } = props
    const clientList = useSelector(state => state.clientListing)
    const totalPendingCount = clientList && clientList.length
    useEffect(() => {
        dispatch(getClientListing())
    }, [ dispatch ])
    const handleLogout = () => {
        localStorage.removeItem('clientId');
        localStorage.removeItem('campaignId');
        localStorage.removeItem('campaignName');
        dispatch(logoutUser(history))
    }
    const isCandidateHeader =  window.location.pathname === '/candidates'
    return (
        <nav className="navbar navbar-expand-lg navbar-dashboard">
            <div className="container-fluid">
                <a className="navbar-brand" href><ReactSVG src={ BackIcon } onClick={ ()=> handleLogout() } className="back-left-icon" /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item d-flex align-items-center">
                            <NavLink className="nav-link jacob" to='/pending' activeClassName="active">PENDING</NavLink>
                            <span className="badge checked">{totalPendingCount}</span>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link jacob" to="/clients" activeClassName="active">CLIENTS</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link jacob" to="/candidates" activeClassName="active">CANDIDATES</NavLink>
                        </li>
                    </ul>
                    {isCandidateHeader ?
                        <FilterHeader
                            updateSearch={ updateSearch }
                            searchValue={ searchValue }
                            handleCandidateOptions={ handleCandidateOptions }
                            disabled={ true }
                        />
                        :
                        <Filter
                            updateSearch={ updateSearch }
                            searchValue={ searchValue }
                            handleOptions={ handleOptions }
                        />
                    }
                </div>
                {isCandidateHeader && !isEdit && <button className="filtr mt-1 mb-2" type="submit" onClick={ addNewRow }><img src={ MemberImage } alt="" width="14" />New Candidate</button> }
                {isCandidateHeader && !isEdit && <button className="fill mt-1 mb-2 edit-bt" onClick={ handleEditFunc } type="submit"><img src={ PenImage } alt=""   />
                    Edit</button>}
                {isCandidateHeader && isEdit && <button className="filtr mt-1 mb-2" type="submit" onClick={ handleEditFunc }>Cancel</button>}
                {isCandidateHeader && isEdit && <button className="apply mt-1 mb-2" disabled={ btnIsDisabled === true ? true : false } type="submit" onClick={ updateCandidateFunc }><img src={ ApplyImage } alt="" />
                    Apply
                </button>}
            </div>
        </nav>
    )
}
AuthHeaderSection.propTypes = {
    history: PropTypes.object,
    isEdit: PropTypes.bool,
    handleEditFunc: PropTypes.func,
    addNewRow: PropTypes.func,
    updateCandidateFunc: PropTypes.func,
    handleCandidateOptions: PropTypes.func,
    handleOptions: PropTypes.func,
    searchValue: PropTypes.string,
    updateSearch: PropTypes.func,
    btnIsDisabled: PropTypes.bool
}
export default AuthHeaderSection
