/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getFilters } from 'actions/dashboardActions';
import SearchImage from 'static/images/search.svg';
import FilterImage from 'static/images/filter.svg';
import { getUniqueArray } from 'shared/Constant';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

function FilterHeader(props) {
    const [ disable , setDisabled  ] = useState(false);
    const dispatch = useDispatch()
    const { updateSearch, searchValue, handleCandidateOptions } = props
    const filterDetails = useSelector(state => state.filterDetails.filters_data)
    const isSendToVotePage =  window.location.pathname === '/send-to-vote'
    const isWardClientPage =  window.location.pathname === '/ward-clients'
    useEffect(() => {
        if(isSendToVotePage || isWardClientPage){
            dispatch(getFilters(0))
        } else {
            dispatch(getFilters(1))
        }
    },  [ dispatch, isSendToVotePage, isWardClientPage ] )
    const [ filterButtonState, setFilterButtonState ] = useState(true)
    const tempCityArray = []
    const tempStateArray = []
    const tempStackArray = []
    const FilterHandler = () => {
        setFilterButtonState(!filterButtonState)
    }
    const cancelHandler = () => {
        setFilterButtonState(!filterButtonState)
    }
    filterDetails && filterDetails.map((cities, idx)=> {
        return tempCityArray.push(cities && cities.city && cities.city.toUpperCase())
    })
    filterDetails && filterDetails.map((states, idx)=> {
        return tempStateArray.push(states && states.state && states.state.toUpperCase())
    })
    filterDetails && filterDetails.map((techStack, idx)=> {
        return tempStackArray.push(techStack && techStack.tech_stack && techStack.tech_stack.toUpperCase())
    })
    const cityList = getUniqueArray(tempCityArray).map((cities, idx) => {
        return <option key={ idx } value={ cities } name={ cities }>{cities}</option>
    })
    const stateList = getUniqueArray(tempStateArray).map((states, idx) => {
        return <option key={ idx } value={ states } name={ states }>{states}</option>
    })
    const techStackList = getUniqueArray(tempStackArray).map((techStack, idx) => {
        return <option key={ idx } value={ techStack } name={ techStack }>{techStack}</option>
    })
    return (
        <form className="d-flex mb-2">
            <img src={ SearchImage } className="searchin" alt="search" />
            <input
                className="form-control handle handled w-auto"
                type="search"
                name="clientSearch"
                value={ searchValue || '' }
                aria-label="Search"
                onChange={ updateSearch }
                disabled={ disable }
            />
            <Dropdown className="dropdown devdata" autoClose="inside" onClick={ () => setDisabled(!disable) }>
                <Dropdown.Toggle id="dropdown-autoclose-inside">
                    { !filterButtonState ? <a href className="cancel-link" onClick={ () => cancelHandler() } ><span className="white-img"></span>Cancel</a> : ''  }
                    <a
                        href
                        className="btn btn-secondary"
                        type="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        onClick={ () => FilterHandler() }
                    >
                        <img src={ FilterImage } alt="" />
                        { filterButtonState ? 'Filter' : <span className="underline">Apply</span>}
                    </a>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className="dropdown-menu locaten"
                    aria-labelledby="dropdownMenuLink"
                >
                    <div className="techteam">
                        <label htmlFor="inputPassword" className="col-sm-4 col-form-label">
                            <h4 className="locatab2 mb-0">Tech Stack</h4>
                        </label>
                        <select className="cityclass7" id="inputGroupSelect01"  onChange={ (event) => handleCandidateOptions(event) } name='tech_stack'>
                            <option value= '' >All</option>
                            {techStackList}
                        </select>
                    </div>
                    <h4 className="locatab2 mt-2 mb-0" style={ { marginLeft: 12 } }>Location</h4>
                    <div className="locatab">
                        <div className="formdropen">
                            <div className="form-group row">
                                <label
                                    htmlFor="inputPassword"
                                    className="col-sm-4 col-form-label p-0"
                                >
                                    City
                                </label>
                                <select className="cityclass" id="inputGroupSelect01" onChange={ (event) => handleCandidateOptions(event) } name='city'>
                                    <option defaultValue>All</option>
                                    {cityList}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor="inputPassword"
                                className="col-sm-12 col-form-label p-0 mt-3"
                            >
                                State
                            </label>
                            <select className="cityclass" id="inputGroupSelect01" onChange={ (event) => handleCandidateOptions(event) } name='state'>
                                <option defaultValue>All</option>
                                {stateList}
                            </select>
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </form>
    );
}
FilterHeader.propTypes = {
    searchValue: PropTypes.string,
    updateSearch: PropTypes.func,
    handleCandidateOptions: PropTypes.func
};
export default FilterHeader;
