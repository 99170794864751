/* eslint-disable quotes */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Play from 'static/images/play.svg';
import Pause from 'static/images/pause.svg';
import {  ddmmyyFormat } from 'shared/Constant';

import {  handleMilestoneStatus } from 'utils/Helper';
import Loading from 'shared/Loader';
import { useHistory } from 'react-router-dom';
import {  deleteMilestone,  getMilestones, updateProject } from 'actions/clientActions';

import MilestoneModal from './MilestoneModal';
import { ReactSVG } from 'react-svg';
import  BackArrow from 'static/images/back.svg';
import ProjectNotes from '../ProjectNote';

function EpicPassport(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [ isEdit, ] = useState(false);
    const [ isOpenMilestoneModal, setIsOpenMilestoneModal ] = useState(false)
    const [ isOpenNote, setIsOpenNote ] = useState(false)

    const [ milestones, setMilestones ] = useState([]);
    const projectId = props.history.location.state ? props.history.location.state.projectId : "";
    const notes = props.history.location.state ? props.history.location.state.notes : "";

    const clientProfileDetails = useSelector(
        (state) => state.clientProfile.client_profile
    );
    const clientCampDetails = useSelector(
        (state) => state.clientProfile.campaigns
    );
    const isLoading = useSelector((state) => state.applicationIsLoading);
    const [ milestoneUpdate, setMilestoneUpdate ] = useState(null);

    const getMilestoneList = () =>{
        dispatch(getMilestones(projectId)).then((res) => {
            if (res.statusCode === 200) {
                setMilestones(res.data.milestone_list);
            }
        });
    }
    useEffect(() => {
        getMilestoneList()
    }, [ dispatch, projectId ]);
    const handleOpenNotes = (data) => {
        setIsOpenNote(true);
        if (!isOpenNote) {
            setTimeout(function () {
                window.scroll({
                    top: document.body.offsetHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 300);
        }
    };
    const closeMilestoneModal = () => {
        setMilestoneUpdate(null)
        setIsOpenMilestoneModal(false);
        getMilestoneList()
    }
    const campArray = [];
    clientProfileDetails &&
        clientProfileDetails.map((data, idx) => {
            clientCampDetails &&
                clientCampDetails.map((camp, idm) => {
                    if (data.id === camp.client_id) {
                        campArray.push(camp);
                    }
                });
        });

    const updateMilestone = (milestone) =>{
        setMilestoneUpdate(milestone);
        setIsOpenMilestoneModal(!isOpenMilestoneModal);
    }
    const removeMilestone = async (milestone_id) => {
        const result = window.confirm('Are you sure you want to delete?');
        if(result){
            dispatch(deleteMilestone(milestone_id)).then((res) => {
                getMilestoneList();
            });
        }

    }
    const colorMapping = {
        complete : 'green',
        on_track : 'yellow',
        at_risk : 'red',
        behind_schedule : 'orange'
    }
    const formatStatus = (status) => {
        return (
            <div style = { { backgroundColor : colorMapping[ status ], width:"20px", height : "20px", borderRadius : "100%",marginTop:"5px" ,marginLeft:"10px" } }></div>
        )
    }
    return (
        <>
            <>
                <nav className="navbar navbar-expand-lg navbar-dashboard">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to=""></Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 listclient">

                                <li className="nav-item back-arrow-box" onClick={ ()=>{
                                    history.goBack()
                                } }>
                                    <ReactSVG src={ BackArrow } className="back-arrow" />
                                </li>
                                <li className="nav-item back-arrow-box" style={ { color: "white" } }onClick={ ()=>{
                                    history.goBack()
                                } }>
                                    Project List
                                </li>

                            </ul>
                            <div className="port">
                                {clientProfileDetails &&
                clientProfileDetails[ 0 ] &&
                clientProfileDetails[ 0 ].company_name}
                            </div>
                            <div className="edit-btn-section pb-2">

                                <button
                                    className="fill applicant pl-2 edit"
                                    style ={ { marginRight : "20px" } }
                                    onClick={ (event) =>
                                        handleOpenNotes()
                                    }
                                >
                                        &nbsp;Project Notes
                                </button>
                                <button
                                    className="fill applicant pl-2 edit"
                                    onClick={ (event) =>
                                        setIsOpenMilestoneModal(!isOpenMilestoneModal)
                                    }
                                >
                                        &nbsp;Create a milestone
                                </button>

                            </div>
                        </div>
                    </div>
                </nav>
            </>

            {!isEdit && (
                <div className="main-div">
                    <table  className="main-table table table-condensed github table-borderless">
                        <thead style = { { width : "100%" } }>
                            <tr className="table-head">
                                <th scope="col" className="s_no">#</th>
                                <th scope="col" style={ { width: '5%' } }></th>
                                <th scope="col" style={ { width: '10%' } }>
                                    Milestone Name <img src="Images/hiphen.svg" alt="" />
                                </th>

                                <th scope="col" style={ { width: '12%' } }>
                                    Start Date <img src="Images/hiphen.svg" alt="" />
                                </th>
                                <th scope="col" style={ { width: '12%' } }>
                                    End Date <img src="Images/hiphen.svg" alt="" />
                                </th>
                                <th scope="col" style={ { width: '10%' } }>
                                    Status <img src="Images/hiphen.svg" alt="" />
                                </th>

                                <th scope="col" style={ { width: '12%' } }>
                                    Resources <img src="Images/hiphen.svg" alt="" />
                                </th>
                                <th scope="col" style={ { width: '20%' } }></th>
                                <th scope="col" style={ { width: '30%' } }>
                                    Action <img src="Images/hiphen.svg" alt="" />
                                </th>
                            </tr>
                        </thead>
                        {isLoading && (
                            <div className="loader">
                                <Loading />
                            </div>
                        )}
                        <tbody>
                            {!isLoading &&
                                            milestones &&
                                            milestones.map((milestone, idx) => {
                                                return (
                                                    <tr key={ idx } className="table-row">
                                                        <th scope="row" className="s_no">{milestone.milestone_order}</th>
                                                        <td>
                                                            {milestone.is_active ? (
                                                                <img
                                                                    src={ Play }
                                                                    alt="play"
                                                                    className="play-icon"
                                                                    onClick={ () =>
                                                                        handleMilestoneStatus(dispatch, milestone.id,0)
                                                                    }
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={ Pause }
                                                                    alt="pause"
                                                                    className="pause-icon pause-img"
                                                                    onClick={ () =>
                                                                        handleMilestoneStatus(dispatch, milestone.id,  1)
                                                                    }
                                                                />
                                                            )}
                                                        </td>
                                                        <td>{milestone.milestone_name}</td>

                                                        <td>
                                                            {moment(milestone.start_date).format(ddmmyyFormat)}{' '}
                                                        </td>
                                                        <td>
                                                            {moment(milestone.end_date).format(ddmmyyFormat)}{' '}
                                                        </td>
                                                        <td>{formatStatus(milestone.status)}</td>
                                                        <td  >
                                                            <button type="submit" className="btn btn-secondary telenory closer"onClick = { () =>{
                                                                history.push({
                                                                    pathname: '/task',
                                                                    state: {
                                                                        milestoneId: milestone.id,
                                                                        projectId : projectId
                                                                    },
                                                                })
                                                            } } >
                                                                {milestone.task_count} Tasks

                                                            </button>
                                                        </td>
                                                        <td >{milestone.milestone_description}</td>

                                                        <td >
                                                            <button type="submit" className="btn btn-secondary telenory closer" onClick = { () =>{
                                                                updateMilestone(milestone)
                                                            } } >
                                                                Edit
                                                            </button>
                                                            <button type="submit" className="btn btn-secondary telenory closer" data-dismiss="modal"
                                                                onClick = { () =>{
                                                                    removeMilestone(milestone.id)
                                                                } }  >
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                        </tbody>
                    </table>

                </div>
            )}
            {isOpenNote && (
                <ProjectNotes
                    updateNotes={ (value) => {
                        const data = {
                            project_notes : value
                        }
                        dispatch(updateProject(projectId, data))
                        setIsOpenNote(false)
                        history.goBack()
                    } }
                    handleCloseNotes={ () => {
                        setIsOpenNote(false)
                    } }
                    notesValue={ notes }
                />
            )}
            {isOpenMilestoneModal && (
                <MilestoneModal
                    isOpenMilestoneModal={ isOpenMilestoneModal }
                    closeMilestoneDetail={ closeMilestoneModal }
                    milestoneUpdate = { milestoneUpdate }
                    projectId={ projectId }
                    size= { milestones.length + 1 }
                />
            )}

        </>
    );
}
EpicPassport.propTypes = {
    history: PropTypes.object,
};

export default EpicPassport;
