import React from 'react';
import PropTypes from 'prop-types';
import star from 'static/images/star.svg';
import halfStar from 'static/images/halfstar.svg';

function Rating(props) {
    const { rate } = props;
    const showRating = (d) => {
        if (d === '3') {
            return (
                <>
                    <img src={ star } alt="star" />
                    <img src={ star } alt="star" />
                    <img src={ star } alt="star" />
                </>
            );
        } else if (d === '2.5') {
            return (
                <>
                    <img src={ star } alt="star" />
                    <img src={ star } alt="star" />
                    <img src={ halfStar } alt="star" />
                </>
            );
        } else if (d === '2') {
            return (
                <>
                    <img src={ star } alt="star" />
                    <img src={ star } alt="star" />
                </>
            );
        } else if (d === '1.5') {
            return (
                <>
                    <img src={ star } alt="star" />
                    <img src={ halfStar } alt="star" />
                </>
            );
        } else if (d === '1') {
            return <img src={ star } alt="star" />;
        } else if (d === '0.5') {
            return <img src={ star } alt="star" />;
        } else {
            return '';
        }
    };
    return <span>{showRating(rate)}</span>;
}
Rating.propTypes = {
    rate: PropTypes.string,
};
export default Rating;
