/* eslint-disable quotes */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import {  ddmmyyFormat } from 'shared/Constant';

import Loading from 'shared/Loader';
import { useHistory } from 'react-router-dom';
import {  deleteTask,  getTasks } from 'actions/clientActions';

import TaskModal from './TaskModal';
import { ReactSVG } from 'react-svg';
import  BackArrow  from 'static/images/back.svg';
function Task(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [ isEdit, ] = useState(false);
    const [ isOpenTaskModal, setIsOpenTaskModal ] = useState(false)

    const [ tasks, setTasks ] = useState([]);
    const milestoneId = props.history.location.state ? props.history.location.state.milestoneId : "";
    const projectId = props.history.location.state ? props.history.location.state.projectId : "";

    const clientProfileDetails = useSelector(
        (state) => state.clientProfile.client_profile
    );
    const clientCampDetails = useSelector(
        (state) => state.clientProfile.campaigns
    );
    const isLoading = useSelector((state) => state.applicationIsLoading);
    const [ taskUpdate, setTaskUpdate ] = useState(null);

    const getTaskList = () =>{
        dispatch(getTasks(milestoneId)).then((res) => {
            if (res.statusCode === 200) {
                setTasks(res.data.task_list);
            }
        });
    }
    useEffect(() => {
        getTaskList()
    }, [ dispatch, milestoneId ]);

    const closeTaskModal = () => {
        setIsOpenTaskModal(false);
        getTaskList()
    }
    const campArray = [];
    clientProfileDetails &&
        clientProfileDetails.map((data, idx) => {
            clientCampDetails &&
                clientCampDetails.map((camp, idm) => {
                    if (data.id === camp.client_id) {
                        campArray.push(camp);
                    }
                });
        });

    const updateTask = (task) =>{
        setTaskUpdate(task);
        setIsOpenTaskModal(!isOpenTaskModal);
    }
    const removeTask = async (task_id) => {
        const result = window.confirm('Are you sure you want to delete?');
        if(result){
            dispatch(deleteTask(task_id)).then((res) => {
                getTaskList();
            });
        }

    }
    const colorMapping = {
        complete : 'green',
        on_track : 'yellow',
        at_risk : 'red',
        behind_schedule : 'orange'
    }
    const formatStatus = (status) => {
        return (
            <div style = { { backgroundColor : colorMapping[ status ], width:"20px", height : "20px", borderRadius : "100%",marginTop:"5px" ,marginLeft:"10px" } }></div>
        )
    }
    return (
        <>
            <>
                <nav className="navbar navbar-expand-lg navbar-dashboard">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to=""></Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 listclient">
                                <li className="nav-item back-arrow-box" onClick={ ()=>{
                                    history.goBack()
                                } }>
                                    <ReactSVG src={ BackArrow } className="back-arrow" />
                                </li>
                                <li className="nav-item back-arrow-box" style={ { color: "white" } }onClick={ ()=>{
                                    history.goBack()
                                } }>
                                    Milestone List
                                </li>
                            </ul>
                            <div className="port">
                                {clientProfileDetails &&
                clientProfileDetails[ 0 ] &&
                clientProfileDetails[ 0 ].company_name}
                            </div>
                            <div className="edit-btn-section pb-2">

                                <button
                                    className="fill applicant pl-2 edit"
                                    onClick={ (event) =>{
                                        setTaskUpdate(null);
                                        setIsOpenTaskModal(!isOpenTaskModal)
                                    }

                                    }
                                >
                                        &nbsp;Create a task
                                </button>

                            </div>
                        </div>
                    </div>
                </nav>
            </>

            {!isEdit && (
                <div className="main-div">
                    <table  className="main-table table table-condensed github table-borderless">
                        <thead style = { { width : "100%" } }>
                            <tr className="table-head">
                                <th scope="col" className="s_no">#</th>
                                <th scope="col" style={ { width: '10%' } }>
                                    Task Name <img src="Images/hiphen.svg" alt="" />
                                </th>
                                <th scope="col" style={ { width: '12%' } }>
                                    Start Date <img src="Images/hiphen.svg" alt="" />
                                </th>
                                <th scope="col" style={ { width: '12%' } }>
                                    End Date <img src="Images/hiphen.svg" alt="" />
                                </th>
                                <th scope="col" style={ { width: '12%' } }>
                                    Status <img src="Images/hiphen.svg" alt="" />
                                </th>
                                <th scope="col" style={ { width: '15%' } }>
                                    Resources <img src="Images/hiphen.svg" alt="" />
                                </th>
                                <th scope="col" style={ { width: '20%' } }> </th>

                                <th scope="col" style={ { width: '30%' } }>
                                    Action <img src="Images/hiphen.svg" alt="" />
                                </th>
                            </tr>
                        </thead>
                        {isLoading && (
                            <div className="loader">
                                <Loading />
                            </div>
                        )}
                        <tbody>
                            {!isLoading &&
                                            tasks &&
                                            tasks.map((task, idx) => {
                                                return (
                                                    <tr key={ idx } className="table-row">
                                                        <th scope="row" className="s_no">{task.task_order}</th>

                                                        <td>{task.task_name}</td>

                                                        <td>
                                                            {moment(task.start_date).format(ddmmyyFormat)}{' '}
                                                        </td>
                                                        <td>
                                                            {moment(task.end_date).format(ddmmyyFormat)}{' '}
                                                        </td>

                                                        <td>{formatStatus(task.status)}</td>
                                                        <td>
                                                            <button className="btn btn-secondary telenory closer" style= { { width : "100px" } } onClick = { () =>{
                                                                history.push({
                                                                    pathname: '/taskcandidate',
                                                                    state: {
                                                                        taskId: task.id,
                                                                        projectId : projectId,
                                                                        milestoneId : milestoneId
                                                                    },
                                                                })
                                                            } } >
                                                                {task.candidate_count} Candidates

                                                            </button>
                                                        </td>

                                                        <td >{task.task_description}</td>

                                                        <td >
                                                            <button type="submit" className="btn btn-secondary telenory closer" onClick = { () =>{
                                                                updateTask(task)
                                                            } } >
                                                                Edit
                                                            </button>
                                                            <button type="submit" className="btn btn-secondary telenory closer" data-dismiss="modal"
                                                                onClick = { () =>{
                                                                    removeTask(task.id)
                                                                } }  >
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                        </tbody>
                    </table>

                </div>
            )}

            {isOpenTaskModal && (
                <TaskModal
                    isOpenTaskModal={ isOpenTaskModal }
                    closeTaskDetail={ closeTaskModal }
                    taskUpdate = { taskUpdate }
                    milestoneId={ milestoneId }
                    projectId={ projectId }
                    size= { tasks.length + 1 }

                />
            )}

        </>
    );
}
Task.propTypes = {
    history: PropTypes.object,
};

export default Task;
