export function assoCandidatesListing(state = [], action) {
    switch (action.type) {
    case 'FETCH_ASSOCIATE_CANDIDATES':
        return action.assoCandidatesListing;
    default:
        return state;
    }
}
export function sendVotedDetails(state = [], action) {
    switch (action.type) {
    case 'FETCH_SEND_VOTED_DETAILS':
        return action.sendVotedDetails;
    default:
        return state;
    }
}
export function resumeFileName(state = '', action) {
    switch (action.type) {
    case 'FECTH_RESUME_FILE_NAME':
        return action.resumeFileName;
    default:
        return state;
    }
}
export function sharedProfileStatus(state = 0, action) {
    switch (action.type) {
    case 'FETCH_SHARED_PROFILE_DETAILS':
        return action.sharedProfileStatus;
    default:
        return state;
    }
}