import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment'
import { addTask, updateTask } from '../../../actions/clientActions';
import { addTaskValidator } from '../../../utils/Validater';
import { useDispatch } from 'react-redux';

function TaskModal(props) {
    const dispatch = useDispatch();

    const { isOpenTaskModal, closeTaskDetail, milestoneId,projectId,  taskUpdate, size } = props;
    const [ inputFieldError, setInputFieldError ] = useState({});
    const [ inputField, setInputField ] = useState({});
    const [ isUpdate , setIsUpdate ] = useState(false);

    useEffect(()=>{
        if(taskUpdate){
            setIsUpdate(true)
            setInputField({
                ...taskUpdate,
                start_date : moment(taskUpdate.start_date).format('YYYY-MM-DD'),
                end_date : moment(taskUpdate.end_date).format('YYYY-MM-DD')
            })
        }else{
            setInputField({
                task_order : size
            })
        }
    },[ taskUpdate ])

    const handleChange = (e) => {
        setInputField({ ...inputField, [ e.target.name ]: e.target.value });
        setInputFieldError({});
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            task_order : inputField.task_order,
            milestone_id : milestoneId,
            project_id : projectId,
            start_date: inputField.start_date,
            end_date: inputField.end_date,
            task_name: inputField.task_name,
            task_description : inputField.task_description,
            status : inputField.status

        };
        if (addTaskValidator(inputField, setInputFieldError)) {
            if(isUpdate){
                data.task_id = taskUpdate.id
                delete data.milestone_id
                delete data.project_id
                dispatch(updateTask(taskUpdate.id, data)).then((res) => {
                    if (res.statusCode === 200) {
                        closeTaskDetail()
                    }
                });
            }else{
                dispatch(addTask(data)).then((res) => {
                    if (res.statusCode === 200) {
                        closeTaskDetail()
                    }
                });
            }
        }
    };

    return (
        <div id="taskModal">

            <Modal isOpen={ isOpenTaskModal }>
                <div className="modal-header">
                    <div>
                        <h5 className="modal-title" id="exampleModalLabel">
                            Task
                        </h5>
                    </div>
                    <div>
                        <button type="button" className="btn btn-secondary telenory closer" data-dismiss="modal" onClick={ closeTaskDetail }>
                            Close
                        </button>
                    </div>
                </div>
                <ModalBody>
                    <form className="login-form">
                        <div className="form-group field_cont">
                            <label htmlFor="task_order" className="form-label">
                                Task Order
                            </label>
                            <input
                                type="number"
                                min="1"
                                name="task_order"
                                id="task_order"
                                autoComplete="off"
                                placeholder="Enter your task order"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.task_order || '' }
                            />
                            {inputFieldError.task_order && <span className="error_msg">{inputFieldError.task_order}</span>}
                        </div>
                        <div className="form-group field_cont">
                            <label htmlFor="task_name" className="form-label">
                                Task Name
                            </label>
                            <input
                                type="text"
                                name="task_name"
                                id="task_name"
                                autoComplete="off"
                                placeholder="Enter your task name"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.task_name || '' }
                            />
                            {inputFieldError.task_name && <span className="error_msg">{inputFieldError.task_name}</span>}
                        </div>
                        <div className="form-group field_cont">
                            <label htmlFor="status" className="form-label">
                                Task Status
                            </label>
                            <select  className="form-control" id="status" name='status' value={ inputField.status }
                                onChange={ handleChange }>
                                <option name='complete' id='complete' value="complete">Completed</option>
                                <option name='on_track' id='on_track' value="on_track">On Track</option>
                                <option name='at_risk' id='at_risk' value="at_risk">At Risk</option>
                                <option name='behind_schedule' id='behind_schedule' value="behind_schedule">Behind Schedule</option>
                            </select>

                            {inputFieldError.status && <span className="error_msg">{inputFieldError.status}</span>}
                        </div>
                        <div className="form-group field_cont">
                            <label htmlFor="start_date" className="form-label">
                                Task Start Date
                            </label>
                            <input
                                type="date"
                                name="start_date"
                                id="start_date"
                                autoComplete="off"
                                placeholder="Enter your task start date"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.start_date || '' }
                            />
                            {inputFieldError.start_date && <span className="error_msg">{inputFieldError.start_date}</span>}
                        </div>
                        <div className="form-group field_cont">
                            <label htmlFor="end_date" className="form-label">
                                Task End Date
                            </label>
                            <input
                                type="date"
                                name="end_date"
                                id="end_date"
                                autoComplete="off"
                                placeholder="Enter your task end date"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.end_date || '' }
                            />
                            {inputFieldError.end_date && <span className="error_msg">{inputFieldError.end_date}</span>}
                        </div>
                        <div className="form-group field_cont">
                            <label htmlFor="task_description" className="form-label">
                                Task Description
                            </label>
                            <textarea
                                type="textarea"
                                name="task_description"
                                id="task_description"
                                autoComplete="off"
                                placeholder="Enter your task description"
                                className="form-control"
                                onChange={ handleChange }
                                value={ inputField.task_description || '' }
                            />
                            {inputFieldError.task_description && <span className="error_msg">{inputFieldError.task_description}</span>}
                        </div>
                        <button type="submit" className="btn btn-secondary telenory closer" data-dismiss="modal" onClick={ handleSubmit }>
                            Save
                        </button>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
}
TaskModal.propTypes = {
    clientHiringDetails: PropTypes.array,
    isOpenTaskModal: PropTypes.bool,
    closeTaskDetail: PropTypes.func,
    taskUpdate : PropTypes.object,
    milestoneId: PropTypes.number,
    projectId: PropTypes.number,
    size : PropTypes.number
};
export default TaskModal;
