import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { addInterviewValidator } from '../../../utils/Validater';
import { useDispatch } from 'react-redux';
import { addInterview , updateInterview } from '../../../actions/clientActions';
import moment from 'moment'
import { getCandidateListing } from '../../../actions/candidateActions';
function AddUpdateInterviewModal(props) {
    const dispatch = useDispatch();

    const {  interview, clientId , submitForm } = props;
    const [ inputFieldError, setInputFieldError ] = useState({});
    const [ inputField, setInputField ] = useState({});
    const [ candidates, setCandidates ] = useState([])
    const [ isUpdate , setIsUpdate ] = useState(false);
    useEffect(() => {
        dispatch(getCandidateListing({})).then((res) => {
            if (res.statusCode === 200) {
                setCandidates(res.data.candidates_list);
            }
        });
    }, [ dispatch ]);

    useEffect(()=>{
        if(interview){
            setIsUpdate(true)
            setInputField({
                ...interview,
                interview_date : moment(interview.interview_date).format('YYYY-MM-DD'),
                interview_time : moment(interview.interview_date).format('HH:MM:SS')
            })
        }
    },[ interview ])
    useEffect(()=>{
        console.log(inputField)
    },[ inputField ])
    const handleChange = (e) => {
        setInputField({ ...inputField, [ e.target.name ]: e.target.value });
        setInputFieldError({});
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            candidate_id: inputField.candidate_id,
            client_id: clientId,
            interview_date: moment(inputField.interview_date + ' ' + inputField.interview_time).format('YYYY-MM-DD HH:MM:SS'),
            interview_link: inputField.interview_link,
            interview_description: inputField.interview_description,

        };
        if (addInterviewValidator(inputField, setInputFieldError)) {
            if(isUpdate){
                data.client_interview_id = interview.id;
                dispatch(updateInterview(data)).then((res) => {
                    if (res.statusCode === 200) {
                        submitForm()
                    }
                });
            }else{
                dispatch(addInterview(data)).then((res) => {
                    if (res.statusCode === 200) {
                        submitForm()
                    }
                });
            }
        }
    };
    return (

        <form className="login-form">
            <div className="form-group field_cont">
                <label htmlFor="candidate_id" className="form-label">
                    Candidate
                </label>

                <select
                    onChange={ handleChange }
                    name="candidate_id"
                    className="form-control"
                    placeholder="Enter your candidate_id"
                    value={ inputField.candidate_id || '' }

                    id="candidate_id">
                    <option defaultValue>None</option>
                    {candidates && candidates.length > 0 && candidates.map((row) => {
                        return (<option key={ row.id } value={ row.id } >{row.first_name}</option>)
                    })}
                </select>
                {inputFieldError.candidate_id && <span className="error_msg">{inputFieldError.candidate_id}</span>}
            </div>
            <div className="form-group field_cont">
                <label htmlFor="interview_date" className="form-label">
                    Interview Date
                </label>
                <input
                    type="date"
                    name="interview_date"
                    id="interview_date"
                    autoComplete="off"
                    placeholder="Enter your interview date"
                    className="form-control"
                    onChange={ handleChange }
                    value={ inputField.interview_date || '' }
                />
                {inputFieldError.interview_date && <span className="error_msg">{inputFieldError.interview_date}</span>}
            </div>
            <div className="form-group field_cont">
                <label htmlFor="interview_time" className="form-label">
                    Interview Time
                </label>
                <input
                    type="time"
                    name="interview_time"
                    id="interview_time"
                    autoComplete="off"
                    placeholder="Enter your interview time"
                    className="form-control"
                    onChange={ handleChange }
                    value={ inputField.interview_time || '' }
                />
                {inputFieldError.interview_time && <span className="error_msg">{inputFieldError.interview_time}</span>}
            </div>
            <div className="form-group field_cont">
                <label htmlFor="interview_link" className="form-label">
                    Interview Link
                </label>
                <input
                    type="text"
                    name="interview_link"
                    id="interview_link"
                    autoComplete="off"
                    placeholder="Enter your interview link"
                    className="form-control"
                    onChange={ handleChange }
                    value={ inputField.interview_link || '' }
                />
                {inputFieldError.interview_link && <span className="error_msg">{inputFieldError.interview_link}</span>}
            </div>
            <div className="form-group field_cont">
                <label htmlFor="interview_description" className="form-label">
                    Interview Description
                </label>
                <input
                    type="text"
                    name="interview_description"
                    id="interview_description"
                    autoComplete="off"
                    placeholder="Enter your interview description"
                    className="form-control"
                    onChange={ handleChange }
                    value={ inputField.interview_description || '' }
                />
                {inputFieldError.interview_description && <span className="error_msg">{inputFieldError.interview_description}</span>}
            </div>
            <button type="submit" className="btn btn-secondary telenory closer" data-dismiss="modal" onClick={ handleSubmit }>
                Save
            </button>
        </form>

    );
}
AddUpdateInterviewModal.propTypes = {
    clientHiringDetails: PropTypes.array,
    isOpenAddInterviewModal: PropTypes.bool,
    submitForm: PropTypes.func,
    clientId: PropTypes.number,
    interview : PropTypes.object
};
export default AddUpdateInterviewModal;
