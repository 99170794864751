import React , { memo } from 'react'
import PropTypes from 'prop-types';

function Button(props) {
    const { handleSubmit, buttonName, buttonClass, buttonIcon } = props
    return (
        <button className={ buttonClass? buttonClass :  'btn' } onClick={ handleSubmit }>
            {buttonIcon}<span>{buttonName}</span>
        </button>
    )
}
Button.propTypes = {
    buttonName: PropTypes.string,
    buttonClass: PropTypes.string,
    handleSubmit: PropTypes.func,
    buttonIcon: PropTypes.object,
}
export default memo(Button)
