/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SearchImage from 'static/images/search.svg';
import FilterImage from 'static/images/filter.svg';
import { getFilters } from 'actions/dashboardActions';
import { getUniqueArray } from 'shared/Constant';
import { Dropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'

function Filter(props) {
    const [ disable , setDisabled  ] = useState(false);
    const { updateSearch, searchValue, handleOptions } = props
    const dispatch = useDispatch()
    const location = useLocation();
    const filterDetails = useSelector(state => state.filterDetails.filters_data)
    useEffect(() => {
        if( location.pathname === '/pending'){
            dispatch(getFilters(parseInt(0), 1))
        } else {
            dispatch(getFilters(parseInt(0)))
        }
    },  [ dispatch, location.pathname ] )
    const [ filterButtonState, setFilterButtonState ] = useState(true)
    const FilterHandler = () => {
        setFilterButtonState(!filterButtonState)
    }
    const cancelHandler = () => {
        setFilterButtonState(!filterButtonState)
    }
    const tempCityArray = []
    const tempStateArray = []
    if(location.pathname === '/clients'){
        filterDetails && filterDetails.map((cities, idx)=> {
            return tempCityArray.push(cities && cities.city &&  cities.city.toUpperCase())
        })
        filterDetails && filterDetails.map((states, idx)=> {
            return tempStateArray.push(states && states.state && states.state.toUpperCase())
        })
    } else if( location.pathname === '/pending'){
        filterDetails && filterDetails.map((cities, idx)=> {
            return tempCityArray.push(cities && cities.city &&  cities.city.toUpperCase())
        })
        filterDetails && filterDetails.map((states, idx)=> {
            return tempStateArray.push(states && states.state && states.state.toUpperCase())
        })
    }
    const cityListing = getUniqueArray(tempCityArray).map((cities, idx) => {
        if(cities !== null) {
            return <option key={ idx } value={ cities } name={ cities }>{cities}</option>
        }
    })
    const stateListing = getUniqueArray(tempStateArray).map((states, idx) => {
        if(states !== null) {
            return <option key={ idx } value={ states } name={ states }>{states}</option>
        }
    })
    return (
        <form className="d-flex mb-2">
            <img src={ SearchImage } className="searchin" alt="search" />
            <input
                className="form-control handle handled w-auto"
                type="search"
                name="clientSearch"
                value={ searchValue || '' }
                placeholder=""
                aria-label="Search"
                onChange={ updateSearch }
                disabled= { disable }
            />
            <Dropdown className="dropdown devdata" autoClose="inside" onClick={ () => setDisabled(!disable) }>
                <Dropdown.Toggle id="dropdown-autoclose-inside">
                    { !filterButtonState ? <a href className="cancel-link" onClick={ () => cancelHandler() } ><span className="white-img"></span>Cancel</a> : ''  }
                    <a
                        href
                        className="btn btn-secondary"
                        type="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        onClick={ () => FilterHandler() }
                    >
                        <img src={ FilterImage } alt="" />
                        { filterButtonState ? 'Filter' : <span className="underline">Apply</span>}
                    </a>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className="dropdown-menu locaten"
                    aria-labelledby="dropdownMenuLink"
                >
                    <h4 className="locatab2 mt-3 pl-2">Location</h4>
                    <div className="locatab">
                        <div className="formdropen">
                            <div className="form-group row">
                                <label
                                    htmlFor="inputPassword"
                                    className="col-sm-4 col-form-label p-0"
                                >
                                    City
                                </label>
                                <select className="cityclass" id="inputGroupSelect01" onChange={ handleOptions } name = 'city'>
                                    <option defaultValue>All</option>
                                    {cityListing}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor="inputPassword"
                                className="col-sm-12 col-form-label p-0 mt-3"
                            >
                                State
                            </label>
                            <select className="cityclass" id="inputGroupSelect01" name='state' onChange={ handleOptions }>
                                <option defaultValue>All</option>
                                {stateListing}
                            </select>
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </form>
    );
}
Filter.propTypes = {
    history: PropTypes.object,
    searchValue: PropTypes.string,
    updateSearch: PropTypes.func,
    handleOptions: PropTypes.func,
};
export default Filter;
